import styled from "styled-components";

export const CardIcon = styled.div`
    position: relative;
    height: 100px;
    width: 100px;
    padding: 5px;
    margin: auto;
    border-radius: 10px;
    border: 5px ridge var(--branco);
    background-color: var(--azul);
    box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
        rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
        rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
        
    svg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        height: 50px;
        width: 50px;
        color: var(--branco);
    }

    p {
        color: var(--branco);
    }

    &:active {
        top: 2px;
    }
`;