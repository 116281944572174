import { useState } from "react";
import { useEffect } from "react";
import { Form, Container, Col, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Api } from "_config/api/apiService";
import { Colaborador, Questionario } from "_config/services/types";
import { mensagemAlert, mensagemErro, mensagemSucesso } from "Components/Toastr/toastr";

import * as S from "helpers/divider/StylesDivider";
import * as C from "./styles.js";

import "./styles.css";
import { Button } from "helpers/ButtonCustom/index.js";

type FormValuesProps = {
  colaborador: Colaborador[];
  questionario: Questionario;
  questionarioID: {
    id: number;
  };
  pessoa: {
    id: string;
  };
  pessoaAvaliada: {
    id: string;
  };
  respostaUm: number;
  respostaDois: number;
  respostaTres: number;
  respostaQuatro: number;
  respostaCinco: number;
  respostaSeis: number;
  respostaSete: number;
  respostaOito: number;
  respostaNove: number;
};

function FormAvaliacao() {
  const user = JSON.parse(localStorage.getItem("usuario") || "{}");
  const navigate = useNavigate();
  const { avaliacaoId }: any = useParams();

  const [loading, setLoading] = useState(true);
  const [gestorPessoa, setGestorPessoa] = useState<Colaborador[]>();
  const [colaboradorPessoa, setColaboradorPessoa] = useState<Colaborador>();

  const [formValues, setFormValues] = useState<FormValuesProps>({
    questionarioID: {
      id: avaliacaoId
    },
    pessoaAvaliada: {
      id: ""
    },
    pessoa: {
      id: user.id
    },
  } as FormValuesProps);

  const onClick = (field: string, value: number) => {
    setFormValues(prevState => ({ ...prevState, [field]: value }));
  };

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    verificaCampos();
  };

  const enviarAvaliacao = async () => {
    try {
      await Api.post(`questionario-pessoa/resposta-questionario`, formValues);
      navigate("/home");
      mensagemSucesso("Sucesso!")
    } catch (error) {
      mensagemErro("Falha ao enviar!");
    }
  };

  function verificaCampos() {
    if (formValues.pessoaAvaliada.id === "") {
      return mensagemAlert(
        "É necessário selecionar um colaborador que será avaliado!"
      );
    }
    if (!formValues.respostaUm) {
      return mensagemAlert("É necessário responder a primeira pergunta!");
    }
    if (!formValues.respostaDois) {
      return mensagemAlert("É necessário responder a segunda pergunta!");
    }
    if (!formValues.respostaTres) {
      return mensagemAlert("É necessário responder a terceira pergunta!");
    }
    if (!formValues.respostaQuatro) {
      return mensagemAlert("É necessário responder a quarta pergunta!");
    }
    if (!formValues.respostaCinco) {
      return mensagemAlert("É necessário responder a quinta pergunta!");
    }
    if (!formValues.respostaSeis) {
      return mensagemAlert("É necessário responder a sexta pergunta!");
    }
    if (!formValues.respostaSete) {
      return mensagemAlert("É necessário responder a setima pergunta!");
    }

    return enviarAvaliacao();
  }

  useEffect(() => {
    (async () => {
      try {
        const colaboradores = (await Api.get("pessoa/todos")).data.content;
        const questionario = (await Api.get(`questionario/${avaliacaoId}`)).data;

        setFormValues(prevState => ({
          ...prevState,
          colaborador: colaboradores,
          questionario: questionario
        }));
      } catch (error) {
        console.log("Internet error");
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    Api.get(`pessoa/gestor-pessoa/${user?.id}`)
      .then(response => {
        setGestorPessoa(response.data);
      })
  }, [user?.id]);

  useEffect(() => {
    Api.get(`pessoa/colaborador-pessoa/${user?.id}`)
      .then(response => {
        setColaboradorPessoa(response.data);
      })
  }, [user?.id]);

  if (loading) return <S.Loader />;

  return (
    <S.Wrapper onSubmit={onSubmit}>
      <Container>
        <S.Divider className="list-avaliacao">
          <h2 className="alin-text">
            Selecione o colaborador que será avaliado
          </h2>
          <Form.Select
            defaultValue={"DEFAULT"}
            onChange={e =>
              setFormValues(prevState => ({
                ...prevState,
                pessoaAvaliada: { id: e.target.value }
              }))
            }
          >
            <option disabled value="DEFAULT">
              Selecione um colaborador
            </option>
            {gestorPessoa != null ? gestorPessoa.map(pessoa => <option value={pessoa?.id}>{pessoa?.nome}</option>) : null}
            {colaboradorPessoa != null ? <option value={colaboradorPessoa.id}>{colaboradorPessoa?.nome}</option> : null};
          </Form.Select>
        </S.Divider>

        <S.Divider className="list-avaliacao">
          <h2 className="alin-text">{formValues.questionario?.nomeQuestionario}</h2>

          <p className="alin-text">{formValues.questionario?.primeiraPergunta}</p>
          <S.CirstaisWrapper checkPosition={formValues.respostaUm}>

            <S.Cristais
              src="/preto-100.png"
              type="button"
              onClick={() => onClick("respostaUm", 1)}
            />

            <S.Cristais
              src="/preto-70.png"
              type="button"
              onClick={() => onClick("respostaUm", 2)}
            />

            <S.Cristais
              src="/preto-50.png"
              type="button"
              onClick={() => onClick("respostaUm", 3)}
            />

            <S.Cristais
              src="/preto-30.png"
              type="button"
              onClick={() => onClick("respostaUm", 4)}
            />

            <S.Cristais
              src="/branco.png"
              type="button"
              onClick={() => onClick("respostaUm", 5)}
            />

          </S.CirstaisWrapper>

          <Row className="m-0 line-title-avaliacao">
            <Col className="p-0"><C.Title>iniciante</C.Title></Col>
            <Col><C.Title>regular</C.Title></Col>
            <Col><C.Title>bom</C.Title></Col>
            <Col><C.Title>ótimo</C.Title></Col>
            <Col><C.Title>jedi</C.Title></Col>
          </Row>

          <p className="alin-text">{formValues.questionario?.segundaPergunta}</p>
          <S.CirstaisWrapper checkPosition={formValues.respostaDois}>

            <S.Cristais
              src="/preto-100.png"
              type="button"
              onClick={() => onClick("respostaDois", 1)}
            />

            <S.Cristais
              src="/preto-70.png"
              type="button"
              onClick={() => onClick("respostaDois", 2)}
            />

            <S.Cristais
              src="/preto-50.png"
              type="button"
              onClick={() => onClick("respostaDois", 3)}
            />

            <S.Cristais
              src="/preto-30.png"
              type="button"
              onClick={() => onClick("respostaDois", 4)}
            />

            <S.Cristais
              src="/branco.png"
              type="button"
              onClick={() => onClick("respostaDois", 5)}
            />

          </S.CirstaisWrapper>

          <Row className="m-0 line-title-avaliacao">
            <Col className="p-0"><C.Title>iniciante</C.Title></Col>
            <Col><C.Title>regular</C.Title></Col>
            <Col><C.Title>bom</C.Title></Col>
            <Col><C.Title>ótimo</C.Title></Col>
            <Col><C.Title>jedi</C.Title></Col>
          </Row>

          <p className="alin-text">{formValues.questionario?.terceiraPergunta}</p>
          <S.CirstaisWrapper checkPosition={formValues.respostaTres}>

            <S.Cristais
              src="/preto-100.png"
              type="button"
              onClick={() => onClick("respostaTres", 1)}
            />

            <S.Cristais
              src="/preto-70.png"
              type="button"
              onClick={() => onClick("respostaTres", 2)}
            />

            <S.Cristais
              src="/preto-50.png"
              type="button"
              onClick={() => onClick("respostaTres", 3)}
            />

            <S.Cristais
              src="/preto-30.png"
              type="button"
              onClick={() => onClick("respostaTres", 4)}
            />

            <S.Cristais
              src="/branco.png"
              type="button"
              onClick={() => onClick("respostaTres", 5)}
            />

          </S.CirstaisWrapper>

          <Row className="m-0 line-title-avaliacao">
            <Col className="p-0"><C.Title>iniciante</C.Title></Col>
            <Col><C.Title>regular</C.Title></Col>
            <Col><C.Title>bom</C.Title></Col>
            <Col><C.Title>ótimo</C.Title></Col>
            <Col><C.Title>jedi</C.Title></Col>
          </Row>

          <p className="alin-text">{formValues.questionario?.quartaPergunta}</p>
          <S.CirstaisWrapper checkPosition={formValues.respostaQuatro}>
            <S.Cristais
              src="/preto-100.png"
              type="button"
              onClick={() => onClick("respostaQuatro", 1)}
            />

            <S.Cristais
              src="/preto-70.png"
              type="button"
              onClick={() => onClick("respostaQuatro", 2)}
            />

            <S.Cristais
              src="/preto-50.png"
              type="button"
              onClick={() => onClick("respostaQuatro", 3)}
            />

            <S.Cristais
              src="/preto-30.png"
              type="button"
              onClick={() => onClick("respostaQuatro", 4)}
            />

            <S.Cristais
              src="/branco.png"
              type="button"
              onClick={() => onClick("respostaQuatro", 5)}
            />

          </S.CirstaisWrapper>

          <Row className="m-0 line-title-avaliacao">
            <Col className="p-0"><C.Title>iniciante</C.Title></Col>
            <Col><C.Title>regular</C.Title></Col>
            <Col><C.Title>bom</C.Title></Col>
            <Col><C.Title>ótimo</C.Title></Col>
            <Col><C.Title>jedi</C.Title></Col>
          </Row>

          <p className="alin-text">{formValues.questionario?.quintaPergunta}</p>
          <S.CirstaisWrapper checkPosition={formValues.respostaCinco}>

            <S.Cristais
              src="/preto-100.png"
              type="button"
              onClick={() => onClick("respostaCinco", 1)}
            />

            <S.Cristais
              src="/preto-70.png"
              type="button"
              onClick={() => onClick("respostaCinco", 2)}
            />

            <S.Cristais
              src="/preto-50.png"
              type="button"
              onClick={() => onClick("respostaCinco", 3)}
            />

            <S.Cristais
              src="/preto-30.png"
              type="button"
              onClick={() => onClick("respostaCinco", 4)}
            />

            <S.Cristais
              src="/branco.png"
              type="button"
              onClick={() => onClick("respostaCinco", 5)}
            />

          </S.CirstaisWrapper>

          <Row className="m-0 line-title-avaliacao">
            <Col className="p-0"><C.Title>iniciante</C.Title></Col>
            <Col><C.Title>regular</C.Title></Col>
            <Col><C.Title>bom</C.Title></Col>
            <Col><C.Title>ótimo</C.Title></Col>
            <Col><C.Title>jedi</C.Title></Col>
          </Row>

          <p className="alin-text">{formValues.questionario?.sextaPergunta}</p>
          <S.CirstaisWrapper checkPosition={formValues.respostaSeis}>

            <S.Cristais
              src="/preto-100.png"
              type="button"
              onClick={() => onClick("respostaSeis", 1)}
            />

            <S.Cristais
              src="/preto-70.png"
              type="button"
              onClick={() => onClick("respostaSeis", 2)}
            />

            <S.Cristais
              src="/preto-50.png"
              type="button"
              onClick={() => onClick("respostaSeis", 3)}
            />

            <S.Cristais
              src="/preto-30.png"
              type="button"
              onClick={() => onClick("respostaSeis", 4)}
            />

            <S.Cristais
              src="/branco.png"
              type="button"
              onClick={() => onClick("respostaSeis", 5)}
            />

          </S.CirstaisWrapper>

          <Row className="m-0 line-title-avaliacao">
            <Col className="p-0"><C.Title>iniciante</C.Title></Col>
            <Col><C.Title>regular</C.Title></Col>
            <Col><C.Title>bom</C.Title></Col>
            <Col><C.Title>ótimo</C.Title></Col>
            <Col><C.Title>jedi</C.Title></Col>
          </Row>

          <p className="alin-text">{formValues.questionario?.setimaPergunta}</p>
          <S.CirstaisWrapper checkPosition={formValues.respostaSete}>

            <S.Cristais
              src="/preto-100.png"
              type="button"
              onClick={() => onClick("respostaSete", 1)}
            />

            <S.Cristais
              src="/preto-70.png"
              type="button"
              onClick={() => onClick("respostaSete", 2)}
            />

            <S.Cristais
              src="/preto-50.png"
              type="button"
              onClick={() => onClick("respostaSete", 3)}
            />

            <S.Cristais
              src="/preto-30.png"
              type="button"
              onClick={() => onClick("respostaSete", 4)}
            />

            <S.Cristais
              src="/branco.png"
              type="button"
              onClick={() => onClick("respostaSete", 5)}
            />

          </S.CirstaisWrapper>

          <Row className="m-0 line-title-avaliacao">
            <Col className="p-0"><C.Title>iniciante</C.Title></Col>
            <Col><C.Title>regular</C.Title></Col>
            <Col><C.Title>bom</C.Title></Col>
            <Col><C.Title>ótimo</C.Title></Col>
            <Col><C.Title>jedi</C.Title></Col>
          </Row>
          {parseInt(formValues.pessoaAvaliada.id) === colaboradorPessoa?.id ?
            <>
              <p className="alin-text">{formValues.questionario?.oitavaPergunta}</p>
              <S.CirstaisWrapper checkPosition={formValues.respostaOito}>

                <S.Cristais
                  src="/preto-100.png"
                  type="button"
                  onClick={() => onClick("respostaOito", 1)}
                />

                <S.Cristais
                  src="/preto-70.png"
                  type="button"
                  onClick={() => onClick("respostaOito", 2)}
                />

                <S.Cristais
                  src="/preto-50.png"
                  type="button"
                  onClick={() => onClick("respostaOito", 3)}
                />

                <S.Cristais
                  src="/preto-30.png"
                  type="button"
                  onClick={() => onClick("respostaOito", 4)}
                />

                <S.Cristais
                  src="/branco.png"
                  type="button"
                  onClick={() => onClick("respostaOito", 5)}
                />

              </S.CirstaisWrapper>

              <Row className="m-0 line-title-avaliacao">
                <Col className="p-0"><C.Title>iniciante</C.Title></Col>
                <Col><C.Title>regular</C.Title></Col>
                <Col><C.Title>bom</C.Title></Col>
                <Col><C.Title>ótimo</C.Title></Col>
                <Col><C.Title>jedi</C.Title></Col>
              </Row>

              <p className="alin-text">{formValues.questionario?.nonaPergunta}</p>
              <S.CirstaisWrapper checkPosition={formValues.respostaNove}>

                <S.Cristais
                  src="/preto-100.png"
                  type="button"
                  onClick={() => onClick("respostaNove", 1)}
                />

                <S.Cristais
                  src="/preto-70.png"
                  type="button"
                  onClick={() => onClick("respostaNove", 2)}
                />

                <S.Cristais
                  src="/preto-50.png"
                  type="button"
                  onClick={() => onClick("respostaNove", 3)}
                />

                <S.Cristais
                  src="/preto-30.png"
                  type="button"
                  onClick={() => onClick("respostaNove", 4)}
                />

                <S.Cristais
                  src="/branco.png"
                  type="button"
                  onClick={() => onClick("respostaNove", 5)}
                />

              </S.CirstaisWrapper>

              <Row className="m-0 line-title-avaliacao">
                <Col className="p-0"><C.Title>iniciante</C.Title></Col>
                <Col><C.Title>regular</C.Title></Col>
                <Col><C.Title>bom</C.Title></Col>
                <Col><C.Title>ótimo</C.Title></Col>
                <Col><C.Title>jedi</C.Title></Col>
              </Row>
            </>
            : null}
        </S.Divider>
      </Container>

      <S.Divider className="alin-btn-avaliacao">
        <Link to="/home">
          <Button id="voltar">Voltar</Button>
        </Link>
        <Button id="salvar">Salvar</Button>
      </S.Divider>
    </S.Wrapper>
  );
}

export default FormAvaliacao;
