import styled from 'styled-components';

/********************************| Header padrão |********************************/
export const Header = styled.div`
    position: relative;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(4, 1fr);
    height: 50px;
    border-radius: 10px;

    margin: 10px auto 10px auto;
    transition: 0.3s ease-in-out;
    background-color: var(--color-01);

    h2 {
        text-align: center;
    }

    @media (max-width: 900px) {
        height: 70px;
    }
`;