import { Col } from 'helpers/ColRow';
import "./styles.css";

function ThreeSquareVertical() {
    return (
        <>
            <Col>
                <span className="element-vertical-cor-green" />
                <span className="element-vertical-cor-yellow" />
                <span className="element-vertical-cor-red" />
            </Col>
        </>
    );
}

export default ThreeSquareVertical;