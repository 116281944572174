import { Image } from "react-bootstrap";
import { DivCenter } from "helpers/divider/StylesDivider";
import mestre_carousel from "../../../_assets/img/img-png/personagem-patente/slice6.png";
import mestra_carousel from "../../../_assets/img/img-png/personagem-patente/slice5.png";
import mestreInsigniaCarousel from "../../../_assets/img/img-png/insignias-patente/mestre.png";
import "./mestre.styles.css";
import "../styles.css";

export const MestreCarousel = () => (
    <>
        <DivCenter>
            <Image src={mestre_carousel} alt="img-mestre-carousel" id="manual-mestre-carousel" className="manual-patente-carousel" />

            <DivCenter className="block-patente-mestre-title">
                <h2 className="alin-title-patente-carrossel">mestre</h2>
            </DivCenter>

            <DivCenter className="block-carrossel-full block-config-mestre-carrossel">
                <Image src={mestreInsigniaCarousel} alt="img-mestre-insignia-carousel" id="manual-mestre-insignia-carousel" className="manual-patente-insignia-mestre-carousel" />
            </DivCenter>

            <Image src={mestra_carousel} alt="img-mestra-carousel" id="manual-mestra-carousel" className="manual-patente-carousel" />
        </DivCenter>
    </>
);