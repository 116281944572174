import moment from "moment";
import { useEffect, useState } from "react";
import { Api } from "_config/api/apiService";
import { Colaborador, LogPatente } from "_config/services/types";
import ThreeSquareHorizontal from "Components/ThreeSquareHorizontal";
import './styles.css';
import { Col, Divider } from "helpers/divider/StylesDivider";
import { Container } from "helpers/Container";
import CardInsignia from "Components/CardInsgnia";
import ImportImgPeople from "Components/ImportImgPeople";

function Jornada() {

    const [usuarioLogado, setUsuarioLogado] = useState<Colaborador>();
    const [log, setLog] = useState<LogPatente[]>([]);
    const user = JSON.parse(localStorage.getItem('usuario') || '{}');
    console.log(log)

    useEffect(() => {
        Api.get(`pessoa/${user.id}`)
            .then(response => {
                setUsuarioLogado(response.data);
            })
    }, [user.id])

    useEffect(() => {
        Api.get(`log-patente`)
            .then(response => {
                setLog(response.data);
            })
    }, [])

    function corPatente(): string {
        let corBackGround = "";

        if (usuarioLogado?.patente === 'Young I') {
            corBackGround = 'young1';
        }
        if (usuarioLogado?.patente === 'Young II') {
            corBackGround = 'young2';
        }
        if (usuarioLogado?.patente === 'Padawan I') {
            corBackGround = 'padawan1';
        }
        if (usuarioLogado?.patente === 'Padawan II') {
            corBackGround = 'padawan2';
        }
        if (usuarioLogado?.patente === 'Padawan III') {
            corBackGround = 'padawan3';
        }
        if (usuarioLogado?.patente === 'Sentinela I') {
            corBackGround = 'sentinela1';
        }
        if (usuarioLogado?.patente === 'Sentinela II') {
            corBackGround = 'sentinela2';
        }
        if (usuarioLogado?.patente === 'Sentinela III') {
            corBackGround = 'sentinela3';
        }
        if (usuarioLogado?.patente === 'Cavaleiro I') {
            corBackGround = 'cavaleiro1';
        }
        if (usuarioLogado?.patente === 'Cavaleiro II') {
            corBackGround = 'cavaleiro2';
        }
        if (usuarioLogado?.patente === 'Cavaleiro III') {
            corBackGround = 'cavaleiro3';
        }
        if (usuarioLogado?.patente === 'Diplomata') {
            corBackGround = 'diplomata';
        }
        if (usuarioLogado?.patente === 'Mestre') {
            corBackGround = 'mestre';
        }
        if (usuarioLogado?.patente === 'Consul') {
            corBackGround = 'consul';
        }
        if (usuarioLogado?.patente === 'Gran Mestre') {
            corBackGround = 'granMestre';
        }
        if (usuarioLogado?.patente === 'Guardião') {
            corBackGround = 'guardiao';
        }
        return corBackGround;
    }

    const string = usuarioLogado?.patente?.split(' ');
    const string2 = string ? string[1] : null;
    const textoPatente = string ? string[0] : null;

    function textoConformeNivel(): string {
        let textoNivel = "";

        if (string2 === 'I') {
            textoNivel = 'nível 1'
        }
        if (string2 === 'II') {
            textoNivel = 'nível 2'
        }
        if (string2 === 'III') {
            textoNivel = 'nível 3'
        }
        return textoNivel;
    }

    const today = new Date().toISOString().substring(0, 10);
    const admissao = usuarioLogado?.dataAdmissao;
    const diff = moment(today, "YYYY-MM-DD").diff(moment(admissao, "YYYY-MM-DD"));
    const dias = moment.duration(diff).asDays().toFixed();


    return (
        <>
            <Container className="config-container-jornada">

                <Col>
                    <Divider className="box-img-jornada">
                        <ImportImgPeople />
                    </Divider>
                </Col>

                <Col>
                    <Divider className="aling-box-infojornada">
                        <Divider className="alin-title-jornada">
                            <ThreeSquareHorizontal />
                            <h1>olá, {usuarioLogado?.nome}</h1>
                        </Divider>
                        <h2>{textoPatente + ' ' + textoConformeNivel()}</h2>
                        <Divider className={`${corPatente()} config-box-info-jornada`}>
                            <h3>Setor: {usuarioLogado?.area}</h3>
                            <h3>Você está conosco há {dias} dias!</h3>
                        </Divider>
                    </Divider>
                </Col>

                <Col>
                    <CardInsignia />
                </Col>

            </Container>
        </>
    );
}

export default Jornada;