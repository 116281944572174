import { Navigate } from "react-router-dom";
import { useAuth } from "context/AuthProvider/useAuth";
import NavBar from "Components/NavBar/Index";

const PrivateRotas: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const { login } = useAuth();

  return login ? <><NavBar />{children}</> : <Navigate to="/" />
}

export default PrivateRotas;
