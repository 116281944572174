import { useAuth } from "context/AuthProvider/useAuth";
import { CardIcon } from "helpers/card-icon";
import { Col, Row } from "helpers/ColRow";
import { Container } from "helpers/Container";
import { Header } from "helpers/header";
import { Title } from "helpers/titles";
import { FilePdfFill } from "react-bootstrap-icons";
import { FaHouseUser, FaRegClipboard, FaRegNewspaper } from "react-icons/fa";
import { Link } from "react-router-dom";
import { PerfilFuncao } from "_config/constant";
import "./styles.css";

function PainelAdmin() {
    const usuario = useAuth();

    return (
        <Container>

            <Header>
                <Title>Painel de Administração</Title>
            </Header>

            <Row className="config-row-painel-admin">
                {usuario.admin.codigo === PerfilFuncao.CODIGO_RECURSOS_HUMANOS ||
                    usuario.admin.codigo === PerfilFuncao.CODIGO_TECNOLOGIA ?
                    <>
                        <Col className="config-col-painel-admin">
                            <Link to="/lista-colaborador-perfil" >
                                <CardIcon>
                                    <FaHouseUser id="fa-house-user" />
                                </CardIcon>
                                <h3>perfil de acesso</h3>
                            </Link>
                        </Col>

                        <Col className="config-col-painel-admin">
                            <Link to="/painel-avaliacao">
                                <CardIcon>
                                    <FaRegClipboard id="fa-reg-clipboard" />
                                </CardIcon>
                                <h3>avaliações</h3>
                            </Link>
                        </Col>

                        <Col className="config-col-painel-admin">
                            <Link to="/jornal-happy">
                                <CardIcon>
                                    <FaRegNewspaper id="fa-reg-news-paper" />
                                </CardIcon>
                                <h3>jornal happy</h3>
                            </Link>
                        </Col>
                    </>
                    : null}

                <Col className="config-col-painel-admin">
                    <Link to="/upload-holerite">
                        <CardIcon>
                            <FilePdfFill id="fa-reg-news-paper" />
                        </CardIcon>
                        <h3>Holerite</h3>
                    </Link>
                </Col>
            </Row>
        </Container >
    );
}

export default PainelAdmin;