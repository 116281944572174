import { Divider } from 'helpers/divider/StylesDivider';
import { useState, useRef } from 'react';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import { FaPhoneAlt, FaRegAddressBook, FaRegEnvelope, FaWhatsapp } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { linkCelular } from '_config/constant/linkCelular';
import { linkSendEmail } from '_config/constant/linkEmail';
import { linkWhatsApp } from '_config/constant/linkWhatsApp';
import { IColaboradorFullDTO } from '_config/models/ordem-happy-api-model';
import "./styles.css";

interface Props {
    colaborador: IColaboradorFullDTO
}

function ContatosColaborador({ colaborador }: Props) {
    const [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);
    const ref = useRef(null);

    const handleClick = (e: any) => {
        setShow(!show);
        setTarget(e.target);
    };

    return (
        <div style={{margin: "auto"}} ref={ref} >
            <FaRegAddressBook onClick={handleClick} className="colaborador-contato-icon" />

            <Overlay
                show={show}
                target={target}
                placement="top"
                container={ref}
                containerPadding={20}
            >
                <Popover id="popover-contained">
                    <Popover.Header as="h3">Contatos {colaborador.genero === "Masculino" ? "do colaborador" : colaborador.genero === "Feminino" ? "da colaboradora" : null} {colaborador.nome}</Popover.Header>
                    <Popover.Body className="config-box-tooltip-contato-colaborador">
                        {colaborador.telefone || colaborador.emailCorporativo || colaborador.emailPessoal ?
                            <>
                                {
                                    colaborador.telefone ?
                                        <Divider>
                                            <strong>WhatsApp</strong>
                                            <Link onClick={(e) => linkWhatsApp(colaborador)} to="">
                                                <FaWhatsapp id="fa-whatsapp" className="colaborador-contato-icon" />
                                            </Link>
                                        </Divider>
                                        : null
                                }

                                {colaborador.emailCorporativo || colaborador.emailPessoal ?
                                    <Divider>
                                        <strong>Enviar e-mail</strong>
                                        <Link onClick={(e) => linkSendEmail(colaborador)} to="">
                                            <FaRegEnvelope id="fa-reg-evelope" className="colaborador-contato-icon" />
                                        </Link>
                                    </Divider>
                                    : null}

                                {colaborador.telefone ?
                                    <Divider>
                                        <strong>Ligar</strong>
                                        <Link onClick={(e) => linkCelular(colaborador)} to="">
                                            <FaPhoneAlt id="fa-phone-alt" className="colaborador-contato-icon" />
                                        </Link>
                                    </Divider>
                                    : null}
                            </>
                            : <p>Colaborador não possui informações para contato cadastrado no sistema.</p>}

                        {/*
                        <strong>Ligar</strong>
                        <Link to="//goo.gl/maps/GLfLfAwBa4p5mQRF9" target="_blank">
                        <Geo id="md-outline-feedback" className="colaborador-contato-icon" />
                        </Link>
                    */}

                    </Popover.Body>
                </Popover>
            </Overlay>
        </div>
    );
}
export default ContatosColaborador;