import { AuthProvider } from 'context/AuthProvider';
import Rotas from "./_config/rotas/Rotas";

const App = () => {
  return (
    <>
      <AuthProvider>
        <Rotas />
      </AuthProvider>
    </>
  );
}

export default App;