import { Api } from '_config/api/apiService';
import { IAxiosResponseCustom } from '_config/models/http.model';
import { IFeedbackFullDTO } from '_config/models/ordem-happy-api-model';

export const useFeedbackService = () => {
    const resourceURL = 'feedback';

    const feedback = (
        feedbackId: number,
    ): Promise<IAxiosResponseCustom<IFeedbackFullDTO>> =>
        Api.get(`${resourceURL}/${feedbackId}`);

    const feedbackList = (
        pessoaAvaliadaId: any,
    ): Promise<IAxiosResponseCustom<IFeedbackFullDTO[]>> =>
        Api.get(`${resourceURL}/listar-feedback/${pessoaAvaliadaId}`);

    const novoFeedback = (
        feedback: any,
    ): Promise<IAxiosResponseCustom<IFeedbackFullDTO>> =>
        Api.post(`${resourceURL}/novo-feedback`, feedback);

    const editarFeedback = (
        feedbackId: number,
        feedback: any,
    ): Promise<IAxiosResponseCustom<IFeedbackFullDTO>> =>
        Api.patch(`${resourceURL}/editar-feedback/${feedbackId}`, feedback);

    return {
        feedback,
        feedbackList,
        novoFeedback,
        editarFeedback,
    };
};
