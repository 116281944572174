import { Image } from "react-bootstrap";
import mundo from '../../../../_assets/img/img-png/manual/mundo-happy.png';
import young from '../../../../_assets/img/img-png/personagem-patente/slice12.png';
import padawan from '../../../../_assets/img/img-png/personagem-patente/slice11.png';
import sentinela from '../../../../_assets/img/img-png/personagem-patente/slice10.png';
import cavaleiro from '../../../../_assets/img/img-png/personagem-patente/slice9.png';
import youngInsignia from '../../../../_assets/img/img-png/insignias-patente/young 3.png';
import padawanInsignia from '../../../../_assets/img/img-png/insignias-patente/padawan 3.png';
import sentinelaInsignia from '../../../../_assets/img/img-png/insignias-patente/sentinela 3.png';
import cavaleiroInsignia from '../../../../_assets/img/img-png/insignias-patente/cavaleiro 3.png';

export const ManualMundo = () => (

    <Image src={mundo} alt="Mundo-Happy" className="manual-mundo" />
);

export const Young = () => (

    <Image src={young} alt="img-young" id="manual-young" className="manual-patente" />
);

export const Padawan = () => (

    <Image src={padawan} alt="img-padawan" id="manual-padawan" className="manual-patente" />
);

export const Sentinela = () => (

    <Image src={sentinela} alt="img-sentinela" id="manual-sentinela" className="manual-patente" />
);

export const Cavaleiro = () => (

    <Image src={cavaleiro} alt="img-cavaleiro" id="manual-cavaleiro" className="manual-patente" />
);

export const YoungInsignia = () => (

    <Image src={youngInsignia} alt="img-young-insignia" id="manual-young-insignia-young" className="manual-patente-insignia-young" />
);

export const PadawanInsignia = () => (

    <Image src={padawanInsignia} alt="img-padawan-insignia" id="manual-padawan-insignia-padawan" className="manual-patente-insignia-padawan" />
);

export const SentinelaInsignia = () => (

    <Image src={sentinelaInsignia} alt="img-sentinela-insignia" id="manual-sentinela-insignia-sentinela" className="manual-patente-insignia-sentinela" />
);

export const CavaleiroInsignia = () => (

    <Image src={cavaleiroInsignia} alt="img-cavaleiro-insignia" id="manual-cavaleiro-insignia" className="manual-patente-insignia-cavaleiro" />
);