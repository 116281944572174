
import { Api } from '_config/api/apiService';
import { IAxiosResponseCustom } from '_config/models/http.model';
import { IFuncaoFullDTO } from '_config/models/ordem-happy-api-model';

export const useFuncaoService = () => {
	const resourceURL = 'funcao';

	const perfilFuncao = (
	): Promise<IAxiosResponseCustom<IFuncaoFullDTO>> =>
		Api.get(`${resourceURL}/perfil-funcao`);

	const perfilFuncaoList = (
	): Promise<IAxiosResponseCustom<IFuncaoFullDTO[]>> =>
		Api.get(`${resourceURL}/listar-perfil-funcao/`);

	const perfilFuncaoListColaborador = (
		colaboradorId: number,
	): Promise<IAxiosResponseCustom<IFuncaoFullDTO[]>> =>
		Api.get(`${resourceURL}/perfil-funcao/${colaboradorId}`);

	return {
		perfilFuncao,
		perfilFuncaoList,
		perfilFuncaoListColaborador,
	};
};
