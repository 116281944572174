
import { Api } from '_config/api/apiService';
import { IAxiosResponseCustom } from '_config/models/http.model';
import { IQuestionarioPessoaFullDTO } from '_config/models/ordem-happy-api-model';


export const useQuestionarioPessoaService = () => {
	const resourceURL = 'questionario-pessoa';

	const questionarioPessoa = (
		colaboradorId: number
	): Promise<IAxiosResponseCustom<IQuestionarioPessoaFullDTO[]>> =>
		Api.get(`${resourceURL}/${colaboradorId}`);

	const enviarAvaliacao = (
		avaliacao: any,
	): Promise<IAxiosResponseCustom<IQuestionarioPessoaFullDTO>> =>
		Api.post(`${resourceURL}/resposta-questionario`, avaliacao);

	return {
		questionarioPessoa,
		enviarAvaliacao,
	};
};
