import moment from "moment";

export const formatMoney = (value: bigint) => {
    const money = value?.toString();
    return (Number(money?.replace(/\D/g, "")) / 100)?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
};

export const formatCpf = (value: string) => {

    return value?.replace(/\D/g, '')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1')
}

export const formatPhone = (value: string) => {
    return value?.replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{5})(\d)/, '$1-$2')
        .replace(/(-\d{4})\d+?$/, '$1')
}

export const formatCep = (value: number) => {
    const cep = value?.toString();

    return cep?.replace(/\D/g, "").replace(/^(\d{5})(\d{3})+?$/, "$1-$2")
}

// Aceita apenas que letras sejam digitadas
export const formatOnlyLetters = (value: string) => {
    const letra = value.toString();
    return letra?.replace(/[0-9!@#¨$%^&*)(+=._-]+/g, "");
};

// Aceita apenas números
export const formatOnlyNumbers = (value: number) => {
    const numero = value.toString();
    return numero.replace(/\D/g, "");
};

// Formata apenas a data para padrão americano ex: 1990-01-01
export const formatDateUSA = (value: Date) => {
    return moment(value).format("YYYY-MM-DD")
};

