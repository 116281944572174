import { Asterisk } from 'react-bootstrap-icons';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { AiFillWarning} from "react-icons/ai";
import "./styles.css";

const popover = (
  <Popover id="popover-basic">
    <Popover.Header as="h3"><AiFillWarning id="ai-fill-warning" className="icons-placa-atencao"/>Dado Obrigatório</Popover.Header>
    <Popover.Body>
      Deve ser cadastrado o superior imediato para que a avaliação seja aplicada corretamente.
    </Popover.Body>
  </Popover>
);

export const Asterisco = () => (
  <OverlayTrigger trigger="hover" placement="right" overlay={popover}>
    <Asterisk id='Asterisk' className="cadastro-asterisco-gestor" />
  </OverlayTrigger>
);