import { Image } from "react-bootstrap";
import { DivCenter, Divider } from "helpers/divider/StylesDivider";
import sentinela_carousel from "../../../_assets/img/img-png/personagem-patente/slice10.png";
import sentinelaInsigniaCarousel from "../../../_assets/img/img-png/insignias-patente/sentinela 3.png";
import "./sentinela.styles.css";
import "../styles.css";

export const SentinelaCarousel = () => (
    <>
        <Image src={sentinela_carousel} alt="img-sentinela-carousel" id="manual-sentinela-carousel" className="manual-patente-carousel" />
        <Divider className="alin-tabela-salario-sentinela-carrossel">
            <DivCenter>
                <h2 className="titles-insignia-cargo-carrosel">sentinela</h2>
                <Image src={sentinelaInsigniaCarousel} alt="img-sentinela-insignia-carousel" id="manual-sentinela-insignia-sentinela-carousel" className="manual-patente-insignia-sentinela-carousel" />
            </DivCenter>
            <Divider className="block-carrossel block-config1-sentinela-carrossel"><p className="alin-text-patente-carrossel">?</p></Divider>
            <Divider className="block-carrossel block-config2-sentinela-carrossel"><p className="alin-text-patente-carrossel">?</p></Divider>
            <Divider className="block-carrossel block-config3-sentinela-carrossel"><p className="alin-text-patente-carrossel">R$ 4.500,00</p></Divider>
            <Divider className="block-carrossel block-config4-sentinela-carrossel"><p className="alin-text-patente-carrossel">R$ 3.800,00</p></Divider>
            <Divider className="block-carrossel block-config5-sentinela-carrossel"><p className="alin-text-patente-carrossel">-</p></Divider>
        </Divider>
    </>
);
