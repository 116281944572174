import styled from "styled-components";

export const BoxForm = styled.div`
    position: relative;
    display: block;
    align-items: center;
    justify-content: center;
    width: 80%;
    padding: 20px;
    border-radius: 10px;
    margin: auto auto 20px auto;
    color: var(--color-01);
    background-color: var(--color-08);
`;

export const BoxContadorCaractere = styled.div`
    display: flex;
    justify-content: end;

    p{
        color: var(--color-01);
    }
`;