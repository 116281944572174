import { faBalanceScale, faFile, faGears, faHandshake, faSchool, faServer } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container } from 'helpers/Container';
import { Col, Row } from 'react-bootstrap';
import "./styles.css";

function Intranet() {
    return (
        <Container className="alin-container-menu-intranete">
            <Row>
                <Col>
                    <FontAwesomeIcon className="icons-intranete-menu" icon={faSchool} />
                </Col>
                <Col>
                    <FontAwesomeIcon className="icons-intranete-menu" icon={faHandshake} />
                </Col>
                <Col>
                    <FontAwesomeIcon className="icons-intranete-menu" icon={faServer} />
                </Col>
            </Row>

            <Row>
                <Col>
                    <FontAwesomeIcon className="icons-intranete-menu" icon={faGears} />
                </Col>
                <Col>
                    <FontAwesomeIcon className="icons-intranete-menu" icon={faFile} />
                </Col>
                <Col>
                    <FontAwesomeIcon className="icons-intranete-menu" icon={faBalanceScale} />
                </Col>
            </Row>
        </Container>
    );
}

export default Intranet