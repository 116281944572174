import styled from "styled-components";

/********************************| Configuração de coluna em componente |********************************/
/*alinhar elementos do container ao centro em coluna um elemento abaixo do outro*/
export const Col = styled.div`
    display: flex;
    flex-direction: column;
`;
/********************************| Configuração de linha em componente |********************************/
/*alinhar elementos do container ao centro em linha elemento lado a lado*/
export const Row = styled.div`
    display: flex;
    flex-direction: row;

`;