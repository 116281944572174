import { useEffect, useState } from "react";
import { Api } from "_config/api/apiService";
import { mensagemErro } from "Components/Toastr/toastr";
import { Divider } from "helpers/divider/StylesDivider";
import PainelGestao from "Components/PainelGestao";
import PaginationColaborador from "Components/Pagination/paginationColaborador";
import { IColaboradorListDTO } from "_config/models/ordem-happy-api-model";
import './styles.css';
import { Container } from "helpers/Container";
import { Header } from "helpers/header";
import { Title } from "helpers/titles";

function Ascensao() {
    const [ativo, setAtivo] = useState(true);
    const [pageNumber, setPageNumber] = useState(0);
    const [page, setPage] = useState<IColaboradorListDTO>({
        content: [],
        last: true,
        totalPages: 0,
        totalElements: 0,
        size: 20,
        number: 0,
        first: true,
        numberOfElements: 0,
        empty: true
    });

    useEffect(() => {
        Api.get(`/pessoa?size=20&page=${pageNumber}&sort=nome&ativo=${ativo}`)
            .then(response => {
                const data = response.data as IColaboradorListDTO;
                setPage(data);
            })
            .catch(error =>
                mensagemErro("Falha na conexão!"))
    }, [pageNumber, ativo])

    const handlePageChange = (newPageNumber: number) => {
        setPageNumber(newPageNumber);
    }

    {/*const isAtivo = () => {
        setAtivo(true);
    }

    const isInativo = () => {
        setPageNumber(0);
        setAtivo(false);
    }*/}

    return (
        <Container>
            <Header>
                <Title>Colaboradores ({page.totalElements})</Title>
            </Header>

            <Divider className="alin-lista-painel-gestao">
                {ativo === true ?
                    page?.content.map(colaborador => (
                        <PainelGestao key={colaborador.id} colaborador={colaborador} />
                    )) :
                    page?.content.map(colaborador => (
                        <PainelGestao key={colaborador.id} colaborador={colaborador} />
                    ))
                }
            </Divider>
            <PaginationColaborador page={page} onChange={handlePageChange} />
        </Container>
    );
}

export default Ascensao;