import { IColaboradorFullDTO } from "_config/models/ordem-happy-api-model";
import { checkMobile } from "../checkMobile";
import { dataSaudacaoArray } from "../dataSaudacaoArray";

export function linkWhatsApp(colaborador: IColaboradorFullDTO) {
    const pl = "\n"; //Pular Linha

    const nome = colaborador.nome.split(' ').shift();
    const saudacao = dataSaudacaoArray.object.map(saudacao => saudacao.descricao);
    const emoji = dataSaudacaoArray.object.map(saudacao => saudacao.emoji);
    var body = "";

    body += `${nome}, ${saudacao} ${emoji}`;
    body += `${pl}${pl}`;

    body = window.encodeURIComponent(body);

    if (checkMobile()) {
        return window.open(`//api.whatsapp.com/send?phone=55${colaborador.telefone}&text=${body}`, "_blank");
    }
    else {
        return window.open(`//web.whatsapp.com/send?phone=55${colaborador.telefone}&text=${body}`, "_blank");
    }
}
