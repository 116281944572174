import { useEffect, useState } from "react";
import { useColaboradorService } from "_config/service/colaborador.service";
import { IColaboradorFullDTO } from "_config/models/ordem-happy-api-model";
import { Image } from "react-bootstrap"
import "./styles.css"

function ImportImgPeople() {
    const user = JSON.parse(localStorage.getItem('u') || '{}');
    const [colaborador, setColaborador] = useState<IColaboradorFullDTO>();
    const serviceColaboradores = useColaboradorService();

    const colaboradores = () => {
        serviceColaboradores.colaboradores(user?.id)
            .then(response => {
                setColaborador(response.data)
            });
    }

    useEffect(() => {
        colaboradores();
    }, [])

    return (
        <>
            <Image className="avatar-colaborador" src={require(`_assets/img/img-png/people/colaborador-id/${(user?.id.toString())}.jpg`)} alt={`Imagem ${colaborador?.nome}`} />
        </>
    )
}

export default ImportImgPeople;