import { useEffect, useState } from 'react'
import { useColaboradorService } from '_config/service/colaborador.service';
import { IColaboradorFullDTO } from '_config/models/ordem-happy-api-model';
import moment from 'moment';
import { DivCenterPR } from 'helpers/divider/StylesDivider';
import "./styles.css";

function CaixaTextMsg() {
    const user = JSON.parse(localStorage.getItem('usuario') || '{}');
    const [colaborador, setColaborador] = useState<IColaboradorFullDTO>();
    const serviceColaboradores = useColaboradorService();

    const colaboradores = () => {
        serviceColaboradores.colaboradores(user?.id)
            .then(response => {
                setColaborador(response.data)
            });
    }

    useEffect(() => {
        colaboradores();
    }, [])

    const today = new Date().toISOString().substring(0, 10);
    const admissao = colaborador?.dataAdmissao;
    const diff = moment(today, "YYYY-MM-DD").diff(moment(admissao, "YYYY-MM-DD"));
    const dias = moment.duration(diff).asDays().toFixed();
    return (
        <>
            <DivCenterPR className="msg-container">
                <p className="msg-text">{colaborador?.nome}</p>
                <p className="msg-text">seja bem-vindo a ordem happy!</p>
                <p className="msg-text">a sua jornada conosco já dura {dias} dias!</p>
                <p className="msg-text">atualmente você está na patente: {colaborador?.patente}</p>
            </DivCenterPR>
        </>
    )
}

export default CaixaTextMsg;