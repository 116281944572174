export enum PerfilFuncao {
   CODIGO_USUARIO= "USU",
   CODIGO_ADMINISTRADOR= "ADM",
   CODIGO_RECURSOS_HUMANOS= "RH",
   CODIGO_TECNOLOGIA= "TEC",
   CODIGO_GESTOR= "GST",
   CODIGO_COLABORADOR_CNPJ= "CNPJ",
}

export enum Patente {
    YOUNG_I= "Young I",
    YOUNG_II= "Young II",
    PADAWAN_I= "Padawan I",
    PADAWAN_II= "Padawan II",
    PADAWAN_III= "Padawan III",
    SENTINELA_I= "Sentinela I",
    SENTINELA_II= "Sentinela II",
    SENTINELA_III= "Sentinela III",
    CAVALEIRO_I= "Cavaleiro I",
    CAVALEIRO_II= "Cavaleiro II",
    CAVALEIRO_III= "Cavaleiro III",
    DIPLOMATA= "Diplomata",
    MESTRE= "Mestre",
    CONSUL= "Consul",
    GRAN_MESTRE= "Gran Mestre",
    GUARDIAO= "Guardião",
 }