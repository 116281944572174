import styled from "styled-components";

export const Title = styled.h1`
    display: block;
    color: var(--color-08);

    @media (max-width: 600px) {
        text-align: center;
    }
`;













export const H5 = styled.h5`
    color: var(--azul);
    font-weight: bold;
    font-family: 'Kobe-Black', 'Kobe-Bold';
    text-align: center;
    width: max-content;
`;