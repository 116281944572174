import styled from 'styled-components';

/********************************| lista padrão |********************************/
export const Lista = styled.div`
    position: relative;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(4, 1fr);
    height: 75px;
    border-radius: 10px;
    padding: 5px;
    margin: 10px auto 10px auto;
    transition: 0.3s ease-in-out;
    background-color: var(--color-01);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
        rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
        rgba(0, 0, 0, 0.2) 0px -3px 0px inset;

        &:hover {
            transition: 0.3s ease-in-out;
            transform: translateY(-5px);
            box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
        }

    svg{
        color: var(--color-08);
        font-size: 2em;

        &:hover{
            color: var(--color-05);
        }
    }

    a {
        margin: auto !important;
    }

    p {
        text-align: center;
    }

    @media (max-width: 900px) {
        height: 100px;

        strong {
            font-size: 0.9em;
        }

        svg{
            font-size: 1.8rem;
        }
    }

    @media (max-width: 600px) {
        height: 130px;
    }
`;