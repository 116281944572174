import styled from 'styled-components';

/********************************| Box lista padrão |********************************/
export const BoxList = styled.div`
    position: relative;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 90%;
    padding: 10px 15px;
    background-color: var(--color-08);
    border-radius: 10px;

    @media (max-width: 600px) {
        width: 100%;
    }
`;