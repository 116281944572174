
import { CavaleiroCarousel } from 'Components/ColaboradorPatente/Cavaleiro';
import { ConsulCarousel } from 'Components/ColaboradorPatente/Consul';
import { DiplomataCarousel } from 'Components/ColaboradorPatente/Diplomata';
import { GranMestreCarousel } from 'Components/ColaboradorPatente/GranMestre';
import { GuardiaoCarousel } from 'Components/ColaboradorPatente/Guardiao';

import { MestreCarousel } from 'Components/ColaboradorPatente/Mestre';
import { PadawanCarousel } from 'Components/ColaboradorPatente/Padawan';
import { SentinelaCarousel } from 'Components/ColaboradorPatente/Sentinela';
import { YoungCarousel } from 'Components/ColaboradorPatente/Young';
import { Divider } from 'helpers/divider/StylesDivider';
import { Carousel } from 'react-bootstrap';
import "./styles.css";

function CarrosselManual() {
    return (
        <>
            <Divider className="alin-carrossel">
                <Carousel className="config-carrossel">

                    <Carousel.Item interval={2000}>
                        <Divider className="alin-img-patente-carrossel">
                            <YoungCarousel />
                        </Divider>
                    </Carousel.Item>

                    <Carousel.Item interval={2000}>
                        <Divider className="alin-img-patente-carrossel">
                            <PadawanCarousel />
                        </Divider>
                    </Carousel.Item>

                    <Carousel.Item interval={2000}>
                        <Divider className="alin-img-patente-carrossel">
                            <SentinelaCarousel />
                        </Divider>
                    </Carousel.Item>

                    <Carousel.Item interval={2000}>
                        <Divider className="alin-img-patente-carrossel">
                            <CavaleiroCarousel />
                        </Divider>
                    </Carousel.Item>

                    <Carousel.Item interval={2000}>
                        <Divider className="alin-img-2patente-carrossel">
                            <DiplomataCarousel />
                        </Divider>
                    </Carousel.Item>

                    <Carousel.Item interval={2000}>
                        <Divider className="alin-img-2patente-carrossel">
                            <MestreCarousel />
                        </Divider>
                    </Carousel.Item>

                    <Carousel.Item interval={2000}>
                        <Divider className="alin-img-patente-carrossel">
                            <ConsulCarousel />
                        </Divider>
                    </Carousel.Item>

                    <Carousel.Item interval={2000}>
                        <Divider className="alin-img-2patente-carrossel">
                            <GranMestreCarousel />
                        </Divider>
                    </Carousel.Item>

                    <Carousel.Item interval={2000}>
                        <Divider className="alin-img-patente-carrossel">
                            <GuardiaoCarousel />
                        </Divider>
                    </Carousel.Item>

                </Carousel>
            </Divider>
        </>
    )
}

export default CarrosselManual;