import styled from 'styled-components';

/********************************| Configuração do Header |********************************/
export const Header = styled.header`
    margin: 50px 0 50px 75px;
    
    @media(max-width: 600px) {
        margin-left: auto;
        margin-right: auto;
    }

    @media(max-width: 400px) {
       margin-left: 0;
    }
`;