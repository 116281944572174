import { useEffect, useState } from 'react';
import { Col, Row } from "helpers/ColRow";
import { Container } from "helpers/Container";
import { H5, Title } from "helpers/titles";
import { BtnPadrao } from 'helpers/btn_especial/StylesButtom';
import { Link, useNavigate, useParams } from "react-router-dom";
import { Form } from 'react-bootstrap';
import "./styles.css";
import { Divider } from 'helpers/divider/StylesDivider';
import { IQuestionarioFullDTO } from '_config/models/ordem-happy-api-model';
import { useQuestionarioService } from '_config/service/questionario.service';
import { mensagemSucesso } from 'Components/Toastr/toastr';
import { Header } from 'helpers/header';


function EditarAvaliacao() {
    const { avaliacaoId }: any = useParams();
    const [questionarios, setQuestionarios] = useState<IQuestionarioFullDTO | any>();
    const serviceQuestionario = useQuestionarioService();
    const navigate = useNavigate();

    const questionario = () => {
        serviceQuestionario.questionario(avaliacaoId)
            .then(response => {
                setQuestionarios(response.data)
            });
    }

    const updateQuestionario = () => {
        serviceQuestionario.editarQuestionario(avaliacaoId, questionarios)
        navigate('/lista-avaliacao')
        mensagemSucesso("Sucesso!")
    }

    useEffect(() => {
        questionario();
    }, []);

    return (
        <Container>
            <Header>
                <Title>Editar de Avaliação</Title>
            </Header>

            <Form className="config-form-editar-avaliacao">

                <Divider className="box-date-editar-avaliacao">
                    <Row>
                        <Col>
                            <H5>Data início</H5>
                            <Form.Control
                                type="date"
                                value={questionarios?.dtInicio}
                                onChange={(e) => setQuestionarios({ ...questionarios, dtInicio: e.target.value })} />
                        </Col>
                        <Col>
                            <H5>Data término</H5>
                            <Form.Control
                                type="date"
                                value={questionarios?.dtTermino}
                                onChange={(e) => setQuestionarios({ ...questionarios, dtTermino: e.target.value })} />
                        </Col>
                    </Row>
                </Divider>

                <H5>nome do questionário</H5>
                <Form.Control
                    placeholder="Nome do questionário"
                    defaultValue={questionarios?.nomeQuestionario}
                    onChange={(e) => setQuestionarios({ ...questionarios, nomeQuestionario: e.target.value })} />

                <H5>Editar de questão</H5>
                <Form.Control
                    placeholder="Questão 1"
                    defaultValue={questionarios?.primeiraPergunta}
                    onChange={(e) => setQuestionarios({ ...questionarios, primeiraPergunta: e.target.value })} />

                <H5>Editar de questão</H5>
                <Form.Control
                    placeholder="Questão 2"
                    defaultValue={questionarios?.segundaPergunta}
                    onChange={(e) => setQuestionarios({ ...questionarios, segundaPergunta: e.target.value })} />

                <H5>Editar de questão</H5>
                <Form.Control
                    placeholder="Questão 3"
                    defaultValue={questionarios?.terceiraPergunta}
                    onChange={(e) => setQuestionarios({ ...questionarios, terceiraPergunta: e.target.value })} />

                <H5>Editar de questão</H5>
                <Form.Control
                    placeholder="Questão 4"
                    defaultValue={questionarios?.quartaPergunta}
                    onChange={(e) => setQuestionarios({ ...questionarios, quartaPergunta: e.target.value })} />

                <H5>Editar de questão</H5>
                <Form.Control
                    placeholder="Questão 5"
                    defaultValue={questionarios?.quintaPergunta}
                    onChange={(e) => setQuestionarios({ ...questionarios, quintaPergunta: e.target.value })} />

                <H5>Editar de questão</H5>
                <Form.Control
                    placeholder="Questão 6"
                    defaultValue={questionarios?.sextaPergunta}
                    onChange={(e) => setQuestionarios({ ...questionarios, sextaPergunta: e.target.value })} />

                <H5>Editar de questão</H5>
                <Form.Control
                    placeholder="Questão 7"
                    defaultValue={questionarios?.setimaPergunta}
                    onChange={(e) => setQuestionarios({ ...questionarios, setimaPergunta: e.target.value })} />

                <H5>Editar de questão</H5>
                <Form.Control
                    placeholder="Questão 8"
                    defaultValue={questionarios?.oitavaPergunta}
                    onChange={(e) => setQuestionarios({ ...questionarios, oitavaPergunta: e.target.value })} />

                <H5>Editar de questão</H5>
                <Form.Control
                    placeholder="Questão 9"
                    defaultValue={questionarios?.nonaPergunta}
                    onChange={(e) => setQuestionarios({ ...questionarios, nonaPergunta: e.target.value })} />

                <Link to="/lista-avaliacao">
                    <BtnPadrao id="voltar">Voltar</BtnPadrao>
                </Link>

                <BtnPadrao onClick={updateQuestionario} id="salvar" type="submit">Salvar</BtnPadrao>
            </Form >

        </Container >
    );
}

export default EditarAvaliacao;