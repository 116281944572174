
import { BtnPadrao } from 'helpers/btn_especial/StylesButtom';
import { DivCenter, Divider } from 'helpers/divider/StylesDivider';
import { Col, Container, Row } from 'react-bootstrap';
import { ArrowLeftCircleFill, ArrowRightCircleFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

function Hino() {
  return (
    <>
      <Container fluid className="alin-container">
        <Divider className="alin-text">
          <h2>Hino Happy</h2>
          <p>
            Somos a Happy, qual é nossa missão?<br />
            Nós vamos transformar, através da educação!<br />
            O nosso time tem amor e alegria,<br />
            Viraremos unicórnio, com muita energia!<br />
            O que nos move, é uma paixão<br />
            Então se ligue agora, e cante esse refrão:<br />
            Ter qualidade, e nunca desistir,<br />
            Buscar sempre o melhor, que possa existir<br />
            Pensamento ilimitado, para imaginar<br />
            E muito otimismo, para conquistar (2x)<br />
            Todo mundo aqui, já ficou sabendo<br />
            Então eu quero ver, todo chão tremendo<br />
            Vem gritar bem alto, até a voz sumir<br />
            O nome do maior, hub do Brasil!<br />
            HAPPY! HAPPY! HAPPY!<br />
          </p>
        </Divider>
      </Container>

      <DivCenter>
        <Row>
          <Col>
            <Link to="/jornada-manual">
              <ArrowLeftCircleFill id="arrow-left-circle-fill" className="btn-icon-arrow" />
            </Link>
          </Col>
          <Col>
            <Link to="/manual">
              <BtnPadrao id="voltar">Voltar</BtnPadrao>
            </Link>
          </Col>
          <Col>
            <Link to="/glossario">
              <ArrowRightCircleFill id="arrow-right-circle-fill" className="btn-icon-arrow" />
            </Link>
          </Col>
        </Row>
      </DivCenter>
    </>
  )
}

export default Hino;