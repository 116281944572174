import { Image } from "react-bootstrap";

import * as S from 'helpers/divider/StylesDivider'

import login_templo from "./img-png/templo.png";
import efeito_luz from "./img-png/efeito luz.png";
import porta from "./img-png/porta.png";
import porta_galeria from "./img-png/galeria interna.png";
import card_login from "./img-png/login.png";

export const ImagesWrapper = ({ isOpen = false }) => (
  <S.ImagesWrapper isOpen={isOpen}>
    <Image src={efeito_luz} alt="efeito-luz" className="efeito-luz" />
    <Image src={login_templo} alt="Login-Templo" className="login-templo" />
    <Image src={porta} alt="porta" className={`porta ${isOpen && "aberta"}`} />
    <Image src={porta_galeria} alt="porta-galeria" className="porta-galeria" />
  </S.ImagesWrapper>
);

export const CardLogin = () => (
  <Image src={card_login} alt="card-login" className="card-login" />
);
