import { Api } from '_config/api/apiService';

export const useArquivoService = () => {
	const resourceURL = 'arquivo';

    const enviarHolerite = (body: any, date: any) => {
        const formData = new FormData();
        formData.append('arquivo', body)
		return Api.post(`${resourceURL}/holerite?date=${date}`, formData);
    }

    const baixarHolerite = (id: number, mes: number, ano: number, adiantamento: boolean) => {
        return Api.get(`${resourceURL}?id=${id}&mes=${mes}&ano=${ano}&adiantamento=${adiantamento}`, {
			responseType: 'blob',
		});
    }

    return {
        enviarHolerite,
        baixarHolerite,
    }

}