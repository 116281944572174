import { mensagemAlert, mensagemErro, mensagemSucesso } from "Components/Toastr/toastr";
import { useAuth } from "context/AuthProvider/useAuth";
import { Button } from "helpers/ButtonCustom";
import { Divider } from "helpers/divider/StylesDivider";
import { Header } from "helpers/header";
import { Title } from "helpers/titles";
import { useState } from "react";
import { Form } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IFeedbackFullDTO } from "_config/models/ordem-happy-api-model";
import { useFeedbackService } from "_config/service/feedback.service";
import { Container } from "../../helpers/Container";
import * as C from "./styles";

function EditarFeedback() {
    const usuario = useAuth();
    const { colaboradorId } = useParams();
    const serviceFeedback = useFeedbackService();
    const navigate = useNavigate();
    const [formFeedback, setFormFeedback] = useState<IFeedbackFullDTO | any>({
        pessoa: {
            id: usuario.id
        },
        pessoaAvaliada: {
            id: colaboradorId
        },
    });
    const limiteCaractere = 1000;

    const enviarFeedback = async () => {
        try {
            await
                serviceFeedback.novoFeedback(formFeedback)
            navigate(`/colaboradores`)
            mensagemSucesso("Sucesso!")
        } catch (error) {
            mensagemErro("Falha ao enviar!");
        }
    };

    const submitValidationFields = () => {
        if (!formFeedback?.fortes) {
            return mensagemAlert("É necessário preencher o campo pontos fortes!");
        }
        if (!formFeedback?.desenvolver) {
            return mensagemAlert("É necessário preencher o campo pontos a desenvolver!");
        }
        if (!formFeedback?.ckGestorCiente) {
            return mensagemAlert("Marcar o check box confirmando que está ciente que não poderá alterar o Feedback!");
        }
        return enviarFeedback()
    };

    return (
        <Container>

            <Header>
                <Title>feedback</Title>
            </Header>

            <C.BoxForm>
                <Form>
                    <h3>Observações do Gestor</h3>
                    <Form.Label>Pontos Fortes</Form.Label>
                    <Form.Control
                        as="textarea"
                        spellCheck={true}
                        style={{ height: "150px" }}
                        maxLength={1000}
                        onChange={(e) => setFormFeedback({ ...formFeedback, fortes: e.target.value })} />
                    <C.BoxContadorCaractere>
                        <p>Limite de caracteres {limiteCaractere} {formFeedback?.fortes?.length ? `utilizado ${formFeedback?.fortes?.length}` : null}</p>
                    </C.BoxContadorCaractere>

                    <Form.Label>Pontos a desenvolver</Form.Label>
                    <Form.Control
                        as="textarea"
                        spellCheck={true}
                        style={{ height: "150px" }}
                        maxLength={1000}
                        onChange={(e) => setFormFeedback({ ...formFeedback, desenvolver: e.target.value })} />
                    <C.BoxContadorCaractere>
                        <p>Limite de caracteres {limiteCaractere} {formFeedback?.desenvolver?.length ? `utilizado ${formFeedback?.desenvolver?.length}` : null}</p>
                    </C.BoxContadorCaractere>

                    <Form.Check
                        /*type={type}
                        value={true}*/
                        id="check-ciente"
                        label="Estou ciente de que após salvar o feedback não poderá ser alterado"
                        onChange={(e) => setFormFeedback({ ...formFeedback, ckGestorCiente: e.target.checked })}
                        required />
                </Form>

                <Divider className="alin-button-feedback">
                    <Link to="/colaboradores"><Button>Voltar</Button></Link>
                    <Button onClick={submitValidationFields}>Salvar</Button>
                </Divider>
            </C.BoxForm>

        </Container>
    );
}

export default EditarFeedback;