import { useState } from "react";
import { Divider } from 'helpers/divider/StylesDivider';
import { Image } from "react-bootstrap";
import FundoEstrelado from "Components/FundoEstrelado";
import templo_home from '../../_assets/img/img-png/home-img/home templo.png';
import luz_biblioteca from '../../_assets/img/img-png/home-img/biblioteca.png';
import luz_guardiao from '../../_assets/img/img-png/home-img/hall guardião.png';
import luz_oficina from '../../_assets/img/img-png/home-img/oficina.png';
import luz_ponte_controle from '../../_assets/img/img-png/home-img/ponte de controle.png';
import luz_hangar from '../../_assets/img/img-png/home-img/hangar.png';
import luz_galeria from '../../_assets/img/img-png/home-img/galeria.png';
import luz_laboratorio from '../../_assets/img/img-png/home-img/laboratorio.png';
import luz_planetario from '../../_assets/img/img-png/home-img/planetario.png';
import luz_sala_conselho from '../../_assets/img/img-png/home-img/sala do conselho.png';
import logo_home from '../../_assets/img/img-png/home-img/home templo logo.png';
import './styles.css';
import CaixaTextMsg from "Components/CaixaTextMsg";
import { ContainerClean } from "helpers/Container";

function Home() {

    const [bibliotecaMSG, setbibliotecaMSG] = useState(false);
    const luzbibliotecaMSG = 'É onde se armazena e analisa todas as informações do universo, para que a Ordem atinja os resultados, é nela que todos os conhecimentos estão organizados, sendo possível visualizar os dados e práticas recomendados para as tomadas de decisões do Templo.';

    const [guardiaoMSG, setguardiaoMSG] = useState(false);
    const luzGuardiaoMSG = 'É onde as pedras do conhecimento estão exibidas, sendo sempre a luz que guia a Ordem na busca do seu proposito. A energia das pedras, emite uma luz branca tão forte que se torna um farol de conhecimento para todos.';

    const [galeriaMSG, setgaleriaMSG] = useState(false);
    const luzGaleriaMSG = 'É a porta de entrada do templo, onde todos os feitos e conquistas extraordinárias da Ordem são compartilhados. É um ambiente descontraído, onde seus integrantes se empenham em divulgar a visão ilimitada da Happy para todo o universo.';

    const [hangarMSG, sethangarMSG] = useState(false);
    const luzHangarMSG = 'É onde se abrigam as aeronaves que levam os integrantes do templo, aos Hubs Educacionais. É a equipe do hangar, a responsável por organizar, direcionar e controlar o gigantesco tráfego das linhas de comunicação da Ordem junto aos comandantes dos Hubs.';

    const [laboratorioMSG, setlaboratorioMSG] = useState(false);
    const luzLaboratorioMSG = 'Este é o lugar onde a energia da Floresta do Conhecimento é canalizada e manipulada. A equipe do laboratório realiza os experimentos que criam novas ramificações e trilhas da Árvore, para que esta cresça majestosa e frutífera.';

    const [oficinaMSG, setOficinaMSG] = useState(false);
    const luzOficinaMSG = 'É o lugar responsável por fazer com que cada peça e engrenagem, trabalhem com absoluta sinergia e eficiência. A oficina garante que essas engrenagens possam trocar informações e trabalhar sem atrito e atrasos.';

    const [planetarioMSG, setplanetarioMSG] = useState(false);
    const luzPlanetarioMSG = 'É o lugar onde a Ordem consegue visualizar e interagir com todo o universo em busca de novas terras e oportunidades. É no planetário que os novos comandantes são recebidos pelo Templo e iniciados nos domínios da Ordem.';

    const [PonteControleMSG, setPonteControleMSG] = useState(false);
    const luzPonteControleMSG = 'É o lugar responsável por garantir que os resultados estejam sendo atingidos, evitando assim que a Ordem fique a deriva e se mantenha no rumo correto, cada vez mais próxima do unicórnio.';

    const [SalaConselhoMSG, setSalaConselhoMSG] = useState(false);
    const luzSalaConselhoMSG = 'É onde se deu início às nossas diretrizes, arquitetadas por líderes que possuem um propósito em comum, trabalhando em sintonia com as visões do Guardião. O Conselho é responsável pela disseminação deste propósito para os integrantes do Templo.';


    return (
        <>

            {bibliotecaMSG && (
                <div className="msg-logo-hover">
                    <h3>biblioteca</h3>
                    {luzbibliotecaMSG}
                </div>
            )}

            {galeriaMSG && (
                <div className="msg-logo-hover">
                    <h3>galeria</h3>
                    {luzGaleriaMSG}
                </div>
            )}

            {hangarMSG && (
                <div className="msg-logo-hover">
                    <h3>hangar</h3>
                    {luzHangarMSG}
                </div>
            )}

            {laboratorioMSG && (
                <div className="msg-logo-hover">
                    <h3>laboratório</h3>
                    {luzLaboratorioMSG}
                </div>
            )}

            {oficinaMSG && (
                <div className="msg-logo-hover">
                    <h3>oficina</h3>
                    {luzOficinaMSG}
                </div>
            )}

            {planetarioMSG && (
                <div className="msg-logo-hover">
                    <h3>planetário</h3>
                    {luzPlanetarioMSG}
                </div>
            )}

            {PonteControleMSG && (
                <div className="msg-logo-hover">
                    <h3>ponte de controle</h3>
                    {luzPonteControleMSG}
                </div>
            )}

            {guardiaoMSG && (
                <div className="msg-logo-hover">
                    <h3>hall do guardião</h3>
                    {luzGuardiaoMSG}
                </div>
            )}

            {SalaConselhoMSG && (
                <div className="msg-logo-hover">
                    <h3>salas do conselho</h3>
                    {luzSalaConselhoMSG}
                </div>
            )}
            <ContainerClean className="config-container-clean-home">
                <Divider className="alin-container-home">
                    <FundoEstrelado />
                    <Image src={logo_home} alt="logo-home" className="logo-home" />
                    <CaixaTextMsg />
                    <Image src={templo_home} alt="templo-home" className="templo-home" />



                    <Divider
                        onMouseEnter={() => setbibliotecaMSG(true)}
                        onMouseLeave={() => setbibliotecaMSG(false)}>
                        <Image src={luz_biblioteca} alt="luz-biblioteca" id="luz-biblioteca" className="home-element" />
                    </Divider>

                    <Divider
                        onMouseEnter={() => setguardiaoMSG(true)}
                        onMouseLeave={() => setguardiaoMSG(false)}>
                        <Image src={luz_guardiao} alt="luz-guardião" id="luz-guardiao" className="home-element" />
                    </Divider>

                    <Divider
                        onMouseEnter={() => setgaleriaMSG(true)}
                        onMouseLeave={() => setgaleriaMSG(false)}>
                        <Image src={luz_galeria} alt="luz-galeria" id="luz-galeria" className="home-element" />
                    </Divider>

                    <Divider
                        onMouseEnter={() => sethangarMSG(true)}
                        onMouseLeave={() => sethangarMSG(false)}>
                        <Image src={luz_hangar} alt="luz-hangar" id="luz-hangar" className="home-element" />
                    </Divider>

                    <Divider
                        onMouseEnter={() => setlaboratorioMSG(true)}
                        onMouseLeave={() => setlaboratorioMSG(false)}>
                        <Image src={luz_laboratorio} alt="luz-laboratorio" id="luz-laboratorio" className="home-element" />
                    </Divider>

                    <Divider
                        onMouseEnter={() => setOficinaMSG(true)}
                        onMouseLeave={() => setOficinaMSG(false)}>
                        <Image src={luz_oficina} alt="luz-oficina" id="luz-oficina" className="home-element" />
                    </Divider>

                    <Divider
                        onMouseEnter={() => setplanetarioMSG(true)}
                        onMouseLeave={() => setplanetarioMSG(false)}>
                        <Image src={luz_planetario} alt="luz-planetario" id="luz-planetario" className="home-element" />
                    </Divider>

                    <Divider
                        onMouseEnter={() => setPonteControleMSG(true)}
                        onMouseLeave={() => setPonteControleMSG(false)}>
                        <Image src={luz_ponte_controle} alt="luz-ponte-controle" id="luz-ponte-controle" className="home-element" />
                    </Divider>

                    <Divider
                        onMouseEnter={() => setSalaConselhoMSG(true)}
                        onMouseLeave={() => setSalaConselhoMSG(false)}>
                        <Image src={luz_sala_conselho} alt="luz-sala-conselho" id="luz-sala-conselho" className="home-element" />
                    </Divider>

                </Divider>

            </ContainerClean>
        </>
    );
}

export default Home;