import { IColaboradorFullDTO } from '_config/models/ordem-happy-api-model';
import { Col, Image, Row } from 'react-bootstrap';
import { Divider } from 'helpers/divider/StylesDivider';
import './styles.css';

interface Props {
    colaborador: IColaboradorFullDTO;
}

function PainelGestao({ colaborador }: Props) {


    function painelGestaoInsignia(): string {
        let insigniaPainelGestao = "";

        if (colaborador?.patente === 'Young I') {
            insigniaPainelGestao = 'young1-painel-gestao';
        }
        if (colaborador?.patente === 'Young II') {
            insigniaPainelGestao = 'young2-painel-gestao';
        }
        if (colaborador?.patente === 'Padawan I') {
            insigniaPainelGestao = 'padawan1-painel-gestao';
        }
        if (colaborador?.patente === 'Padawan II') {
            insigniaPainelGestao = 'padawan2-painel-gestao';
        }
        if (colaborador?.patente === 'Padawan III') {
            insigniaPainelGestao = 'padawan3-painel-gestao';
        }
        if (colaborador?.patente === 'Sentinela I') {
            insigniaPainelGestao = 'sentinela1-painel-gestao';
        }
        if (colaborador?.patente === 'Sentinela II') {
            insigniaPainelGestao = 'sentinela2-painel-gestao';
        }
        if (colaborador?.patente === 'Sentinela III') {
            insigniaPainelGestao = 'sentinela3-painel-gestao';
        }
        if (colaborador?.patente === 'Cavaleiro I') {
            insigniaPainelGestao = 'cavaleiro1-painel-gestao';
        }
        if (colaborador?.patente === 'Cavaleiro II') {
            insigniaPainelGestao = 'cavaleiro2-painel-gestao';
        }
        if (colaborador?.patente === 'Cavaleiro III') {
            insigniaPainelGestao = 'cavaleiro3-painel-gestao';
        }
        if (colaborador?.patente === 'Diplomata') {
            insigniaPainelGestao = 'diplomata-painel-gestao';
        }
        if (colaborador?.patente === 'Mestre') {
            insigniaPainelGestao = 'mestre-painel-gestao';
        }
        if (colaborador?.patente === 'Consul') {
            insigniaPainelGestao = 'consul-painel-gestao';
        }
        if (colaborador?.patente === 'Gran Mestre') {
            insigniaPainelGestao = 'granMestre-painel-gestao';
        }
        if (colaborador?.patente === 'Guardião') {
            insigniaPainelGestao = 'guardiao-painel-gestao';
        }
        return insigniaPainelGestao;
    }

    return (
        <>
            <Divider className="config-card-painel-colaborador">
                <Row className="m-0">
                    <Col className="p-0">
                        <Image className="config-card-painel-colaborador-img" src="https://cdn.pixabay.com/photo/2020/07/01/12/58/icon-5359553_1280.png" alt="Matheus" />
                        <Divider className={painelGestaoInsignia()} />
                    </Col>
                    <Col className="p-0">
                        <Row className="m-0">
                            <h5>{colaborador?.nome}</h5>
                        </Row>
                        <Row className="m-0">
                            <h5>{colaborador?.patente}</h5>
                        </Row>

                        <Row className="m-0">
                            <h5>{colaborador?.funcao}</h5>
                        </Row>
                    </Col>
                </Row>
            </Divider>
        </>
    );
}

export default PainelGestao;