import { mensagemErro, mensagemSucesso } from "Components/Toastr/toastr";
import { CardIcon } from "helpers/card-icon";
import { Col, Row } from "helpers/ColRow";
import { Container } from "helpers/Container";
import { Divider } from "helpers/divider/StylesDivider";
import { Header } from "helpers/header";

import { H5, Title } from "helpers/titles";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { FaHouseUser } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { IColaboradorFullDTO, IFuncaoFullDTO } from "_config/models/ordem-happy-api-model";
import { useColaboradorService } from "_config/service/colaborador.service";
import { useFuncaoService } from "_config/service/funcao.service";

function EditarPerfilFuncao() {
    const { colaboradorId }: any = useParams();
    const [colaborador, setColaborador] = useState<IColaboradorFullDTO | any>();
    const [funcoes, setFuncoes] = useState<IFuncaoFullDTO[]>([]);
    const serviceColaboradores = useColaboradorService();
    const serviceFuncao = useFuncaoService();
    const navigate = useNavigate();

    const colaboradores = () => {
        serviceColaboradores.colaboradores(colaboradorId)
            .then(response => {
                setColaborador(response.data)
            });
    }

    const perfilFuncaoList = () => {
        serviceFuncao.perfilFuncaoList()
            .then(response => {
                setFuncoes(response.data)
            });
    }

    const updatePerfilFuncao = (e: any) => {
        serviceColaboradores.editarPerfilFuncao(colaboradorId, colaborador)
            .then(() => {
                navigate('/lista-colaborador-perfil')
                mensagemSucesso("Sucesso!")
            })
            .catch(error =>
                mensagemErro("Falha na conexão!"))
            .then(() => {
                navigate('/editar-perfil-funcao')
            });
    }

    useEffect(() => {
        colaboradores();
        perfilFuncaoList();
    }, []);

    return (
        <Container>
            <Header>
                <Title>Perfil Função</Title>
            </Header>

            <Row className="config-row-editar-perfil-funcao">
                {funcoes.map(funcao =>
                    <Col>
                        <Form>
                            <CardIcon onClick={() => updatePerfilFuncao(funcao?.codigo)}>
                                <p>{funcao.codigo}</p>
                                <FaHouseUser id="fa-house-user" />
                            </CardIcon>
                        </Form>
                        <Divider className="margin">
                            <H5>{funcao.descricao}</H5>
                        </Divider>
                    </Col>
                )}
            </Row>
        </Container >
    );
}

export default EditarPerfilFuncao;