import { createContext, useState } from "react";
import { IAuthProvider, IContext, IUser } from "./types";
import { getUserLocalStorage, LoginRequest, setUserLocalStorage } from "./util";

export const AuthContext = createContext<IContext | null>(null)

export const AuthProvider = ({ children }: IAuthProvider) => {
  const [user, setUser] = useState<IUser>(() => {
    const userStorage = localStorage.getItem('usuario');

    if (userStorage) {
      return JSON.parse(userStorage)
    }

    return {} as IUser
  })

  async function authenticate(login: string, senha: string, area: string, admin: string, nome: string, id: number, patente: string, superiorImediato: string) {
    const response = await LoginRequest(login, senha)

    if (response) {
      const { login, id, area, admin, nome, patente, superiorImediato } = response
      setUser({ login, id, area, admin, nome, patente, superiorImediato })
      setUserLocalStorage({ login, id, area, admin, nome, patente, superiorImediato })
      return response
    }

    return response;
  }

  function logout() {
    setUser({} as IUser);
    localStorage.removeItem(getUserLocalStorage())
  }

  return (
    <AuthContext.Provider value={{ ...user, authenticate, logout }}
    >
      {children}
    </AuthContext.Provider>
  )
}
