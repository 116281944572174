import { Divider } from "helpers/divider/StylesDivider";
import { BoxList } from "helpers/box-list";
import { Container } from "helpers/Container";
import RowAvaliacao from "Pages/PainelAdmin/Avaliacao/RowAvaliacao";
import { useEffect, useMemo, useState } from "react";
import { IQuestionarioListDTO } from "_config/models/ordem-happy-api-model";
import { Api } from "_config/api/apiService";
import { mensagemErro } from "Components/Toastr/toastr";
import { BtnActive, BtnInative } from "helpers/ButtonCustom";
import PaginationAvaliacao from "Components/Pagination/paginationAvaliacao";
import { Title } from "helpers/titles";
import { Row } from "helpers/ColRow";
import { Search } from "react-bootstrap-icons";
import { Form } from "react-bootstrap";
import "./styles.css";
import * as C from "./styles";
import { Header } from "helpers/header";

function ListAvaliacao() {
    const [validation, setvalidation] = useState<boolean>(false);
    const [ativo, setAtivo] = useState(true);
    const [pageNumber, setPageNumber] = useState(0);
    const date: any = new Date().toISOString().substring(0, 10);
    const [search, setSearch] = useState<string>('');
    const [page, setPage] = useState<IQuestionarioListDTO>({
        content: [],
        last: true,
        totalPages: 0,
        totalElements: 0,
        size: 11,
        number: 0,
        first: true,
        numberOfElements: 0,
        empty: true
    });

    useEffect(() => {
        Api.get(`/questionario?size=11&page=${pageNumber}&sort=id`)
            .then(response => {
                const data = response.data as IQuestionarioListDTO;
                setPage(data);
            })
            .catch(error =>
                mensagemErro("Falha na conexão!"))
    }, [pageNumber])

    const handlePageChange = (newPageNumber: number) => {
        setPageNumber(newPageNumber);
    }

    const isAtivo = () => {
        setAtivo(true);
    }

    const isInativo = () => {
        setAtivo(false);
    }

    const searchAvaliacao = useMemo(() => {
        const lowerSearch = search.toLowerCase();
        return page.content.filter(avaliacao => avaliacao?.nomeQuestionario?.toLowerCase().includes(lowerSearch))
    }, [page, search])

    function validacao() {
        if (!validation) {
            setvalidation(true);
        } else {
            setvalidation(false);
        }
    }

    const paginaFiltrada = page.content.filter(avaliacao => !avaliacao.dtTermino || avaliacao.dtTermino >= date);

    const paginaFiltradaInativo = page.content.filter(avaliacao => avaliacao.dtTermino <= date);

    return (
        <>
            <Container>
                <Header>
                    <Title>Avaliação ({ativo === true ? paginaFiltrada.length : paginaFiltradaInativo.length})</Title>
                </Header>

                <BoxList>

                    <Divider className="alin-aba-avaliacao">
                        <BtnActive onClick={isAtivo}>Ativo</BtnActive>
                        <BtnInative onClick={isInativo}>Inativo</BtnInative>
                    </Divider>

                    <Row className="config-search-lista-avaliacao-responsividade">
                        <Search id="search" className="search-lista-avaliacao" onClick={() => validacao()} />
                        {validation ?
                            <Form.Control
                                className="config-campo-avaliacao"
                                placeholder="Buscar"
                                onChange={(e) => setSearch(e.target.value)} />
                            : null}
                    </Row>

                    <C.Header>
                        <Divider><h2>nome</h2></Divider>
                        <Divider><h2>data início</h2></Divider>
                        <Divider><h2>data término</h2></Divider>

                        <Row className="config-search-lista-avaliacao">
                            <Search id="search" className="search-lista-avaliacao" onClick={() => validacao()} />
                            {validation ?
                                <Form.Control
                                    className="config-campo-avaliacao"
                                    placeholder="Buscar"
                                    onChange={(e) => setSearch(e.target.value)} />
                                : null}
                        </Row>
                    </C.Header>

                    {ativo === true ?
                        searchAvaliacao.map(avaliacao => (
                            <RowAvaliacao key={avaliacao.id} avaliacao={avaliacao} />
                        ))
                        :
                        searchAvaliacao.map(avaliacao => (
                            <RowAvaliacao key={avaliacao.id} avaliacao={avaliacao} />
                        ))
                    }
                </BoxList>

                <PaginationAvaliacao page={page} onChange={handlePageChange} />
            </Container>
        </>
    );
}

export default ListAvaliacao;