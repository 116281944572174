import "./style.css";
interface Props {
    setHideDisplay: React.Dispatch<React.SetStateAction<boolean>>,
    hideDisplay: boolean,
    img?: string;
    title?: string,
    descriptionOne?: string,
    descriptionTwo?: string,
    descriptionThree?: string,
    observacao?: string,
    listTitle?: string,
    listItem?: string[],
    iconDefault?: string;

    color?: string,
    colorIcon?: string,
    fontSizeTitle?: string | number,
    fontSize?: string | number,
    background?: string,
    backgroundIcon?: string,
    maxWidth?: string | number,
    width?: string | number,
}

const MsgInfoSistema: React.FC<Props> = (
    { setHideDisplay,
        hideDisplay,
        img,
        title,
        descriptionOne,
        descriptionTwo,
        descriptionThree,
        observacao,
        listTitle,
        listItem,
        iconDefault,

        color,
        colorIcon,
        fontSizeTitle,
        fontSize,
        background,
        backgroundIcon,
        width,
        maxWidth
    }: Props) => {

    return (
        <div style={{ width: width, maxWidth: maxWidth, background: background }} className="box-msg-servico"
            onMouseLeave={() => setHideDisplay(!hideDisplay)}>
            <h1 style={{ color: color, fontSize: fontSizeTitle }}>
                {img ?
                    <img className="config-icons-financeiro" src={img} />
                    : <span className="icon-msg-information" style={{ background: backgroundIcon, color: colorIcon }}>{iconDefault}</span>}
                {title}
            </h1>
            <br />
            {descriptionOne ?
                <>
                    <p style={{ color: color, fontSize: fontSize }}>{descriptionOne}</p>
                    <br />
                </>
                : null}
            {descriptionTwo ?
                <>
                    <p style={{ color: color, fontSize: fontSize }}>{descriptionTwo}</p>
                    <br />
                </>
                : null}
            {descriptionThree ?
                <>
                    <p style={{ color: color, fontSize: fontSize }}>{descriptionThree}</p>
                    <br />
                </>
                : null}
            {observacao ?
                <>
                    <p style={{ color: color, fontSize: fontSize }}><strong>Obs:</strong> {observacao}</p>
                    <br />
                </>
                : null}
            <h1 style={{ color: color, fontSize: fontSizeTitle }}>{listTitle}</h1>
            <br />
            {
                listItem?.map((item, index) =>
                    <ol key={index}>
                        <li style={{ color: color, fontSize: fontSize }}>{`${index + 1}. ${item}`}</li>
                    </ol>
                )
            }
        </div >
    );
}

export default MsgInfoSistema;