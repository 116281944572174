
import { Api } from '_config/api/apiService';
import { IAxiosResponseCustom } from '_config/models/http.model';
import { IHappyNewsFullDTO } from '_config/models/ordem-happy-api-model';

export const useHappyNews = () => {
	const resourceURL = 'jornal';
	
	const happyNews = (
        jornalId: Number,
	): Promise<IAxiosResponseCustom<IHappyNewsFullDTO>> =>
		Api.get(`${resourceURL}/${jornalId}`);

	const happyNewsList = (
	): Promise<IAxiosResponseCustom<IHappyNewsFullDTO[]>> =>
		Api.get(`${resourceURL}/listar-jornal`);

	return {
		happyNews,
        happyNewsList,
	};
};
