import { Row } from "helpers/ColRow";
import "./styles.css";

function ThreeSquareHorizontal() {
    return (
        <>
            <Row>
                <span className="element-horizontal-cor-green" />
                <span className="element-horizontal-cor-yellow" />
                <span className="element-horizontal-cor-red" />
            </Row>
        </>
    );
}

export default ThreeSquareHorizontal;