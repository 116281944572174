import styled from 'styled-components';

/********************************| Configuração do Header |********************************/
export const Title = styled.h1`
    font-size: 4em;
    font-weight: 900;
    color: var(--color-08);
`;

export const SubTitle = styled.h2`
    font-size: 2em;
    font-weight: 900;
    color: var(--color-08);
`;

/********************************| Configuração do Card |********************************/
export const Card = styled.div`
max-width: 700px;
max-height: 500px;
padding: 20px;
border-radius: 10px;
margin: 100px auto 100px auto;
box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
    rgba(0, 0, 0, 0.2) 0px -3px 0px inset;

    &:hover {
        transition: 0.3s ease-in-out;
        transform: translateY(-5px);
        box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
    }
`;

/********************************| Configuração do Box Icon |********************************/
export const BoxIcon = styled.div`
    position: absolute;
    font-size: 2em;
    height: 100px;
    width: 100px;
    background-color: aqua;
    border-radius: 100%;
    transform: translate(-85%, -85%);
`;

/********************************| Configuração do Card Title |********************************/
export const CardTitle = styled.h1`
    color: var(--color-08);
`;

/********************************| Configuração do Card Text |********************************/
export const CardText = styled.li`
    list-style: square;
    color: var(--color-08);
`;

/********************************| Configuração do Card Text |********************************/
export const SquareGreen = styled.div`
    height: 10px;
    width: 100px;
    background-color: var(--color-06);
`;

export const SquareYellow = styled.div`
    height: 10px;
    width: 100px;
    background-color: var(--color-05);
`;

export const SquareRed = styled.div`
    height: 10px;
    width: 100px;
    background-color: var(--color-04);
`;