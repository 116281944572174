import { Divider } from 'antd';
import { BtnPadrao } from 'helpers/btn_especial/StylesButtom';
import { DivCenter } from 'helpers/divider/StylesDivider';
import { Col, Container, Row } from 'react-bootstrap'
import { ArrowLeftCircleFill, ArrowRightCircleFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import './styles.css';

function Templo() {
  return (
    <>
        <Container fluid className="alin-container">
            <Divider className="alin-text">
                <h2>Código Happy</h2>
                    <p> O código Happy é um conjunto de regras que determinam o comportamento da<br/>
                        Ordem Happy. Ele ensina a não ceder a sentimentos ruins em relação aos outros, o<br/>
                        que ajuda a resistir ao medo e impedir que o lado sombrio da força tome conta.<br/>
                    </p>
                <h2>O Templo Happy</h2>
                    <p> O Templo Happy, é onde as diretrizes do pensamento ilimitado fazem a transição<br/>
                        do idealizado ao realizado. Atuando no Templo, temos:<br/>
                        Mandalorian Happy: Forasteiros que passam por várias terras, realizando<br/>
                        missões em busca de recompensas. Também conhecidos como<br/>
                        Freelancers;
                        <li>
                            <strong>Young Happy:</strong> Refere-se a um potencial da força, recém iniciado. São<br/>
                            atribuídos a um grupo de treinamento Happy, podendo se tornar um<br/>
                            Padawan;
                        </li>
                        <li>
                            <strong>Padawan Happy:</strong> São talentos descobertos pelo Templo Happy, que<br/>
                            abrem mão de conhecer outras terras, visando desenvolver seu verdadeiro<br/>
                            potencial;
                        </li>
                        <li>
                            <strong>Sentinela:</strong> Cumprindo o papel de conciliador e motivador, os Sentinelas<br/>
                            são a principal fonte de informações do Templo Happy. Detentores do<br/>
                            conhecimento operacional dos Hubs Educacionais, o Sentinela é o<br/>
                            responsável para levar a luz às áreas dominadas pela escuridão;<br/>
                        </li>
                        <li>
                            <strong>Cavaleiro Happy:</strong> Os Cavaleiros possuem a responsabilidade e o desafio<br/>
                            de manter o seu Squad focado em resultado. São desenvolvidos para<br/>
                            despertar o poder da liderança;<br/>
                        </li>
                        <li>
                            <strong>Diplomata:</strong> São criadores ou cocriadores das ferramentas que serão<br/>
                            utilizadas pelo Templo, para conquistar novas terras e habitantes;<br/>
                        </li>
                        <li>
                            <strong>Mestre Happy:</strong> Escolhidos pelos Gran Mestres, possuem o poder da<br/>
                            liderança e a força para potencializar os resultados. São detentores do<br/>
                            mapa executivo do Templo Happy;
                        </li>
                        <li>
                            <strong>Consul Happy:</strong> Usa a força do conhecimento, para encontrar soluções<br/>
                            pacíficas para cada problema. Se concentra na diplomacia e na busca por<br/>
                            sabedoria. Sempre explora métodos, para entender melhor a natureza da<br/>
                            força e sua conexão com ela;<br/>
                        </li>
                        <li>
                            <strong>Gran Mestre Happy:</strong> Sua função é comandar o Templo, distribuindo<br/>
                            missões e graduando seus mestres. Os Gran Mestres orientam todos os<br/>
                            membros do Templo Happy, se empenham em ser versáteis e capazes de<br/>
                            manejar diversas situações, além de compor o Conselho do Templo Happy;<br/>
                        </li>
                        <li>
                            <strong>Guardião Happy:</strong> O Guardião combina habilidades humanas e visionárias<br/>
                            da força, é o defensor que protege o Templo de todos os perigos, dando<br/>
                            direcionamentos aos Gran Mestres.<br/>
                        </li>
                    </p>
            </Divider>
        </Container>

        <DivCenter>
            <Row>
                <Col>
                    <Link to="/mundo">
                        <ArrowLeftCircleFill id="arrow-left-circle-fill" className="btn-icon-arrow"/>
                    </Link>
                </Col>
                <Col>
                    <Link to="/manual">
                        <BtnPadrao id="voltar">Voltar</BtnPadrao>
                    </Link>
                </Col>
                <Col>
                    <Link to="/cargo">
                        <ArrowRightCircleFill id="arrow-right-circle-fill" className="btn-icon-arrow"/>
                    </Link>
                </Col>
            </Row>
        </DivCenter>
    </>
  )
}

export default Templo;