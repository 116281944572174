import { Image } from "react-bootstrap";
import { DivCenter } from "helpers/divider/StylesDivider";
import guardiao_carousel from "../../../_assets/img/img-png/personagem-patente/slice1.png";
import guardiaoInsigniaCarousel from "../../../_assets/img/img-png/insignias-patente/insignias-azuis/Guardião.png";
import "./guardiao.styles.css";
import "../styles.css";

export const GuardiaoCarousel = () => (
    <>
        <DivCenter>
            <Image src={guardiao_carousel} alt="img-guardiao-carousel" id="manual-guardiao-carousel" className="manual-patente-carousel" />

            <DivCenter className="block-patente-guardiao-title">
                <h2 className="alin-title-guardia-carrossel">guardião</h2>
            </DivCenter>

            <DivCenter className="block-carrossel-full block-config-guardiao-carrossel">
                <Image src={guardiaoInsigniaCarousel} alt="img-guardiao-insignia-carousel" id="manual-guardiao-insignia-carousel" className="manual-patente-insignia-guardiao-carousel" />
            </DivCenter>

        </DivCenter>
    </>
);