import { ArrowLeft as Arrow } from 'react-bootstrap-icons';
import { IQuestionarioListDTO } from '_config/models/ordem-happy-api-model';
import './styles.css';

interface Props {
    page: IQuestionarioListDTO;
    onChange: Function;
}

function PaginationAvaliacao({ page, onChange }: Props) {
    return (
        <div className="dsmovie-pagination-container">
            <div className="dsmovie-pagination-box">
                <button onClick={() => onChange(page.number - 1)} className="dsmovie-pagination-button" disabled={page.first} >
                    <Arrow />
                </button>
                <p>{`${page.number + 1} de ${page.totalPages}`}</p>
                <button onClick={() => onChange(page.number + 1)} className="dsmovie-pagination-button" disabled={page.last} >
                    <Arrow className="dsmovie-flip-horizontal" />
                </button>
            </div>
        </div>
    );
}

export default PaginationAvaliacao;