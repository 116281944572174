import { mensagemAlert, mensagemErro, mensagemSucesso } from 'Components/Toastr/toastr';
import { Button } from 'helpers/ButtonCustom';
import { Container } from 'helpers/Container';
import { Divider } from 'helpers/divider/StylesDivider';
import { Header } from 'helpers/header';
import { Title } from 'helpers/titles';
import { useEffect, useState } from "react";
import { Form } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { IFeedbackFullDTO } from '_config/models/ordem-happy-api-model';
import { useFeedbackService } from '_config/service/feedback.service';
import * as C from "./styles";
import "./styles.css";
import { New } from 'Pages/Manual/json';
import { useAuth } from 'context/AuthProvider/useAuth';

function FeedbackColaborador() {
    const usuario = useAuth();
    const navigate = useNavigate();
    const { pessoaAvaliadaId } = useParams();
    const serviceFeedback = useFeedbackService();
    const [textAreaDiscordo, setTextAreaDiscordo] = useState<boolean>(false);
    const [formFeedback, setFormFeedback] = useState<IFeedbackFullDTO | any>();
    const [feedbacks, setFeedbacks] = useState<IFeedbackFullDTO[]>();
    const limiteCaractere = 1000;

    const feedbackList = () => {
        serviceFeedback.feedbackList(pessoaAvaliadaId)
            .then(response => {
                setFeedbacks(response.data)
            });
    }

    const updateFeedback = async (id: number) => {
        try {
            await
                serviceFeedback.editarFeedback(id, formFeedback)
            navigate(`/home`)
            mensagemSucesso("Sucesso!")
        } catch (error) {
            mensagemErro("Falha ao enviar!");
        }
    };

    const onSubmit = (e: any) => {
        if (!textAreaDiscordo) {
            setTextAreaDiscordo(true);
        } else {
            setTextAreaDiscordo(e.target.checked);
        }
        setFormFeedback({ ...formFeedback, ckDiscordo: e.target.checked })
    };

    useEffect(() => {
        feedbackList();
    }, [])

    const submitValidationFields = (id: any) => {
        if (formFeedback?.ckDiscordo) {
            if (!formFeedback?.discordo) {
                return mensagemAlert("É necessário preencher o campo discordo!");
            }
        }
        if (!formFeedback?.ckConcordo) {
            return mensagemAlert("É necessário marcar o check box concordo!");
        }
        if (!formFeedback?.ckCiente) {
            return mensagemAlert("É necessário marcar o check box ciente!");
        }
        return updateFeedback(id)
    };

    return (
        <Container>
            <Header>
                <Title>feedback ({feedbacks?.length})</Title>
            </Header>
            {feedbacks?.map(feedback =>
                <>
                    <C.BoxForm>
                        <Divider className="alin-new">
                            {!feedback.ckConcordo ?
                                <New />
                                : null}
                        </Divider>
                        <h3>Observações do Gestor</h3>
                        <Form className="config-forms-feedback">
                            <Form.Label>Pontos Fortes</Form.Label>
                            <Form.Control
                                as="textarea"
                                spellCheck={true}
                                disabled={true}
                                defaultValue={feedback?.fortes}
                                style={{ height: "150px" }}
                                maxLength={1000}
                                onChange={(e) => setFormFeedback({ ...formFeedback, fortes: e.target.value })} />
                            <C.BoxContadorCaractere>
                                <p>Limite de caracteres {limiteCaractere} {formFeedback?.fortes?.length ? `utilizado ${formFeedback?.fortes?.length}` : null}</p>
                            </C.BoxContadorCaractere>

                            <Form.Label>Pontos a desenvolver</Form.Label>
                            <Form.Control
                                as="textarea"
                                spellCheck={true}
                                disabled={true}
                                defaultValue={feedback?.desenvolver}
                                style={{ height: "150px" }}
                                maxLength={1000}
                                onChange={(e) => setFormFeedback({ ...formFeedback, desenvolver: e.target.value })} />
                            <C.BoxContadorCaractere>
                                <p>Limite de caracteres {limiteCaractere} {formFeedback?.desenvolver?.length ? `utilizado ${formFeedback?.desenvolver?.length}` : null}</p>
                            </C.BoxContadorCaractere>

                            <Form.Check
                                id="check-ciente"
                                label="Estou ciente de que após salvar o feedback não poderá ser alterado"
                                disabled={true}
                                defaultChecked={feedback?.ckGestorCiente}
                                onChange={(e) => setFormFeedback({ ...formFeedback, ciente: e.target.checked })}
                                required />

                            <h3>Observações do Avaliado:</h3>

                            {textAreaDiscordo || feedback.ckDiscordo === true ?
                                <>
                                    <Form.Label>Descreva o motivo</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        spellCheck={true}
                                        disabled={(!feedback?.ckCiente ? feedback.ckCiente : true) || (usuario.id !== feedback.pessoa.id ? feedback.ckCiente : true)}
                                        defaultValue={feedback?.discordo}
                                        style={{ height: "150px" }}
                                        maxLength={1000}
                                        onChange={(e) => setFormFeedback({ ...formFeedback, discordo: e.target.value })}
                                        required />
                                    <C.BoxContadorCaractere>
                                        <p>Limite de caracteres {limiteCaractere} {formFeedback?.discordo?.length ? `utilizado ${formFeedback?.discordo?.length}` : null}</p>
                                    </C.BoxContadorCaractere>
                                </>
                                : null}

                            <Form.Check
                                /*type={type}
                                value={true}*/
                                id="check-concordo"
                                label="Concordo com as observações realizadas pelo meu gestor"
                                disabled={(!feedback?.ckCiente ? feedback.ckCiente : true) || (usuario.id !== feedback.pessoa.id ? feedback.ckCiente : true)}
                                defaultChecked={feedback?.ckConcordo}
                                onChange={(e) => setFormFeedback({ ...formFeedback, ckConcordo: e.target.checked })}
                                required />

                            <Form.Check
                                /*type={type}
                                value={true}*/
                                id="check-discordo"
                                label="Discordo das observações realizadas pelo meu gestor"
                                disabled={(!feedback?.ckCiente ? feedback.ckCiente : true) || (usuario.id !== feedback.pessoa.id ? feedback.ckCiente : true)}
                                defaultChecked={feedback?.ckDiscordo}
                                onChange={(e) => onSubmit(e)}
                            />

                            <Form.Check
                                /*type={type}
                                value={true}*/
                                id="check-ciente"
                                label="Ciente dos pontos a desenvolver"
                                disabled={(!feedback?.ckCiente ? feedback.ckCiente : true) || (usuario.id !== feedback.pessoa.id ? feedback.ckCiente : true)}
                                defaultChecked={feedback?.ckCiente}
                                onChange={(e) => setFormFeedback({ ...formFeedback, ckCiente: e.target.checked })}
                                required />
                        </Form>
                        {!feedback.ckCiente ?
                            <Divider className="alin-button-feedback">
                                <Button onClick={() => submitValidationFields(feedback?.id)}>Salvar</Button>
                            </Divider>
                            : null}
                    </C.BoxForm>
                </>
            )}
        </Container>
    );
}

export default FeedbackColaborador;