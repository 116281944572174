import { useEffect, useState } from 'react';
import CardUsuarioLogado from 'Components/CardUsuarioLogado';
import { Image, NavDropdown } from 'react-bootstrap';
import { BarChartLineFill, BookHalf, BoxArrowRight, Diagram3Fill, HouseFill, PeopleFill, PersonLinesFill, StarFill } from 'react-bootstrap-icons';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import insignia from "../../_assets/img/img-png/insignia-ordem-happy/insignia-branco.svg";
import { useAuth } from 'context/AuthProvider/useAuth';
import { IColaboradorFullDTO, IQuestionarioFullDTO } from '_config/models/ordem-happy-api-model';
import { useColaboradorService } from '_config/service/colaborador.service';
import { PerfilFuncao, Patente } from '_config/constant';
import { Divider } from 'helpers/divider/StylesDivider';
import "./styles.css";
import { useQuestionarioService } from '_config/service/questionario.service';
import { formatDateUSA } from '_config/format';

function NavBar() {
    const usuario = useAuth();
    const [colaborador, setColaborador] = useState<IColaboradorFullDTO>();
    const [questionarios, setQuestionarios] = useState<IQuestionarioFullDTO[]>([]);
    const serviceColaboradores = useColaboradorService();
    const serviceQuestionario = useQuestionarioService();
    const data = new Date;

    const logout = () => {
        localStorage.removeItem('usuario');
    }

    const colaboradores = () => {
        serviceColaboradores.colaboradores(usuario?.id)
            .then(response => {
                setColaborador(response.data)
            });
    }

    const todosQuestionario = () => {
        serviceQuestionario.todosQuestionario()
            .then(response => {
                setQuestionarios(response.data)
            });
    }

    useEffect(() => {
        todosQuestionario();
        colaboradores();
    }, []);

    return (
        <Navbar collapseOnSelect expand="lg" sticky="top">
            <Container>

                <Navbar.Brand href="/home" className="alin-element-navbar-brand">
                    <Image id="insignia-ordem-happy" src={insignia} />
                    <h2>ordem happy</h2>
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="justify-content-end flex-grow-1">
                        <Divider className="config-navbar-responsividade">

                            <Nav.Link href="/home" className="alin-element-navbar">
                                <HouseFill id="house-fill" className="navbar-icon" />
                                <h5>home</h5>
                            </Nav.Link>


                            {questionarios.map(questionario =>

                                questionario?.ativo && questionario?.dtTermino?.toString() > formatDateUSA(data) ?
                                    usuario?.admin?.codigo !== PerfilFuncao.CODIGO_COLABORADOR_CNPJ ?
                                        <Nav.Link key={questionario.id} href={`/avaliacao/${questionario.id}`} className="alin-element-navbar">
                                            <StarFill id="star" className="navbar-icon" />
                                            <h5>avaliação</h5>
                                        </Nav.Link>
                                        : null
                                    : null

                            )}


                            {usuario?.admin?.codigo === PerfilFuncao.CODIGO_TECNOLOGIA ||
                                usuario?.admin?.codigo === PerfilFuncao.CODIGO_RECURSOS_HUMANOS ?
                                <Nav.Link href="/cadastro" className="alin-element-navbar">
                                    <PeopleFill id="people" className="navbar-icon" />
                                    <h5>cadastro</h5>
                                </Nav.Link>
                                : null}

                            {usuario?.admin?.codigo === PerfilFuncao.CODIGO_TECNOLOGIA ||
                                usuario?.admin?.codigo === PerfilFuncao.CODIGO_GESTOR ||
                                usuario?.admin?.codigo === PerfilFuncao.CODIGO_RECURSOS_HUMANOS ?
                                <Nav.Link href="/colaboradores" className="alin-element-navbar">
                                    <PersonLinesFill id="person" className="navbar-icon" />
                                    <h5>colaborador</h5>
                                </Nav.Link>
                                : null}

                            {usuario?.admin?.codigo === PerfilFuncao.CODIGO_RECURSOS_HUMANOS ||
                                usuario?.admin?.codigo === PerfilFuncao.CODIGO_TECNOLOGIA ||
                                (usuario?.admin?.codigo === PerfilFuncao.CODIGO_GESTOR && usuario.patente === Patente.GRAN_MESTRE) ?
                                <Nav.Link href="/ascensao" className="alin-element-navbar">
                                    <BarChartLineFill id="bar-chart-line-fill" className="navbar-icon" />
                                    <h5>painel gestão</h5>
                                </Nav.Link>
                                : null}

                            {/*<Nav.Link href="/happy-news" className="alin-element-navbar">
                            <Newspaper id="news-paper" className="navbar-icon" />
                            <h5>happy news</h5>
                        </Nav.Link>*/}

                            <Nav.Link href="/manual" className="alin-element-navbar">
                                <BookHalf id="book-half" className="navbar-icon" />
                                <h5>manual</h5>
                            </Nav.Link>

                            <Nav.Link href="/organograma" className="alin-element-navbar">
                                <Diagram3Fill id="diagram3fill" className="navbar-icon" />
                                <h5>organograma</h5>
                            </Nav.Link>

                            {/*<Nav.Link href="/intranet" className="alin-element-navbar">
                            <Globe id="globe" className="navbar-icon" />
                            <h5 className="text-font">intranet</h5>
                        </Nav.Link>

                        <Nav.Link href="/jornada" className="alin-element-navbar">
                            <PersonSquare id="person-square" className="navbar-icon" />
                            <h5>jornada</h5>
                        </Nav.Link>*/}

                        </Divider>

                        <NavDropdown title={<CardUsuarioLogado />} id="collasible-nav-dropdown">
                            <NavDropdown.Item href={`/editar/${colaborador?.id}`}>Meus dados</NavDropdown.Item>
                            <NavDropdown.Item href={"/exibir-holerite"}>Holerite</NavDropdown.Item>
                            <NavDropdown.Item href={`/feedback-colaborador/${colaborador?.id}`}>Feedback</NavDropdown.Item>
                            {/*<NavDropdown.Item><ModalTrocarSenha/></NavDropdown.Item>*/}
                            {/*<NavDropdown.Item href={`/colaborador/${colaborador?.id}`}>Alterar imagem</NavDropdown.Item>*/}
                            {usuario?.admin?.codigo === PerfilFuncao.CODIGO_ADMINISTRADOR ||
                                usuario?.admin?.codigo === PerfilFuncao.CODIGO_TECNOLOGIA ||
                                usuario?.admin?.codigo === PerfilFuncao.CODIGO_RECURSOS_HUMANOS ||
                                colaborador?.id === 61 ?
                                <NavDropdown.Item href={"/admin-painel"}>Painel Admin</NavDropdown.Item>
                                : null}
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="/" onClick={logout}>
                                <BoxArrowRight id="box-arrow-right" className="navbar-icon" />
                            </NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default NavBar;