import { DivCenter, Divider } from 'helpers/divider/StylesDivider';
import { Col, Container, Row } from 'react-bootstrap';
import CarrosselManual from '../component/carousel';
import { Link } from 'react-router-dom';
import { ArrowLeftCircleFill, ArrowRightCircleFill } from 'react-bootstrap-icons';
import { BtnPadrao } from 'helpers/btn_especial/StylesButtom';
import { Cavaleiro, CavaleiroInsignia, Padawan, PadawanInsignia, Sentinela, SentinelaInsignia, Young, YoungInsignia } from '../component/_exportimg';
import './styles.css';

function Cargo() {
  return (
    <>
      <Container fluid className="alin-container">
        <Divider className="alin-text-manual">
          <h2>Estrutura de cargos e salários</h2>
          <p> A estrutura de salários do Templo Happy, obedece o seguinte padrão:<br /></p>
          <DivCenter className="config-carrossel-cargo">
            <CarrosselManual />
          </DivCenter>
          <p> Durante o período de experiência, composto por 90 dias, a estrutura de salários<br />
            deverá respeitar o padrão abaixo. Após este período, caso o colaborador seja<br />
            efetivado, passará a ser remunerado de forma integral.<br />
          </p>
        </Divider>

        <DivCenter>
          <Row>
            <Col className="alin-img-patente">
              <Young />
              <Divider className="alin-tabela-salario-young">
                <DivCenter><h2 className="titles-insignia-cargo">young</h2><YoungInsignia /></DivCenter>
                <Divider className="block blk1 block-config1-young">-</Divider>
                <Divider className="block blk2 block-config2-young"><p className="alin-text-patente">R$ 1.500,00</p></Divider>
                <Divider className="block blk3 block-config3-young"><p className="alin-text-patente">R$ 1.000,00</p></Divider>
              </Divider>
            </Col>
            <Col className="alin-img-patente">
              <Padawan />
              <Divider className="alin-tabela-salario-padawan">
                <DivCenter><h2 className="titles-insignia-cargo">padawan</h2><PadawanInsignia /></DivCenter>
                <Divider className="block blk1 block-config1-padawan"><p className="alin-text-patente">R$ 3.000,00</p></Divider>
                <Divider className="block blk2 block-config2-padawan"><p className="alin-text-patente">R$ 2.800,00</p></Divider>
                <Divider className="block blk3 block-config3-padawan"><p className="alin-text-patente">R$ 2.000,00</p></Divider>
              </Divider>
            </Col>
            <Col className="alin-img-patente">
              <Sentinela />
              <Divider className="alin-tabela-salario-sentinela">
                <DivCenter><h2 className="titles-insignia-cargo">sentinela</h2><SentinelaInsignia /></DivCenter>
                <Divider className="block blk1 block-config1-sentinela"><p className="alin-text-patente">R$ 4.000,00</p></Divider>
                <Divider className="block blk2 block-config2-sentinela"><p className="alin-text-patente">R$ 3.000,00</p></Divider>
                <Divider className="block blk3 block-config3-sentinela"><p className="alin-text-patente">-</p></Divider>
              </Divider>
            </Col>
            <Col id="alin-cavaleiro" className="alin-img-patente">
              <Cavaleiro />
              <Divider className="alin-tabela-salario-cavaleiro">
                <DivCenter><h2 className="titles-insignia-cargo">cavaleiro</h2><CavaleiroInsignia /></DivCenter>
                <Divider className="block blk1 block-config1-cavaleiro"><p className="alin-text-patente">R$ 5.000,00</p></Divider>
                <Divider className="block blk2 block-config2-cavaleiro"><p className="alin-text-patente">R$ 4.500,00</p></Divider>
                <Divider className="block blk3 block-config3-cavaleiro"><p className="alin-text-patente">R$ 4.000,00</p></Divider>
              </Divider>
            </Col>
          </Row>
        </DivCenter>


        <DivCenter className="alin-title-manual">
          <h2>Período de experiência</h2>
        </DivCenter>
        <DivCenter className="alin-btn-manual-cargo">
          <Row>
            <Col>
              <Link to="/templo">
                <ArrowLeftCircleFill id="arrow-left-circle-fill" className="btn-icon-arrow" />
              </Link>
            </Col>
            <Col>
              <Link to="/manual">
                <BtnPadrao id="voltar">Voltar</BtnPadrao>
              </Link>
            </Col>
            <Col>
              <Link to="/jornada-manual">
                <ArrowRightCircleFill id="arrow-right-circle-fill" className="btn-icon-arrow" />
              </Link>
            </Col>
          </Row>
        </DivCenter>

      </Container>
    </>
  );
}

export default Cargo;