let hora: any = new Date().toLocaleTimeString('pt-BR', { hour: 'numeric', hour12: false }); // formato 24 horas (0-23)
let descricao = "";
let emoji = "";
let codigo = "";

if (hora >= 0 && hora <= 5) { // entre meia noite (0h) e 5 da madrugada
    codigo = `MAD`;
    descricao = 'Boa Madrugada!';
    emoji = `🌚`;
} else if (hora >= 6 && hora < 12) { // entre 6 e 11 da manhã
    codigo = `DIA`;
    descricao = 'Bom Dia!';
    emoji = `☀️`;
} else if (hora >= 12 && hora < 18) { // entre meio dia (12h) e 17 (5h) da tarde
    codigo = `TAR`;
    descricao = 'Boa Tarde!';
    emoji = `🌆`;
} else if (hora >= 18 && hora <= 23) { // entre 18 (6h) e 23 (11h) da noite
    codigo = `NOI`;
    descricao = 'Boa Noite!';
    emoji = `🌇`;
}

export const dataSaudacaoArray = {
    object: [
        { codigo, descricao, emoji }
    ]
}

