import { useAuth } from "context/AuthProvider/useAuth";
import { Container } from "helpers/Container";
import { FilePdfFill } from "react-bootstrap-icons";
import { useArquivoService } from "_config/service/arquivo.service";
import FileSaver from 'file-saver';
import { mensagemErro, mensagemSucesso } from "Components/Toastr/toastr";
import { Table } from "react-bootstrap";
import { Col, Row } from "helpers/ColRow";
import { Title } from "helpers/titles";
import { Header } from "helpers/header";
import "./styles.css";

function ExibirHolerite() {
    const usuario = useAuth();
    const service = useArquivoService();

    const baixarHolerite = (id: number, mes: number, ano: number, adiantamento: boolean) => {
        service.baixarHolerite(id, mes, ano, adiantamento)
            .then((response) => {
                const blob = new Blob([response.data], {
                    type: 'application/x-pdf',
                });
                FileSaver.saveAs(blob, `holerite.pdf`);
                mensagemSucesso("Download Concluído!");
            })
            .catch(() => mensagemErro("Holerite indisponível!"))
    }

    function getLast12Months() {
        let months = [];
        let currentDate = new Date();

        for (let i = 0; i < 6; i++) {
            let monthName = currentDate.toLocaleString('default', { month: 'long' });
            let monthNumber = currentDate.getMonth() + 1;
            let year = currentDate.getFullYear();
            months.unshift({ monthName, monthNumber, year });
            currentDate.setMonth(currentDate.getMonth() - 1);
        }

        return months;
    }

    const last12Months = getLast12Months();

    return (
        <Container>
            <Header>
                <Title>{usuario?.nome}</Title>
            </Header>

            <Table className="table-holerite" striped bordered hover>
                <thead>
                    <Row className="config-row-table-holerite">
                        <Col>Mês</Col>
                        <Col>Holerite Adiantamento</Col>
                        <Col>Baixar Holerite</Col>
                    </Row>
                </thead>
                <tbody>
                    {last12Months.reverse().map(({ monthName, monthNumber, year }) =>
                        <Row className="config-row-table-holerite">
                            <Col>{monthName} {year}</Col>
                            <Col><FilePdfFill className="pdf-icon" onClick={() => baixarHolerite(usuario.id, monthNumber, year, true)} /></Col>
                            <Col><FilePdfFill className="pdf-icon" onClick={() => baixarHolerite(usuario.id, monthNumber, year, false)} /></Col>
                        </Row>
                    )}
                </tbody>
            </Table>
        </Container>
    );
}

export default ExibirHolerite;