import styled from 'styled-components';

/********************************| BTN Padrão |********************************/
export const ButtonLogin = styled.button`
    font-size: 12px;
    font-weight: bold;
    color: white;
    background-color: #0c306c;
    height: 30px;
    width: 200px;
    border-radius: 10px;
    border: 2px solid #fff;

    /********************************| Responsividade dos Ícones |********************************/

/* Tablet Portrait size to standard 960 (devices and browsers) */
@media only screen and (min-width: 768px) and (max-width: 959px) {

    height: 6%;
}

/* Mobile Landscape Size to Tablet Portrait (devices and browsers) */
@media only screen and (min-width: 480px) and (max-width: 767px) {

    height: 5%;
    width: 60%;
    font-size: 8px; 
}

/* Mobile Landscape Size to Tablet Portrait (devices and browsers) */
@media only screen and (min-width: 280px) and (max-width: 479px) {

    height: 6%;
    width: 50%;
    font-size: 6px;
}

`;


