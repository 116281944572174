import { useState, useEffect } from "react";
import ThreeSquareHorizontal from "Components/ThreeSquareHorizontal";
import Lottie from "lottie-react";
import avatar_homem from "../../_assets/lottie/avatar/avatar-homem.json";
import avatar_mulher from "../../_assets/lottie/avatar/75399-woman-avatar.json";
import avatar_undefined from "../../_assets/lottie/avatar/avatar-undefined.json";
import { Col, Row } from "helpers/ColRow";
import { useAuth } from "context/AuthProvider/useAuth";
import { IColaboradorFullDTO } from "_config/models/ordem-happy-api-model";
import { useColaboradorService } from "_config/service/colaborador.service";
import "./styles.css";

function CardUsuarioLogado() {
    const usuario = useAuth();
    const [colaborador, setColaborador] = useState<IColaboradorFullDTO>();
    const serviceColaboradores = useColaboradorService();

    const colaboradores = () => {
        serviceColaboradores.colaboradores(usuario?.id)
            .then(response => {
                setColaborador(response.data)
            });
    }

    useEffect(() => {
        colaboradores();
    }, []);

    const AvatarHomem = () => {
        return <Lottie id="avatar-homem" loop animationData={avatar_homem} />
    }
    const AvatarMulher = () => {
        return <Lottie id="avatar-mulher" loop animationData={avatar_mulher} />
    };
    const AvatarUndefined = () => {
        return <Lottie id="avatar-undefined" loop animationData={avatar_undefined} />
    };

    function ValidarGenero() {
        if (colaborador?.genero === "Masculino") {
            return <AvatarHomem />
        }
        if (colaborador?.genero === "Feminino") {
            return <AvatarMulher />
        }
        if (!colaborador?.genero) {
            return <AvatarUndefined />
        }
    }

    return (
        <>
            <Row className="alin-avatar-navbar-responsividade">
                <Col>
                    {ValidarGenero()}
                </Col>
                <Col className="alin-element-card-usuario-logado">
                    <ThreeSquareHorizontal />
                    <h4>{colaborador?.login?.toUpperCase().split('.').join(' ')}</h4>
                    <h5>{colaborador?.patente}, {colaborador?.area}</h5>
                </Col>
            </Row>
        </>
    )
}

export default CardUsuarioLogado;