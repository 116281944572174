import { useEffect, useState } from 'react';
import { Api } from "_config/api/apiService";
import { Areas, Cargo, Colaborador, Patente } from '_config/services/types';
import { Link, useNavigate } from "react-router-dom";
import { mensagemAlert, mensagemErro, mensagemSucesso } from 'Components/Toastr/toastr';
import { Col, Container, Form, Row } from "react-bootstrap";
import { Divider } from 'helpers/divider/StylesDivider';
import './styles.css';
import { Asterisco } from 'Components/Asterisco';
import { cidades, cidadesEstado, estadoCivil, estados, generos, grauInstrucoes } from '_config/array';
import { formatCep, formatOnlyNumbers, formatPhone } from '_config/format';
import { Button } from 'helpers/ButtonCustom';

const defaultColaborador = {
    login: '',
    senha: '',
    area: '',
    admin: {
        id: ''
    },
    nome: '',
    emailCorporativo: '',
    emailPessoal: '',
    endereco: '',
    complemento: '',
    numeroEndereco: '',
    telefone: '',
    bairro: '',
    cidade: '',
    cep: '',
    estado: '',
    cidadeNascimento: '',
    nacionalidade: '',
    dataNascimento: '',
    estadoCivil: '',
    nomeConjuge: '',
    nomePai: '',
    nomeMae: '',
    ctp: '',
    ctpSerie: '',
    pisPasep: '',
    dataExpedicaoCtps: '',
    rg: '',
    orgaoEmissorRg: '',
    dataExpedicaoRg: '',
    cpf: '',
    dataExpedicaoCpf: '',
    tituloEleitoral: '',
    dataExpedicaoTitulo: '',
    secao: '',
    zona: '',
    reservista: '',
    carteiraConselhoRegional: '',
    numeroCarteiraConselhoRegional: '',
    banco: '',
    contaCorrente: '',
    agencia: '',
    grauInstrucao: '',
    estudaAtualmente: false,
    seriePeriodo: '',
    curso: '',
    cidadeCurso: '',
    estadoCurso: '',
    paisCurso: '',
    escolaFaculdade: '',
    outrosCursos: '',
    outrasInformacoes: '',
    funcao: '',
    salario: '',
    patente: '',
    dataAdmissao: '',
    dataDesligamento: '',
    status: '',
    matricula: '',
    genero: '',
    gestor: {
        id: ''
    },
}

const Cadastro = () => {
    const [colaborador, setColaborador] = useState(defaultColaborador);
    const navigate = useNavigate();
    const [senhaConfirma, setSenhaConfirma] = useState<String>();
    const [todosColaboradores, setTodosColaboradores] = useState<Colaborador[]>([]);
    const [patente, setPatente] = useState<Patente[]>([]);
    const [areas, setAreas] = useState<Areas[]>([]);
    const [cargos, setCargos] = useState<Cargo[]>([]);

    useEffect(() => {
        Api.get(`pessoa/todos`)
            .then(response => {
                setTodosColaboradores(response.data)
            })
    }, [])

    useEffect(() => {
        Api.get(`patente`)
            .then(response => {
                setPatente(response.data)
            })
    }, [])

    useEffect(() => {
        Api.get(`areas`)
            .then(response => {
                setAreas(response.data)
            })
    }, [])

    useEffect(() => {
        Api.get(`cargos`)
            .then(response => {
                setCargos(response.data)
            })
    }, [])

    function onHandleSubmit() {
        Api.post('pessoa/nova-pessoa/', colaborador)
            .then((response) => {
                navigate('/colaboradores')
                mensagemSucesso("Sucesso!")
            })
            .catch((error) =>
                mensagemErro("Falha na conexão!"))
    }

    function confirmaSenha() {
        if (colaborador.senha !== senhaConfirma) {
            mensagemAlert("As senhas informadas não batem.");
            return;
        }
        if (colaborador.login === '' || colaborador.login === null) {
            mensagemAlert("É necessário informar o login");
            return;
        } else {
            onHandleSubmit();
        }
    }

    return (
        <>
            <Container className="align-self-center d-flex justify-content-center">
                <Form.Group>
                    <hr className="hr" />
                    <Form.Text className="align-self-center d-flex justify-content-center my-4"><h2>Cadastro de Colaborador</h2></Form.Text>
                    <hr className="hr" />

                    <Form.Group className="mb-3 fundo-color">
                        <Row>
                            <Col className="d-flex-inline">
                                <Form.Text><span className="text-register">Setor:</span>
                                    <Form.Label className="my-2 mx-2 tm100">
                                        <Form.Select className="mb-2 form-array" onChange={(e) => setColaborador({ ...colaborador, area: e.target.value })}>
                                            <option value=''></option>
                                            {areas.map((area) => (
                                                <option key={area.id} value={area.descricao}>{area.descricao}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Label>
                                </Form.Text>
                            </Col>
                            <Col className="d-flex-inline">
                                <Form.Text><span className="text-register">Função:</span>
                                    <Form.Label className="my-2 mx-2 tm100">
                                        <Form.Select /*enable*/ className="mb-2 form-array" onChange={(e) => setColaborador({ ...colaborador, funcao: e.target.value })}>
                                            <option value=''></option>
                                            {cargos.map((cargo) => (
                                                <option key={cargo.id} value={cargo.descricao}>{cargo.descricao}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Label>
                                </Form.Text>
                            </Col>
                            <Col className="d-flex-inline">
                                <Form.Text><span className="text-register">Patente:</span>
                                    <Form.Label className="my-2 mx-2 tm100">
                                        <Form.Select /*enable*/
                                            className="mb-2 form-array"
                                            onChange={(e) => setColaborador({ ...colaborador, patente: e.target.value })}>
                                            <option value=''></option>
                                            {patente.map((patentes) => (
                                                <option key={patentes.id} value={patentes.descricao}>{patentes.descricao}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Label>
                                </Form.Text>
                            </Col>
                        </Row>

                        <Row>
                            <Col className="d-flex-inline">
                                <Form.Text><span className="text-register">Superior Imediato:<Divider className="config-asterisco"><Asterisco /></Divider></span>
                                    <Form.Label className="my-2 mx-2 tm100">
                                        <Form.Select /*enable*/ className="mb-2 form-array"
                                            onChange={(e) => setColaborador({ ...colaborador, gestor: { id: e.target.value } })}>
                                            <option value=''></option>
                                            {todosColaboradores.map((colaboradores) => (
                                                <option key={colaboradores.id} value={colaboradores.id}>{colaboradores?.nome}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Label>
                                </Form.Text>
                            </Col>
                        </Row>

                        <Row>
                            {/*<Col className="d-flex-inline">
                                <Form.Text><span className="text-register">Matrícula:</span>
                                    <Form.Label className="my-2 mx-2 tm100"><Form.Control
                                        type="text"
                                        onChange={(e) => setColaborador({ ...colaborador, matricula: e.target.value })}
                                    ></Form.Control></Form.Label>
                                </Form.Text>
                            </Col>*/}
                            <Col className="d-flex-inline">
                                <Form.Text><span className="text-register">Salário:</span>
                                    <Form.Label className="my-2 mx-2 tm100"><Form.Control
                                        type="text"
                                        onChange={(e) => setColaborador({ ...colaborador, salario: e.target.value })}
                                    ></Form.Control></Form.Label>
                                </Form.Text>
                            </Col>
                            <Col lg="3">
                                <Form.Text><span className="text-register">Data de Admissão:</span>
                                    <Form.Label className="my-2 mx-2 tm100"><Form.Control
                                        type="date"
                                        onChange={(e) => setColaborador({ ...colaborador, dataAdmissao: e.target.value })}
                                    ></Form.Control></Form.Label>
                                </Form.Text>
                            </Col>
                            <Col lg="3">
                                <Form.Text><span className="text-register">Data de Desligamento:</span>
                                    <Form.Label className="my-2 mx-2 tm100"><Form.Control
                                        type="date"
                                        onChange={(e) => setColaborador({ ...colaborador, dataDesligamento: e.target.value })}
                                    ></Form.Control></Form.Label>
                                </Form.Text>
                            </Col>
                        </Row>

                        <Row>
                            <Col className="d-flex-inline">
                                <Form.Text><span className="text-register">Nome:</span>
                                    <Form.Label className="my-2 mx-2 tm100"><Form.Control
                                        type="text"
                                        onChange={(e) => setColaborador({ ...colaborador, nome: e.target.value })}
                                    ></Form.Control></Form.Label>
                                </Form.Text>
                            </Col>
                        </Row>
                        {/*<Row>
                            <Col className="d-flex-inline">
                                <Form.Text><span className="text-register">Nome Pai:</span>
                                    <Form.Label className="my-2 mx-2 tm100"><Form.Control
                                        type="text"
                                        onChange={(e) => setColaborador({ ...colaborador, nomePai: e.target.value })}
                                    ></Form.Control></Form.Label>
                                </Form.Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="d-flex-inline">
                                <Form.Text><span className="text-register">Nome Mãe:</span>
                                    <Form.Label className="my-2 mx-2 tm100"><Form.Control
                                        type="text"
                                        onChange={(e) => setColaborador({ ...colaborador, nomeMae: e.target.value })}
                                    ></Form.Control></Form.Label>
                                </Form.Text>
                            </Col>
                        </Row>*/}

                        <Row>
                            <Col className="d-flex-inline">
                                <Form.Text><span className="text-register">Cidade/Estado de Nascimento:</span>
                                    <Form.Label className="my-2 mx-2 tm100">
                                        <Form.Select
                                            value={colaborador?.cidadeNascimento ? colaborador.cidadeNascimento : ''}
                                            className="mb-2 form-array" onChange={(e) => setColaborador({ ...colaborador, cidadeNascimento: e.target.value })}>
                                            {cidadesEstado.map((cidadeNascimento) => (
                                                <option value={cidadeNascimento}>{cidadeNascimento}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Label>
                                </Form.Text>
                            </Col>
                            <Col>
                                <Form.Text><span className="text-register">Nacionalidade:</span>
                                    <Form.Label className="my-2 mx-2 tm100"><Form.Control
                                        type="text"
                                        onChange={(e) => setColaborador({ ...colaborador, nacionalidade: e.target.value })}
                                    ></Form.Control></Form.Label>
                                </Form.Text>
                            </Col>
                            <Col className="d-flex-inline">
                                <Form.Text><span className="text-register">Genêro:</span>
                                    <Form.Label className="my-2 mx-2 tm100">
                                        <Form.Select
                                            value={colaborador?.genero ? colaborador.genero : ''}
                                            className="mb-2 form-array" onChange={(e) => setColaborador({ ...colaborador, genero: e.target.value })}>
                                            {generos.map((genero) => (
                                                <option value={genero}>{genero}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Label>
                                </Form.Text>
                            </Col>
                            <Col lg="3">
                                <Form.Text><span className="text-register">Data de Nascimento:</span>
                                    <Form.Label className="my-2 mx-2 tm100"><Form.Control
                                        type="date"
                                        onChange={(e) => setColaborador({ ...colaborador, dataNascimento: e.target.value })}
                                    ></Form.Control></Form.Label>
                                </Form.Text>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg="3">
                                <Form.Text><span className="text-register">Telefone:</span>
                                    <Form.Label className="my-2 mx-2 tm100"><Form.Control
                                        type="text" id="telefone"
                                        onChange={(e) => setColaborador({ ...colaborador, telefone: e.target.value })}
                                        maxLength={15}
                                        defaultValue={formatPhone(colaborador?.telefone)}
                                        value={formatPhone(colaborador?.telefone)}
                                    ></Form.Control></Form.Label>
                                </Form.Text>
                            </Col>
                            <Col className="d-flex-inline">
                                <Form.Text><span className="text-register">Cidade:</span>
                                    <Form.Label className="my-2 mx-2 tm100">
                                        <Form.Select
                                            value={colaborador?.cidade ? colaborador.cidade : ''}
                                            className="mb-2 form-array" onChange={(e) => setColaborador({ ...colaborador, cidade: e.target.value })}>
                                            {cidades.map((cidade) => (
                                                <option value={cidade}>{cidade}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Label>
                                </Form.Text>
                            </Col>
                            <Col lg="4">
                                <Form.Text><span className="text-register">Endereço:</span>
                                    <Form.Label className="my-2 mx-2 tm100"><Form.Control
                                        type="text"
                                        onChange={(e) => setColaborador({ ...colaborador, endereco: e.target.value })}
                                    ></Form.Control></Form.Label>
                                </Form.Text>
                            </Col>
                            <Col lg="1">
                                <Form.Text><span className="text-register">Nº:</span>
                                    <Form.Label className="my-2 mx-2 tm100"><Form.Control
                                        type="text" id="numeroEndereco"
                                        onChange={(e) => setColaborador({ ...colaborador, numeroEndereco: e.target.value })}
                                        maxLength={5}
                                        defaultValue={colaborador?.numeroEndereco}
                                        value={formatOnlyNumbers(parseInt(colaborador?.numeroEndereco))}
                                    ></Form.Control></Form.Label>
                                </Form.Text>
                            </Col>
                            <Col lg="4">
                                <Form.Text><span className="text-register">Bairro:</span>
                                    <Form.Label className="my-2 mx-2 tm100"><Form.Control
                                        type="text"
                                        onChange={(e) => setColaborador({ ...colaborador, bairro: e.target.value })}
                                    ></Form.Control></Form.Label>
                                </Form.Text>
                            </Col>
                            <Col lg="4">
                                <Form.Text><span className="text-register">Complemento:</span>
                                    <Form.Label className="my-2 mx-2 tm100"><Form.Control
                                        type="text" id="endereco"
                                        defaultValue={colaborador?.complemento}
                                        onChange={(e) => setColaborador({ ...colaborador, complemento: e.target.value })}
                                    ></Form.Control></Form.Label>
                                </Form.Text>
                            </Col>
                            <Col>
                                <Form.Text><span className="text-register">CEP:</span>
                                    <Form.Label className="my-2 mx-2 tm100"><Form.Control
                                        type="text" id="cep"
                                        onChange={(e) => setColaborador({ ...colaborador, cep: e.target.value })}
                                        maxLength={9}
                                        defaultValue={formatCep(parseInt(colaborador?.cep))}
                                        value={formatCep(parseInt(colaborador?.cep))}
                                    ></Form.Control></Form.Label>
                                </Form.Text>
                            </Col>
                            <Col className="d-flex-inline">
                                <Form.Text><span className="text-register">Estado:</span>
                                    <Form.Label className="my-2 mx-2 tm100">
                                        <Form.Select
                                            value={colaborador?.estado ? colaborador.estado : ''}
                                            className="mb-2 form-array" onChange={(e) => setColaborador({ ...colaborador, estado: e.target.value })}>
                                            {estados.map((estado) => (
                                                <option value={estado}>{estado}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Label>
                                </Form.Text>
                            </Col>
                        </Row>

                        <Row>
                            <Col className="d-flex-inline">
                                <Form.Text><span className="text-register">Estado Civil:</span>
                                    <Form.Label className="my-2 mx-2 tm100">
                                        <Form.Select
                                            value={colaborador?.estadoCivil ? colaborador.estadoCivil : ''}
                                            className="mb-2 form-array" onChange={(e) => setColaborador({ ...colaborador, estadoCivil: e.target.value })}>
                                            {estadoCivil.map((estadoCv) => (
                                                <option value={estadoCv}>{estadoCv}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Label>
                                </Form.Text>
                            </Col>
                            <Col className="d-flex-inline">
                                <Form.Text><span className="text-register">Nome Cônjuge:</span>
                                    <Form.Label className="my-2 mx-2 tm100"><Form.Control
                                        type="text"
                                        onChange={(e) => setColaborador({ ...colaborador, nomeConjuge: e.target.value })}
                                    ></Form.Control></Form.Label>
                                </Form.Text>
                            </Col>
                        </Row>
                    </Form.Group>

                    {/*<hr className="hr" />
                    <Form.Text className="align-self-center d-flex justify-content-center my-4"><h2>Dados Pessoais</h2></Form.Text>
                    <hr className="hr" />

                    <Form.Group className="mb-3 fundo-color">
                        <Row>
                            <Col lg="4">
                                <Form.Text><span className="text-register">Carteira de trabalho profissional:</span>
                                    <Form.Label className="my-2 mx-2 tm100"><Form.Control
                                        type="text"
                                        onChange={(e) => setColaborador({ ...colaborador, ctp: e.target.value })}
                                    ></Form.Control></Form.Label>
                                </Form.Text>
                            </Col>
                            <Col lg="2">
                                <Form.Text><span className="text-register">Série:</span>
                                    <Form.Label className="my-2 mx-2 tm100"><Form.Control
                                        type="text"
                                        onChange={(e) => setColaborador({ ...colaborador, ctpSerie: e.target.value })}
                                    ></Form.Control></Form.Label>
                                </Form.Text>
                            </Col>
                            <Col lg="4">
                                <Form.Text><span className="text-register">PIS/PASEP:</span>
                                    <Form.Label className="my-2 mx-2 tm100"><Form.Control
                                        type="text"
                                        onChange={(e) => setColaborador({ ...colaborador, pisPasep: e.target.value })}
                                    ></Form.Control></Form.Label>
                                </Form.Text>
                            </Col>
                            <Col lg="2">
                                <Form.Text><span className="text-register">Data Expedição CTP:</span>
                                    <Form.Label className="my-2 mx-2 tm100"><Form.Control
                                        type="date"
                                        onChange={(e) => setColaborador({ ...colaborador, dataExpedicaoCtps: e.target.value })}
                                    ></Form.Control></Form.Label>
                                </Form.Text>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg="3">
                                <Form.Text><span className="text-register">RG:</span>
                                    <Form.Label className="my-2 mx-2 tm100"><Form.Control
                                        type="text"
                                        onChange={(e) => setColaborador({ ...colaborador, rg: e.target.value })}
                                    ></Form.Control></Form.Label>
                                </Form.Text>
                            </Col>
                            <Col lg="2">
                                <Form.Text><span className="text-register">Órgão Emissor:</span>
                                    <Form.Label className="my-2 mx-2 tm100"><Form.Control
                                        type="text"
                                        onChange={(e) => setColaborador({ ...colaborador, orgaoEmissorRg: e.target.value })}
                                    ></Form.Control></Form.Label>
                                </Form.Text>
                            </Col>
                            <Col lg="2">
                                <Form.Text><span className="text-register">Data Expedição RG:</span>
                                    <Form.Label className="my-2 mx-2 tm100"><Form.Control
                                        type="date"
                                        onChange={(e) => setColaborador({ ...colaborador, dataExpedicaoRg: e.target.value })}
                                    ></Form.Control></Form.Label>
                                </Form.Text>
                            </Col>
                            <Col lg="3">
                                <Form.Text><span className="text-register">CPF:</span>
                                    <Form.Label className="my-2 mx-2 tm100"><Form.Control
                                        type="text"
                                        onChange={(e) => setColaborador({ ...colaborador, cpf: e.target.value })}
                                    ></Form.Control></Form.Label>
                                </Form.Text>
                            </Col>
                            <Col lg="2">
                                <Form.Text><span className="text-register">Data Expedição CPF:</span>
                                    <Form.Label className="my-2 mx-2 tm100"><Form.Control
                                        type="date"
                                        onChange={(e) => setColaborador({ ...colaborador, dataExpedicaoCpf: e.target.value })}
                                    ></Form.Control></Form.Label>
                                </Form.Text>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg="4">
                                <Form.Text><span className="text-register">Título Eleitoral:</span>
                                    <Form.Label className="my-2 mx-2 tm100"><Form.Control
                                        type="text"
                                        onChange={(e) => setColaborador({ ...colaborador, tituloEleitoral: e.target.value })}
                                    ></Form.Control></Form.Label>
                                </Form.Text>
                            </Col>
                            <Col lg="3">
                                <Form.Text><span className="text-register">Seção:</span>
                                    <Form.Label className="my-2 mx-2 tm100"><Form.Control
                                        type="text"
                                        onChange={(e) => setColaborador({ ...colaborador, secao: e.target.value })}
                                    ></Form.Control></Form.Label>
                                </Form.Text>
                            </Col>
                            <Col lg="2">
                                <Form.Text><span className="text-register">Zona:</span>
                                    <Form.Label className="my-2 mx-2 tm100"><Form.Control
                                        type="text"
                                        onChange={(e) => setColaborador({ ...colaborador, zona: e.target.value })}
                                    ></Form.Control></Form.Label>
                                </Form.Text>
                            </Col>
                            <Col lg="3">
                                <Form.Text><span className="text-register">Certificado de Reservista n.º:</span>
                                    <Form.Label className="my-2 mx-2 tm100"><Form.Control
                                        type="text"
                                        onChange={(e) => setColaborador({ ...colaborador, reservista: e.target.value })}
                                    ></Form.Control></Form.Label>
                                </Form.Text>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Text><span className="text-register">Carteira do Conselho Regional de:</span>
                                    <Form.Label className="my-2 mx-2 tm100"><Form.Control
                                        type="text"
                                        onChange={(e) => setColaborador({ ...colaborador, carteiraConselhoRegional: e.target.value })}
                                    ></Form.Control></Form.Label>
                                </Form.Text>
                            </Col>
                            <Col>
                                <Form.Text><span className="text-register">Nº:</span>
                                    <Form.Label className="my-2 mx-2 tm100"><Form.Control
                                        type="text"
                                        onChange={(e) => setColaborador({ ...colaborador, numeroCarteiraConselhoRegional: e.target.value })}
                                    ></Form.Control></Form.Label>
                                </Form.Text>
                            </Col>
                        </Row>

                        <Row>
                        <Col className="d-flex-inline">
                                <Form.Text><span className="text-register">Banco:</span>
                                    <Form.Label className="my-2 mx-2 tm100">
                                        <Form.Select
                                            value={colaborador?.banco ? colaborador.banco : ''}
                                            className="mb-2 form-array" onChange={(e) => setColaborador({ ...colaborador, banco: e.target.value })}>
                                            {bancos.map((banco) => (
                                                <option value={banco}>{banco}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Label>
                                </Form.Text>
                            </Col>
                            <Col>
                                <Form.Text><span className="text-register">Conta Corrente:</span>
                                    <Form.Label className="my-2 mx-2 tm100"><Form.Control
                                        type="text"
                                        onChange={(e) => setColaborador({ ...colaborador, contaCorrente: e.target.value })}
                                    ></Form.Control></Form.Label>
                                </Form.Text>
                            </Col>
                            <Col>
                                <Form.Text><span className="text-register">Agência:</span>
                                    <Form.Label className="my-2 mx-2 tm100"><Form.Control
                                        type="text"
                                        onChange={(e) => setColaborador({ ...colaborador, agencia: e.target.value })}
                                    ></Form.Control></Form.Label>
                                </Form.Text>
                            </Col>
                        </Row>
                    </Form.Group>*/}

                    <hr className="hr" />
                    <Form.Text className="align-self-center d-flex justify-content-center my-4"><h2>Dados Escolar</h2></Form.Text>
                    <hr className="hr" />

                    <Form.Group className="mb-3 fundo-color">
                        <Row>
                            <Col className="d-flex-inline">
                                <Form.Text><span className="text-register">Grau de Instrução:</span>
                                    <Form.Label className="my-2 mx-2 tm100">
                                        <Form.Select
                                            value={colaborador?.grauInstrucao ? colaborador.grauInstrucao : ''}
                                            className="mb-2 form-array" onChange={(e) => setColaborador({ ...colaborador, grauInstrucao: e.target.value })}>
                                            {grauInstrucoes.map((grauInstrucao) => (
                                                <option value={grauInstrucao}>{grauInstrucao}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Label>
                                </Form.Text>
                            </Col>

                            <Col lg="2">
                                <Form.Text><span className="text-register">Estuda atualmente:</span>
                                    <Form.Label className="my-2 mx-2 tm100 d-flex">
                                        {['radio'].map((type) => (
                                            <div key="sim" className="mb-3 mx-2 my-2">
                                                <span className="text-register">
                                                    <Form.Check
                                                        /*type={type}
                                                        value={true}*/
                                                        id={`inline-${type}-1`}
                                                        label="Sim"
                                                        onChange={(e) => setColaborador({ ...colaborador, estudaAtualmente: true })}
                                                    />
                                                </span>
                                            </div>
                                        ))}
                                        {['radio'].map((type) => (
                                            <div key="não" className="mb-3 mx-2 my-2">
                                                <span className="text-register">
                                                    <Form.Check
                                                        /*type={type}
                                                        value={false}*/
                                                        id={`inline-${type}-1`}
                                                        label="Não"
                                                        onChange={(e) => setColaborador({ ...colaborador, estudaAtualmente: false })}
                                                    />
                                                </span>
                                            </div>
                                        ))}
                                    </Form.Label>
                                </Form.Text>
                            </Col>

                            <Col lg="3">
                                <Form.Text><span className="text-register">Série/Período:</span>
                                    <Form.Label className="my-2 mx-2 tm100"><Form.Control
                                        type="text"
                                        onChange={(e) => setColaborador({ ...colaborador, seriePeriodo: e.target.value })}
                                    ></Form.Control></Form.Label>
                                </Form.Text>
                            </Col>
                            <Col lg="4">
                                <Form.Text><span className="text-register">Curso:</span>
                                    <Form.Label className="my-2 mx-2 tm100"><Form.Control
                                        type="text"
                                        onChange={(e) => setColaborador({ ...colaborador, curso: e.target.value })}
                                    ></Form.Control></Form.Label>
                                </Form.Text>
                            </Col>
                        </Row>

                        {/*<Row>
                            <Col className="d-flex-inline">
                                <Form.Text><span className="text-register">Cidade Curso:</span>
                                    <Form.Label className="my-2 mx-2 tm100">
                                        <Form.Select
                                            value={colaborador?.cidadeCurso ? colaborador.cidadeCurso : ''}
                                            className="mb-2 form-array" onChange={(e) => setColaborador({ ...colaborador, cidadeCurso: e.target.value })}>
                                            {cidades.map((cidadeCurso) => (
                                                <option value={cidadeCurso}>{cidadeCurso}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Label>
                                </Form.Text>
                            </Col>
                            <Col className="d-flex-inline">
                                <Form.Text><span className="text-register">Estado Curso:</span>
                                    <Form.Label className="my-2 mx-2 tm100">
                                        <Form.Select
                                            value={colaborador?.estadoCurso ? colaborador.estadoCurso : ''}
                                            className="mb-2 form-array" onChange={(e) => setColaborador({ ...colaborador, estadoCurso: e.target.value })}>
                                            {estados.map((estadoCurso) => (
                                                <option value={estadoCurso}>{estadoCurso}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Label>
                                </Form.Text>
                            </Col>
                            <Col className="d-flex-inline">
                                <Form.Text><span className="text-register">País Curso:</span>
                                    <Form.Label className="my-2 mx-2 tm100">
                                        <Form.Select
                                            value={colaborador?.paisCurso ? colaborador.paisCurso : ''}
                                            className="mb-2 form-array" onChange={(e) => setColaborador({ ...colaborador, paisCurso: e.target.value })}>
                                            {paises.map((paisCurso) => (
                                                <option value={paisCurso}>{paisCurso}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Label>
                                </Form.Text>
                            </Col>
                        </Row>*/}

                        <Row>
                            <Col className="d-flex-inline">
                                <Form.Text><span className="text-register">Escola/Faculdade:</span>
                                    <Form.Label className="my-2 mx-2 tm100"><Form.Control
                                        type="text"
                                        onChange={(e) => setColaborador({ ...colaborador, escolaFaculdade: e.target.value })}
                                    ></Form.Control></Form.Label>
                                </Form.Text>
                            </Col>
                        </Row>

                        <Row>
                            <Col className="d-flex-inline">
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Text>
                                        <Form.Label><span className="text-register">Outros Cursos/Graduações/ Pós-Graduações/ Certificações:</span></Form.Label>
                                        <Form.Control as="textarea" rows={3} onChange={(e) => setColaborador({ ...colaborador, outrosCursos: e.target.value })} />
                                    </Form.Text>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col className="d-flex-inline">
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Text>
                                        <Form.Label><span className="text-register">Outras Informações:</span></Form.Label>
                                        <Form.Control as="textarea" rows={3} onChange={(e) => setColaborador({ ...colaborador, outrasInformacoes: e.target.value })} />
                                    </Form.Text>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col className="d-flex-inline">
                                <Form.Text><span className="text-register">E-mail Corporativo:</span>
                                    <Form.Label className="my-2 mx-2 tm100"><Form.Control
                                        type="text"
                                        defaultValue={colaborador?.emailCorporativo}
                                        onChange={(e) => setColaborador({ ...colaborador, emailCorporativo: e.target.value })}
                                    ></Form.Control></Form.Label>
                                </Form.Text>
                            </Col>
                            <Col className="d-flex-inline">
                                <Form.Text><span className="text-register">E-mail pessoal:</span>
                                    <Form.Label className="my-2 mx-2 tm100"><Form.Control
                                        type="text"
                                        defaultValue={colaborador?.emailPessoal}
                                        onChange={(e) => setColaborador({ ...colaborador, emailPessoal: e.target.value })}
                                    ></Form.Control></Form.Label>
                                </Form.Text>
                            </Col>
                        </Row>

                        <Row>
                            <Col className="d-flex-inline">
                                <Form.Text><span className="text-register">Login:</span>
                                    <Form.Label className="my-2 mx-2 tm100"><Form.Control
                                        type="text"
                                        onChange={(e) => setColaborador({ ...colaborador, login: e.target.value })}
                                    ></Form.Control></Form.Label>
                                </Form.Text>
                            </Col>
                        </Row>

                        <Row>
                            <Col className="d-flex-inline">
                                <Form.Text><span className="text-register">Senha:</span>
                                    <Form.Label className="my-2 mx-2 tm100"><Form.Control
                                        type="password"
                                        onChange={(e) => setColaborador({ ...colaborador, senha: e.target.value })}
                                    ></Form.Control></Form.Label>
                                </Form.Text>
                            </Col>
                        </Row>

                        <Row>
                            <Col className="d-flex-inline">
                                <Form.Text><span className="text-register">Confirmar senha:</span>
                                    <Form.Label className="my-2 mx-2 tm100"><Form.Control
                                        type="password"
                                        onChange={(e) => setSenhaConfirma(e.target.value)}
                                    ></Form.Control></Form.Label>
                                </Form.Text>
                            </Col>
                        </Row>
                    </Form.Group>

                    <Divider className="alin-button">
                        <Link to="/home">
                            <Button>Voltar</Button>
                        </Link>
                        <Button onClick={confirmaSenha} type="submit">Salvar</Button>
                    </Divider>
                </Form.Group>
            </Container>
        </>
    );
}

export default Cadastro;