import { PencilSquare } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { IColaboradorFullDTO, IFuncaoFullDTO } from '_config/models/ordem-happy-api-model';
import { Row } from 'helpers/ColRow';
import { Divider } from 'helpers/divider/StylesDivider';
import { useFuncaoService } from '_config/service/funcao.service';
import { useEffect, useState } from 'react';
import * as C from "./styles";

interface Props {
    colaborador: IColaboradorFullDTO;
}

function RowPerfil({ colaborador }: Props) {
    const [funcoes, setFuncoes] = useState<IFuncaoFullDTO[]>();
    const serviceFuncao = useFuncaoService();

    const perfilFuncaoListColaborador = () => {
        serviceFuncao.perfilFuncaoListColaborador(colaborador.id)
            .then(response => {
                setFuncoes(response.data)
            });
    }

    useEffect(() => {
        perfilFuncaoListColaborador();
    }, []);

    return (
        <C.Lista>
            {funcoes?.map(funcao =>
                <>
                    <Divider>
                        <p><strong>{colaborador?.nome}</strong></p>
                    </Divider>
                    <Divider>
                        <p>{colaborador?.area}</p>
                    </Divider>
                    <Divider>
                        <p>{funcao.descricao}</p>
                    </Divider>

                    <Row>
                        <Link to={`/editar-perfil-funcao/${colaborador.id}`}>
                            <PencilSquare id="pencil-square" className="icone-list" />
                        </Link>
                    </Row>
                </>
            )}
        </C.Lista>
    );
}

export default RowPerfil;