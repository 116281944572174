import ThreeSquareVertical from "Components/ThreeSquareVertical";
import { Col, Row } from "helpers/ColRow";
import { Container } from "helpers/Container";
import { Divider } from "helpers/divider/StylesDivider";
import { Header } from "helpers/header";
import { useEffect, useState } from "react";
import { GiftFill } from "react-bootstrap-icons";
import { IHappyNewsFullDTO } from "_config/models/ordem-happy-api-model";
import { useHappyNews } from "_config/service/happyNews";
import * as C from "./styles";
import "./styles.css";

function HappyNews() {
    const [happyNews, setHappyNews] = useState<IHappyNewsFullDTO[]>();
    const serviceHappyNews = useHappyNews();

    const happyNewsList = () => {
        serviceHappyNews.happyNewsList()
            .then(response => {
                setHappyNews(response.data)
            });
    }

    useEffect(() => {
        happyNewsList();
    }, []);

    return (
        <Container>
            <Header>
                <Divider className="alin-three-square-vertical-happy-news">
                    <ThreeSquareVertical />
                </Divider>
                <Col>
                    <C.Title>happy</C.Title>
                    <C.SubTitle>news</C.SubTitle>
                </Col>
            </Header>

            {happyNews?.map(jornal =>
                <C.Card key={jornal.id}>
                    <C.BoxIcon><GiftFill /></C.BoxIcon>
                    <C.CardTitle>{jornal.titulo}</C.CardTitle>
                    <Row>
                        <C.SquareGreen />
                        <C.SquareYellow />
                        <C.SquareRed />
                    </Row>
                    <C.CardText>{jornal.texto}</C.CardText>
                </C.Card>
            )}
        </Container>
    );
}

export default HappyNews;