import { BtnPadrao } from 'helpers/btn_especial/StylesButtom';
import { DivCenter, Divider } from 'helpers/divider/StylesDivider';
import { Row, Col, Container } from 'react-bootstrap';
import { ArrowLeftCircleFill, ArrowRightCircleFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import './styles.css';

function JornadaManual() {
    return (
        <>
            <Container fluid className="alin-container">
                <Divider className="alin-text">
                    <h2>Jornada do Crescimento Happy</h2>
                    <p>
                        A Jornada do Crescimento foi criada, pensando no desenvolvimento do Squad da<br />
                        Happy. O projeto contempla três fases, que o colaborador necessita para alcançar<br />
                        benefícios, em prol de seu crescimento na empresa.<br />
                    </p>
                    <DivCenter className="ajust-div">
                        <li>
                            <strong>Fase 1 – Feedbacks:</strong><br />
                            Nesta etapa, os Squads do Templo passará por avaliações (Feedbacks), que<br />
                            ocorrerão da seguinte forma:<br />
                            <strong>✓ 1º - Avaliação 360º:</strong> O primeiro feedback, ocorrerá ao final do primeiro<br />
                            trimestre de cada ano, será uma avaliação 360º, onde um colaborador irá<br />
                            avaliar todo o Squad da sua área;<br />
                            <strong>✓ 2º - Avaliação 180º:</strong> Será aplicado pelo Desenvolvimento Humano com o<br />
                            Mestre/Cavaleiro e com o colaborador, ao final do segundo trimestre de<br />
                            cada ano;<br />
                            <strong>✓ 3º - Avaliação 180º:</strong> Será aplicado pelo Desenvolvimento Humano com o<br />
                            Mestre/Cavaleiro e com o colaborador, ao final do terceiro trimestre de cada<br />
                            ano.<br />
                            Os feedbacks contemplarão 5 pilares, em relação ao desenvolvimento do<br />
                            colaborador, entre os quais temos:<br />
                            <DivCenter>
                                <ol>
                                    <li className="alin-list">Resultados;</li>
                                    <li className="alin-list">Comprometimento;</li>
                                    <li className="alin-list">Proatividade;</li>
                                    <li className="alin-list">Comportamento;</li>
                                    <li className="alin-list">Aperfeiçoamento na área.</li>
                                </ol>
                            </DivCenter>
                            Para que o feedback seja considerado positivo, o colaborador deverá atingir<br />
                            nota igual ou maior que 7,0 em cada um dos três feedbacks aplicados.<br />
                        </li>
                    </DivCenter>
                    <DivCenter className="ajust-div">
                        <li>
                            <strong>Fase 2 – Avaliação do Mestre:</strong><br />
                            Para esta etapa, o Mestre/Cavaleiro deverá preencher um documento com<br />
                            informações referentes a evolução do colaborador e encaminhar ao Desenvolvimento<br />
                            Humano, para que este solicite aprovação ao Gran Mestre. Caso o colaborador tenha<br />
                            recebido 3 feedbacks positivos e apresentado melhoria em relação aos anteriores,<br />
                            caberá ao Mestre definir qual benefício o colaborador irá receber, nos quais temos:<br />
                            <strong>✓ Desenvolvimento:</strong> Este benefício tem o intuito de desenvolver o<br />
                            colaborador em sua área de atuação, deste modo, poderá ser concedida<br />
                            uma bolsa de estudos, de acordo com o nível da patente.<br />

                            <Divider className="ajust-div">
                                <Row>
                                    <Col className="p-0">
                                        <Row id="title-tabela" className="tabela-bolsa">patente</Row>
                                        <Row className="tabela-bolsa"><strong>Young 1</strong></Row>
                                        <Row className="tabela-bolsa"><strong>Young 2</strong></Row>
                                        <Row className="tabela-bolsa"><strong>Padawan 1 / Sentinela 1</strong></Row>
                                        <Row className="tabela-bolsa"><strong>Padawan 2</strong></Row>
                                        <Row className="tabela-bolsa"><strong>Padawan 3 / Sentinela 2</strong></Row>
                                        <Row className="tabela-bolsa"><strong>Sentinela 3 / Cavaleiro 1</strong></Row>
                                        <Row className="tabela-bolsa"><strong>Cavaleiro 2</strong></Row>
                                        <Row className="tabela-bolsa"><strong>Cavaleiro 3</strong></Row>
                                    </Col>
                                    <Col className="p-0">
                                        <Row id="title-tabela" className="tabela-bolsa">valor da Bolsa</Row>
                                        <Row className="tabela-bolsa"><strong>R$ 300,00</strong></Row>
                                        <Row className="tabela-bolsa"><strong>R$ 540,00</strong></Row>
                                        <Row className="tabela-bolsa"><strong>R$ 840,00</strong></Row>
                                        <Row className="tabela-bolsa"><strong>R$ 960,00</strong></Row>
                                        <Row className="tabela-bolsa"><strong>R$ 1.140,00</strong></Row>
                                        <Row className="tabela-bolsa"><strong>R$ 1.350,00</strong></Row>
                                        <Row className="tabela-bolsa"><strong>R$ 1.500,00</strong></Row>
                                        <Row className="tabela-bolsa"><strong>R$ 1.650,00</strong></Row>
                                    </Col>
                                </Row>
                            </Divider>
                            <p>
                                <strong>✓ Happy Gold:</strong> O Happy Gold é uma forma de reconhecer o colaborador pelo<br />
                                seu desempenho, esforço e dedicação durante o ano. Este benefício dará<br />
                                ao colaborador, o direito de receber um Recompensa extra.<br />
                                <strong>✓ Mentoria com Gran Mestres:</strong> A mentoria de carreira tem o objetivo de<br />
                                acompanhar e desenvolver o colaborador no âmbito profissional. Aquele<br />
                                que receber este benefício, passará por um processo de desenvolvimento,<br />
                                durante 6 meses, com um dos Gran Mestres do Templo. Durante este<br />
                                período, o colaborador deverá ouvir e entender os conselhos, além de<br />
                                promover conhecimento para estimular o seu crescimento no Templo<br />
                                Happy.<br />
                                <strong>✓ Ascensão:</strong> Este benefício dará ao colaborador o direito de chegar a fase<br />
                                3, como reconhecimento à sua contribuição aos resultados da empresa. De<br />
                                acordo com seu desempenho em concluir a última fase, ele poderá receber<br />
                                uma nova patente e também um aumento na sua Recompensa. A<br />
                                quantidade de colaboradores a receberem uma nova patente ou promoção,<br />
                                será baseada nos resultados da empresa durante o ano. Para receber esse<br />
                                benefício, o colaborador deverá ter pelo menos 1 ano de jornada no<br />
                                Templo.<br />
                            </p>
                        </li>
                    </DivCenter>
                    <DivCenter className="ajust-div">
                        <li>
                            <strong>Fase 3 – Missão:</strong>
                            Nesta etapa, o Desenvolvimento Humano, juntamente com o Gran Mestre e o<br />
                            Mestre, irão definir uma missão para que o Colaborador possa completar sua jornada<br />
                            de crescimento. As missões podem variar, de acordo com cada patente a ser<br />
                            conquistada pelo colaborador, entre as opções temos:<br />
                            ✓ Promover uma ação social envolvendo o Templo Happy;<br />
                            ✓ Apresentar uma solução por meio de protótipo de jogo;<br />
                            ✓ Apresentar ao menos uma solução à empresa ou área;<br />
                            ✓ Apresentar um case de sucesso com Hubs Educacionais, que passaram do<br />
                            lado sombrio da força, para o lado da luz.<br />
                            Definindo qual será a missão a ser cumprida o Mestre / Cavaleiro deverá passar<br />
                            ao colaborador todas as informações necessárias para sua realização.<br />
                            Ao concluir a missão que lhe foi atribuída, o colaborador deverá apresentar um<br />
                            pitch de até 5 minutos para uma banca, composta por Gran Mestres e Consul, que<br />
                            irão avaliar a apresentação e decidir se o colaborador será aprovado ou não. Caso<br />
                            seja aprovado, então o colaborador estará pronto para assumir sua nova patente.<br />
                            Além das 3 fases da Jornada, para um colaborador ser promovido a Cavaleiro,<br />
                            deverá possuir os seguintes requisitos, de acordo com cada nível:<br />
                            <li>
                                <strong>Cavaleiro Happy 1</strong><br />
                                ✓ Perfil de liderança;<br />
                                ✓ Graduação completa;<br />
                                ✓ Ter resultados positivos nas 3 fases;<br />
                            </li>
                            <li>
                                <strong>Cavaleiro Happy 2</strong><br />
                                ✓ Pelo menos 12 meses na função;<br />
                                ✓ Graduação completa;<br />
                                ✓ Experiência com liderança de equipes na Happy;<br />
                                ✓ Feedback positivo como líder;<br />
                            </li>
                            <li>
                                <strong>Cavaleiro Happy 3</strong><br />
                                ✓ Mais de 2 anos na função;<br />
                                ✓ Graduação completa;<br />
                                ✓ Experiência em gestão na Happy;<br />
                                ✓ Histórico de resultados positivos como líder;<br />
                                ✓ Realizar mentoria com Padawans e Cavaleiros;<br />
                            </li>
                        </li>
                    </DivCenter>
                    <h2>Mentoria</h2>
                    <p>
                        A mentoria é um passo fundamental e obrigatório para a jornada de crescimento<br />
                        de Cavaleiros, pois ela permite maior interação entre as lideranças, apresentando um<br />
                        caminho diferenciado para o desenvolvimento, crescimento e motivação, tanto do<br />
                        mentor, quanto do mentorado. O Mentor pode utilizar a ferramenta que avaliar como<br />
                        mais adequada para cada mentorado, segue abaixo algumas sugestões:<br />
                    </p>
                    <DivCenter className="ajust-div">
                        <Row>
                            <Col className="p-0">
                                <Row id="title-tabela-mentoria" className="tabela-mentoria">público</Row>
                                <Row id="celula-publico" className="tabela-mentoria"><p><strong>Cavaleiro Happy</strong></p></Row>
                            </Col>
                            <Col className="p-0">
                                <Row id="title-tabela-mentoria" className="tabela-mentoria">mentor</Row>
                                <Row className="tabela-mentoria"><li>Cavaleiro Happy 3</li></Row>
                                <Row className="tabela-mentoria"><li>Diplomata</li></Row>
                                <Row className="tabela-mentoria"><li>Mestre</li></Row>
                                <Row className="tabela-mentoria"><li>Consul</li></Row>
                                <Row className="tabela-mentoria"><li>Gran Mestre</li></Row>
                            </Col>
                            <Col className="p-0">
                                <Row id="title-tabela-mentoria" className="tabela-mentoria">ferramenta</Row>
                                <Row className="tabela-mentoria celula-ferramenta"><li>Análise SWOT</li></Row>
                                <Row className="tabela-mentoria celula-ferramenta"><li>6Ws</li></Row>
                                <Row className="tabela-mentoria celula-ferramenta"><li>Mentoria de liderança</li></Row>
                            </Col>
                        </Row>
                    </DivCenter>
                    <p>
                        Não há necessidade do mentor ser da mesma área que o mentorado, mas é<br />
                        importante seguir o descritivo acima.<br />
                    </p>
                    <h2>Cerimonial da Ordem Happy</h2>
                    <p>
                        O Cerimonial da Ordem Happy acontecerá uma vez ao ano, preferencialmente no<br />
                        mês de abril. Neste evento, serão divulgados os colaboradores que mais se<br />
                        destacaram e qual reconhecimento cada um deles irá receber. Além da apresentação<br />
                        da Ascenção para todo o Templo, bem como o pitch construído pelos colaboradores<br />
                        na missão.<br />
                    </p>
                </Divider>
            </Container>

            <DivCenter>
                <Row>
                    <Col>
                        <Link to="/cargo">
                            <ArrowLeftCircleFill id="arrow-left-circle-fill" className="btn-icon-arrow" />
                        </Link>
                    </Col>
                    <Col>
                        <Link to="/manual">
                            <BtnPadrao id="voltar">Voltar</BtnPadrao>
                        </Link>
                    </Col>
                    <Col>
                        <Link to="/hino">
                            <ArrowRightCircleFill id="arrow-right-circle-fill" className="btn-icon-arrow" />
                        </Link>
                    </Col>
                </Row>
            </DivCenter>
        </>
    )
}

export default JornadaManual;