import styled, { css, keyframes } from "styled-components";

const slideIn = keyframes`
  to {
    transform: scale(1.7) translate(-15rem, -15rem);
  }
`

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

/******************************** Div normal sem styles|********************************/
export const Divider = styled.div``;

/********************************| Div Alinha ao Centro |********************************/
export const DivCenter = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

/********************************| Div Alinha ao Centro com Position Relative |********************************/
export const DivCenterPR = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
`;

/********************************| Div Alinha ao Centro com Position Absolute |********************************/
export const DivCenterPA = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
`;

/********************************| Configuração de coluna em componente |********************************/
/*alinhar elementos do container ao centro em coluna um elemento abaixo do outro*/
export const Col = styled.div`
    display: inline-block;
    flex-direction: column;
    align-items: center; 
    justify-content: center;
`;
/********************************| Configuração de linha em componente |********************************/
/*alinhar elementos do container ao centro em linha elemento lado a lado*/
export const Row = styled.div`
    display: block;
    flex-direction: row;
    align-items: center; 
    justify-content: center;
`;

/******************************** Estilos do login |********************************/

export const Content = styled.div`
  ${({ isOpen }) => css`
    display: ${!!isOpen ? 'none' : 'block'};
  `}
`

const imagesWrapperModifiers = {
  open: () => css`
    overflow: hidden;
    animation: ${slideIn} 1.3s linear forwards;
  `
}


export const ImagesWrapper = styled.div`
  ${({ isOpen }) => css`
      ${!!isOpen && imagesWrapperModifiers.open()}
  `}
`

/********************************| Cristais Personalizado para a avaliação |********************************/

export const Wrapper = styled.form``;

export const Loader = styled.div`
  width: 50px;
  height: 50px;

  margin: 200px auto;

  border-radius: 50px;

  border: 6px solid #0C306C;
  border-top-color: transparent;

  animation: ${rotate} 1s linear infinite;
`;

export const Cristais = styled.button`
  ${({ src }) => css`
    border: none;
    outline: none;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 80px;
    height: 80px;

    border-radius: 8px;

    background: url(${src});
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;

    transform: translate();
  `}
`;

export const CirstaisWrapper = styled.div`
  ${({ checkPosition }) => css`
    display: flex;
    align-items: center;
    justify-content: space-around;

    ${Cristais}:nth-child(${checkPosition}) {
      box-shadow: inset 0 0 1em #fff;
    }
  `}
`;