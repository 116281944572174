import { CardIcon } from 'helpers/card-icon';
import { Col, Row } from 'helpers/ColRow';
import { Container } from 'helpers/Container';
import { Divider } from 'helpers/divider/StylesDivider';
import { Header } from 'helpers/header';

import { Title } from 'helpers/titles';
import { FaEdit, FaListAlt, FaStar } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import "./styles.css";

function PainelAvaliacao() {

    return (
        <Container>
            <Header>
                <Title>Painel de Avaliações</Title>
            </Header>

            <Row className="config-row-painel-avaliacao">
                <Col className="config-col-painel-avaliacao">
                    <Link to={"/nova-avaliacao"}>
                        <CardIcon>
                            <Divider className="alin-star">
                                <FaStar id="fa-star" />
                            </Divider>
                            <FaEdit id="fa-edit" />
                        </CardIcon>
                        <h3>nova avaliação</h3>
                    </Link>
                </Col>

                <Col className="config-col-painel-avaliacao">
                    <Link to={"/lista-avaliacao"}>
                        <CardIcon>
                            <FaEdit id="fa-edit" />
                        </CardIcon>
                        <h3>lista de avaliações</h3>
                    </Link>
                </Col>

                <Col className="config-col-painel-avaliacao">
                    <Link to={`/lista-colaboradores-avaliados`}>
                        <CardIcon>
                            <FaListAlt id="fa-list-alt" />
                        </CardIcon>
                        <h3>lista de colaboradores avaliados</h3>
                    </Link>
                </Col>
            </Row>
        </Container >
    );
}

export default PainelAvaliacao;