import { Image } from "react-bootstrap";
import { DivCenter } from "helpers/divider/StylesDivider";
import gran_mestre_carousel from "../../../_assets/img/img-png/personagem-patente/slice2.png";
import granMestreInsigniaCarousel from "../../../_assets/img/img-png/insignias-patente/gran mestre.png";
import "./granmestre.styles.css";
import "../styles.css";

export const GranMestreCarousel = () => (
    <>
        <DivCenter>
            <Image src={gran_mestre_carousel} alt="img-gran-mestre-carousel" id="manual-gran-mestre-carousel" className="manual-patente-carousel" />

            <DivCenter className="block-patente-gran-mestre-title">
                <h2 className="alin-title-patente-carrossel">gran mestre</h2>
            </DivCenter>

            <DivCenter className="block-carrossel-full block-config-gran-mestre-carrossel">
                <Image src={granMestreInsigniaCarousel} alt="img-gran-mestre-insignia-carousel" id="manual-gran-mestre-insignia-carousel" className="manual-patente-insignia-gran-mestre-carousel" />
            </DivCenter>

        </DivCenter>
    </>
);