import { useEffect, useState } from 'react';
import { Star } from 'react-bootstrap-icons';
import { Link, useParams } from 'react-router-dom';
import { IColaboradorFullDTO, IQuestionarioPessoaFullDTO } from '_config/models/ordem-happy-api-model';
import { Col, Row } from 'helpers/ColRow';
import { useAuth } from 'context/AuthProvider/useAuth';
import { PerfilFuncao } from '_config/constant';
import { FaRegListAlt } from 'react-icons/fa';
import { useQuestionarioPessoaService } from '_config/service/questionarioPessoa.service';
import * as C from "./styles";
import { Divider } from 'helpers/divider/StylesDivider';

interface Props {
    colaborador: IColaboradorFullDTO;
}

function RowColaboradoresAvaliados({ colaborador }: Props) {
    const { questionarioId }: any = useParams();
    const usuario = useAuth();
    const [respostas, setRespostas] = useState<IQuestionarioPessoaFullDTO[]>([]);
    const serviceQuestionarioPessoa = useQuestionarioPessoaService();

    const questionarioPessoa = () => {
        serviceQuestionarioPessoa.questionarioPessoa(colaborador?.id)
            .then(response => {
                setRespostas(response.data)
            });
    }

    useEffect(() => {
        questionarioPessoa();
    }, [])

    return (
        <C.Lista>
            <Divider>
                <p><strong>{colaborador.nome}</strong></p>
            </Divider>
            <Divider>
                <p>{colaborador.area}</p>
            </Divider>
            <Divider>
                <p>Buscar avaliação </p>
            </Divider>

            {usuario.admin.codigo === PerfilFuncao.CODIGO_RECURSOS_HUMANOS ||
                usuario.admin.codigo === PerfilFuncao.CODIGO_TECNOLOGIA ?
                <>
                    <Row>
                        <Link to={`/feedback-colaborador/${colaborador.id}`}>
                            <FaRegListAlt id="fa-reg-list-alt"/>
                        </Link>
                    
                        <Link to={`/resultado-avaliacao/${colaborador.id}`}>
                            <Star id="star" />
                        </Link>
                    </Row>

                </>
                : null}
        </C.Lista>
    );
}

export default RowColaboradoresAvaliados;