
import { Container } from "helpers/Container";
import { Divider } from "helpers/divider/StylesDivider";
import { Title } from "helpers/titles";
import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useArquivoService } from "_config/service/arquivo.service";
import { mensagemAlert, mensagemErro, mensagemSucesso } from 'Components/Toastr/toastr';
import { Header } from "helpers/header";
import MsgInfoSistema from "Components/MsgInfoSistema";
import { Row } from "helpers/ColRow";
import { BsFillInfoCircleFill } from "react-icons/bs";
import "./styles.css";

function UploadHolerite() {
    const service = useArquivoService();
    const [file, setFile] = useState<any>();
    const [date, setDate] = useState<any>();
    const [hideDisplay, setHideDisplay] = useState<boolean>(false);

    const enviarArquivo = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        service.enviarHolerite(file[0], date)
            .then(() => mensagemSucesso("Arquivo enviado com sucesso!"))
            .catch(() => mensagemErro("Houve um erro ao enviar o arquivo!"))
            .finally(() => mensagemAlert("O processo de envio terminou!"));
    }

    return (
        <Container>
            <Header>
                <Title>Enviar documentos</Title>
            </Header>

            <Divider className="container-form-enviar-documento">
                <Form.Group controlId="formFile" className="form-enviar-documento mb-3">
                    <Row>
                        <Form.Label htmlFor="arquivo">Enviar holerite formato PDF</Form.Label>
                        <BsFillInfoCircleFill
                            id="bs-fill-info-circle-fill"
                            className="icon-info"
                            onClick={() => setHideDisplay(!hideDisplay)} />
                    </Row>
                    {hideDisplay ?
                        <MsgInfoSistema
                            setHideDisplay={setHideDisplay}
                            hideDisplay={hideDisplay}
                            title={"Envio de arquivos"}
                            descriptionOne={"Para enviar o arquivo deve ser informado o mês e ano para que seja exibido o campo para upload."}
                            observacao={"O botão de envio ficara bloqueado até que seja informado data do envio do arquivo e tenha o arquivo anexado."}
                            color={"#FFF"}
                            width={400}
                            fontSizeTitle={"1.5em"}
                            iconDefault={"i"}
                            colorIcon={"#FFF"}
                            backgroundIcon={"#0C306C"}
                        />
                        : null}
                    <Form.Control type={"month"}
                        onChange={(e) => setDate(e.target.value)}
                    />
                    <Form onSubmit={enviarArquivo}>
                        {date ?
                            <input type="file" onChange={e => setFile(e.target.files)}></input>
                            : null}
                        <Button disabled={!file ? true : false} type="submit" className="button-second" style={{ width: 350, margin: 5 }}> Enviar </Button>
                    </Form>
                </Form.Group>
            </Divider>
        </Container>
    );
}

export default UploadHolerite;