import { Col, Row } from 'helpers/ColRow';
import { Container } from 'helpers/Container';
import { Header } from 'helpers/header';
import { Title } from 'helpers/titles';
import { Link } from 'react-router-dom';
import { CargosHappy, CrescimentoHappy, GlossarioHappy, HinoHappy, MundoHappy, TemploHappy } from './json';
import './styles.css';

function Manual() {
    return (

        <Container>
            <Header>
                <Title>Procedimento a Ordem Happy</Title>
            </Header>

            <Row className="config-row-manual">
                <Col className="config-col-manual">
                    <h4>Mundo Happy</h4>
                    <Link to="/mundo"><MundoHappy /></Link>
                </Col>

                <Col className="config-col-manual">
                    <h4>Templo Happy</h4>
                    <Link to="/templo"><TemploHappy /></Link>
                </Col>

                <Col className="config-col-manual">
                    <h4>Cargos e Salários</h4>
                    <Link to="/cargo"><CargosHappy /></Link>
                </Col>

                <Col className="config-col-manual">
                    <h4>Crescimento Happy</h4>
                    <Link to="/jornada-manual"><CrescimentoHappy /></Link>
                </Col>

                <Col className="config-col-manual">
                    <h4>Hino Happy</h4>
                    <Link to="/hino"><HinoHappy /></Link>
                </Col>

                <Col className="config-col-manual">
                    <h4>Glossário Ordem Happy</h4>
                    <Link to="/glossario"><GlossarioHappy /></Link>
                </Col>
            </Row>

        </Container>
    )
}

export default Manual;