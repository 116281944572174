import { useAuth } from "context/AuthProvider/useAuth";
import { Form, Input, message } from "antd";
import { useNavigate } from "react-router-dom";
import { Divider, Content } from "helpers/divider/StylesDivider";
import { ButtonLogin } from "helpers/buttom_padrao/StylesButtonPadrao";

import "./styles.css";
import { ImagesWrapper, CardLogin } from "./exportimg";
import { useState } from "react";
import { ContainerClean } from "helpers/Container";

export const Login = () => {
  const { authenticate } = useAuth();
  const [portaAberta, setPortaAberta] = useState(false);

  const navigate = useNavigate();

  async function handleLogin(values: {
    login: string;
    senha: string;
    area: string;
    admin: string;
    nome: string;
    id: number;
    patente: string;
    superiorImediato: string;
  }) {
    try {
      await authenticate(
        values.login,
        values.senha,
        values.area,
        values.admin,
        values.nome,
        values.id,
        values.patente,
        values.superiorImediato
      );

      setPortaAberta(true);
      setTimeout(() => navigate("home"), 1600);
    } catch (error) {
      message.error("Senha ou login invalidos");
    }
  }

  return (
    <>
      <ContainerClean>
        <ImagesWrapper isOpen={portaAberta} />
        <Content isOpen={portaAberta}>
          <CardLogin />
          <Divider className="alin-login">
            <Form onFinish={handleLogin}>
              <Form.Item name="login">
                <Input placeholder="INTEGRANTE" className="config-input" />
              </Form.Item>
              <Form.Item name="senha">
                <Input
                  type="password"
                  placeholder="CÓDIGO DE ACESSO"
                  className="config-input"
                />
              </Form.Item>
              <ButtonLogin
                className="btn-tela-login"
                type="primary"
                htmlType="submit"
              >
                ACESSO AO TEMPLO
              </ButtonLogin>
            </Form>
          </Divider>
        </Content>
      </ContainerClean>
      {/*
                <ReactForm.Group className="mb-3" controlId="formBasicEmail">
                    <ReactForm.Label>Email address</ReactForm.Label>
                        <ReactForm.Control
                        type="input"
                        placeholder="Insira o usuário"
                        onChange={(e) => setUsuario({ ...usuario, login: e.target.value}) }
                        />
                    <ReactForm.Text className="text-muted">
                    Informe o usuário
                    </ReactForm.Text>
                </ReactForm.Group>
                <ReactForm.Group className="mb-3" controlId="formBasicPassword">
                    <ReactForm.Label>Password</ReactForm.Label>
                        <ReactForm.Control
                        type="password"
                        placeholder="Password"
                        onChange={(e) => setUsuario({ ...usuario, senha: e.target.value}) }
                        />
                </ReactForm.Group>
                <ReactForm.Group className="mb-3" controlId="formBasicCheckbox">
                    <ReactForm.Check type="checkbox" label="Check me out" />
                </ReactForm.Group>
                <Link to="/Home">
                    <Button variant="primary" type="submit">
                        Entrar
                    </Button>
            </Link>*/}
    </>
  );
};
