import ReactDOM from 'react-dom';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'toastr/build/toastr.min.js'
import 'toastr/build/toastr.css'
import 'toastr/build/toastr.min.css'
import "./reset.css";
import 'index.css';

ReactDOM.render(
  <App />,
  document.getElementById('root')
);
