
import { Api } from '_config/api/apiService';
import { IAxiosResponseCustom } from '_config/models/http.model';
import { IQuestionarioFullDTO } from '_config/models/ordem-happy-api-model';

export const useQuestionarioService = () => {
	const resourceURL = 'questionario';

	const questionario = (
		questionarioId: number,
	): Promise<IAxiosResponseCustom<IQuestionarioFullDTO>> =>
		Api.get(`${resourceURL}/${questionarioId}`);
	
		const todosQuestionario = (
		): Promise<IAxiosResponseCustom<IQuestionarioFullDTO[]>> =>
			Api.get(`${resourceURL}/todos`);

	const novoQuestionario = (
		questionario: any,
	): Promise<IAxiosResponseCustom<IQuestionarioFullDTO>> =>
		Api.post(`${resourceURL}/novo-questionario`, questionario);

	const editarQuestionario = (
		questionarioId: number,
		questionario: any,
	): Promise<IAxiosResponseCustom<IQuestionarioFullDTO>> =>
		Api.patch(`${resourceURL}/editar-questionario/${questionarioId}`, questionario);

	return {
		questionario,
		todosQuestionario,
		novoQuestionario,
		editarQuestionario,
	};
};
