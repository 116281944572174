import { Api } from "_config/api/apiService";
import { IUser } from "./types";

export function setUserLocalStorage(user: IUser | null) {
    localStorage.setItem('usuario', JSON.stringify(user));
}

export function getUserLocalStorage() {
    const json = localStorage.getItem('usuario')

    if (!json) {
        return null;
    }

    const user = JSON.parse(json)

    return user ?? null;
}

export async function LoginRequest(login: string, senha: string) {
    try {
        const request = await Api.post('pessoa/autenticar/', { login, senha })

        return request.data;
    } catch (error) {
        return null;
    }

}