export const generos = ['', 'Masculino', 'Feminino', 'Não Identificado'];

export const estadoCivil = ['', 'Solteiro (a)', 'Casado (a)', 'Divorciado (a)', 'Separado (a)', 'Viúvo (a)'];

export const bancos = ['',
    '001 - BANCO DO BRASIL S.A (BB)',
    '033 - BANCO SANTANDER BRASIL S.A',
    '077 - BANCO INTER S.A',
    '102 - XP INVESTIMENTOS S.A',
    '104 - CAIXA ECONÔMICA FEDERAL (CEF)',
    '133 - CRESOL CONFEDERAÇÃO',
    '136 - UNICRED COOPERATIVA',
    '208 - BANCO BTG PACTUAL S.A',
    '212 - BANCO ORIGINAL S.A',
    '237 - BRADESCO S.A',
    '260 - NU PAGAMENTOS S.A (NUBANK)',
    '290 - PAGSEGURO INTERNET S.A (PAGBANK)',
    '323 - MERCADO PAGO - CONTA DO MERCADO LIVRE',
    '341 - ITAÚ UNIBANCO S.A',
    '348 - BANCO XP S/A',
    '380 - PICPAY SERVICOS S.A.',
    '422 - BANCO SAFRA S.A',
    '623 - BANCO PAN',
    '746 - BANCO MODAL S.A',
    '748 - SICREDI S.A',
    '756 - BANCOOB (BANCO COOPERATIVO DO BRASIL)'];

export const grauInstrucoes = ['',
    'Ensino fundamental incompleto',
    'Ensino fundamental completo',
    'Ensino médio incompleto',
    'Ensino médio completo',
    'Superior incompleto',
    'Superior completo',
    'Pós-graduação',
    'Mestrado',
    'Doutorado',
    'Pós-Doutorado'];

export const estados = ['',
    'ACRE - AC',
    'ALAGOAS - AL',
    'AMAPÁ - AP',
    'AMAZONAS - AM',
    'APUCARANA - PR',
    'BAHIA - BA',
    'CEARÁ - CE',
    'DISTRITO FEDERAL - DF',
    'ESPÍRITO SANTO - ES',
    'GOIÁS - GO',
    'MARANHÃO - MA',
    'MATO GROSSO - MT',
    'MATO GROSSO DO SUL - MS',
    'MINAS GERAIS - MG',
    'PARÁ - PA',
    'PARAÍBA - PB',
    'PARANÁ - PR',
    'PERNAMBUCO - PE',
    'PIAUÍ - PI',
    'RIO DE JANEIRO - RJ',
    'RIO GRANDE DO NORTE - RN',
    'RIO GRANDE DO SUL - RS',
    'RONDÔNIA - RO',
    'RORAIMA - RR',
    'SANTA CATARINA - SC',
    'SÃO PAULO - SP',
    'SERGIPE - SE',
    'TOCANTINS - TO'];

export const cidades = ['',
    'Águas de Lindóia',
    'Álvares Machado',
    'Alvorada',
    'Americana',
    'Amparo',
    'Ananindeua',
    'Anápolis',
    'Antônio Carlos',
    'Aparecida de Goiânia',
    'Aracaju',
    'Araçatuba',
    'Araporã',
    'Araraquara',
    'Araucária',
    'Artur Nogueira',
    'Astorga',
    'Apucarana',
    'Bagé',
    'Barão de Cocais',
    'Barbacena',
    'Barcarena',
    'Barro Alto',
    'Barueri',
    'Belém',
    'Belford Roxo',
    'Belo Horizonte',
    'Benevides',
    'Bento Gonçalves',
    'Betim',
    'Biguaçu',
    'Birigüi',
    'Blumenau',
    'Boa Vista',
    'Bom Jesus dos Perdões',
    'Braço do Norte',
    'Bragança',
    'Bragança Paulista',
    'Brasília',
    'Brusque',
    'Cabedelo',
    'Cabo Frio',
    'Cachoeirinha',
    'Caldas Novas',
    'Cambé',
    'Cametá',
    'Campinas',
    'Campo Largo',
    'Campos do Jordão',
    'Canoas',
    'Cantagalo',
    'Capivari',
    'Carapicuíba',
    'Carazinho',
    'Cascavel',
    'Caseiros',
    'Castanhal',
    'Caxambu',
    'Caxias do Sul',
    'Cerro Grande do Sul',
    'Cerro Largo',
    'Chapecó',
    'Cianorte',
    'Cidade Ocidental',
    'Conselheiro Lafaiete',
    'Constantina',
    'Contagem',
    'Cordeiro',
    'Cordilheira Alta',
    'Coronel Vivida',
    'Cotia',
    'Criciúma',
    'Cruz Alta',
    'Curitiba',
    'Dois Irmãos',
    'Eldorado do Sul',
    'Embu das Artes',
    'Estância Velha',
    'Esteio',
    'Estrela',
    'Fátima',
    'Faxinal dos Guedes',
    'Florianópolis',
    'Fortaleza',
    'Foz do Iguaçu',
    'Francisco Morato',
    'Franco da Rocha',
    'Gandu',
    'Garibaldi',
    'Goianésia',
    'Goiânia',
    'Gramado',
    'Gravataí',
    'Guaporé',
    'Guaraniaçu',
    'Guarapuava',
    'Guararapes',
    'Guarulhos',
    'Herval D`Oeste',
    'Hortolândia',
    'Ibiporã',
    'Ilhabela',
    'Indaial',
    'Indaiatuba',
    'Indiana',
    'Itabira',
    'Itaboraí',
    'Itapevi',
    'Itatiba',
    'Itaúna',
    'Itupeva',
    'Jaguariúna',
    'Jandaia do Sul',
    'Jandira',
    'Japeri',
    'Jaraguá do Sul',
    'Joaçaba',
    'João Pessoa',
    'Juazeiro',
    'Juiz de Fora',
    'Jundiaí',
    'Laguna',
    'Lajeado',
    'Londrina',
    'Louveira',
    'Mafra',
    'Magé',
    'Mairinque',
    'Manaus',
    'Marabá',
    'Mariana Pimentel',
    'Maricá',
    'Marília',
    'Maringá',
    'Marituba',
    'Matão',
    'Mauá',
    'Mesquita',
    'Mococa',
    'Mossoró',
    'Nilópolis',
    'Niterói',
    'Nova Friburgo',
    'Nova Iguaçu',
    'Nova Lima',
    'Nova Odessa',
    'Nova Pádua',
    'Novo Hamburgo',
    'Orlândia',
    'Osasco',
    'Ouro Preto',
    'Paiçandu',
    'Palhoça',
    'Palmitos',
    'Pará',
    'Paracambi',
    'Parnaíba',
    'Passos',
    'Paulínia',
    'Pelotas',
    'Petrolina',
    'Petrópolis',
    'Pinhais',
    'Piracicaba',
    'Piratininga',
    'Poços de Caldas',
    'Pontal do Paraná',
    'Portão',
    'Porto Alegre',
    'Porto Feliz',
    'Porto Velho',
    'Praia Grande',
    'Presidente Médici',
    'Presidente Prudente',
    'Queimados',
    'Recife',
    'Ribeirão Pires',
    'Ribeirão Preto',
    'Rinópolis',
    'Rio Claro',
    'Rio de Janeiro',
    'Rio Grande da Serra',
    'Rio Verde',
    'Rolândia',
    'Sabará',
    'Salvador',
    'Santa Bárbara D`Oeste',
    'Santa Cruz do Sul',
    'Santa Isabel do Pará',
    'Santa Maria',
    'Santana de Parnaíba',
    'Santarém',
    'Santo Amaro da Imperatriz',
    'Santo André',
    'Santo Ângelo',
    'Santo Antônio da Patrulha',
    'Santos',
    'São Gonçalo',
    'São João de Meriti',
    'São Jorge do Ivaí',
    'São José',
    'São José dos Pinhais',
    'São Leopoldo',
    'São Luís',
    'São Paulo',
    'Sapucaia do Sul',
    'Sarandi',
    'Senador Canedo',
    'Senhora de Oliveira',
    'Seropédica',
    'Serra Talhada',
    'Severiano de Almeida',
    'Sorriso',
    'Sumaré',
    'Suzano',
    'Taboão da Serra',
    'Timbó',
    'Timóteo',
    'Trindade',
    'Tubarão',
    'Ubá',
    'Uberaba',
    'Uberlândia',
    'Vale Real',
    'Valinhos',
    'Várzea Paulista',
    'Viamão',
    'Vila Velha',
    'Vinhedo',
    'Vitória',
    'Vitória da Conquista',
    'Xangri-Lá',
    'Xaxim'];

export const paises = ['',
    'Afeganistão',
    'África do Sul',
    'Albânia',
    'Alemanha',
    'Andorra',
    'Angola',
    'Anguilla',
    'Antárctica',
    'Antigua e Barbuda',
    'Antilhas Holandesas',
    'Arábia Saudita',
    'Argélia',
    'Argentina',
    'Armênia',
    'Aruba',
    'Austrália',
    'Áustria',
    'Azerbaijão',
    'Bahamas',
    'Bangladesh',
    'Barbados',
    'Barein',
    'Belarus',
    'Bélgica',
    'Belize',
    'Benin',
    'Bermuda',
    'Bolívia',
    'Bósnia e Herzegóvina',
    'Botswana',
    'Brasil',
    'Brunei',
    'Bulgária',
    'Burkina Fasso',
    'Burundi',
    'Butão',
    'Cabo Verde',
    'Camarões',
    'Camboja',
    'Canadá',
    'Catar',
    'Cazaquistão',
    'Chade',
    'Chile',
    'China',
    'Chipre',
    'Cingapura',
    'Colômbia',
    'Comores',
    'Congo',
    'Coréia do Norte',
    'Coréia do Sul',
    'Costa do Marfim',
    'Costa Rica',
    'Croácia',
    'Cuba',
    'Dinamarca',
    'Djibuti',
    'Dominica',
    'Egito',
    'El Salvador',
    'Emirados Árabes Unidos',
    'Equador',
    'Eritréia',
    'Eslováquia',
    'Eslovênia',
    'Espanha',
    'Estado da Palestina',
    'Estados Unidos',
    'Estônia',
    'Etiópia',
    'Federação Russa',
    'Fiji',
    'Filipinas',
    'Finlândia',
    'França',
    'França Metropolitana',
    'Gabão',
    'Gâmbia',
    'Gana',
    'Geórgia',
    'Gibraltar',
    'Grã-Bretanha',
    'Granada',
    'Grécia',
    'Groenlândia',
    'Guadalupe',
    'Guam',
    'Guatemala',
    'Guiana',
    'Guiana Francesa',
    'Guiné',
    'Guiné Equatorial',
    'Guiné-Bissau',
    'Haiti',
    'Holanda',
    'Honduras',
    'Hong Kong',
    'Hungria',
    'Iêmen',
    'Ilha Bouvet',
    'Ilha Christmas',
    'Ilha Norfolk',
    'Ilhas Cayman',
    'Ilhas Cocos',
    'Ilhas Cook',
    'Ilhas Faroe',
    'Ilhas Geórgia do Sul e Ilhas Sandwich do Sul',
    'Ilhas Guernsey',
    'Ilhas Heard e Mac Donald',
    'Ilhas Malvinas',
    'Ilhas Mariana',
    'Ilhas Marshall',
    'Ilhas Pitcairn',
    'Ilhas Reunião',
    'Ilhas Salomão',
    'Ilhas Santa Helena',
    'Ilhas Svalbard e Jan Mayen',
    'Ilhas Tokelau',
    'Ilhas Turks e Caicos',
    'Ilhas Virgens',
    'Ilhas Virgens Britânicas',
    'Ilhas Wallis e Futuna',
    'Índia',
    'Indonésia',
    'Irã',
    'Iraque',
    'Irlanda',
    'Islândia',
    'Israel',
    'Itália',
    'Iugoslávia',
    'Jamaica',
    'Japão',
    'Jersey',
    'Jordânia',
    'Kiribati',
    'Kuweit',
    'Laos',
    'Lesoto',
    'Letônia',
    'Líbano',
    'Libéria',
    'Líbia',
    'Liechtenstein',
    'Lituânia',
    'Luxemburgo',
    'Macau',
    'Macedônia',
    'Madagascar',
    'Malásia',
    'Malauí',
    'Maldivas',
    'Mali',
    'Malta',
    'Marrocos',
    'Martinica',
    'Maurício',
    'Mauritânia',
    'Mayotte',
    'México',
    'Mianmar',
    'Micronésia',
    'Moçambique',
    'Moldávia',
    'Mônaco',
    'Mongólia',
    'Montenegro',
    'Montserrat',
    'Namíbia',
    'Nauru',
    'Nepal',
    'Nicarágua',
    'Niger',
    'Nigéria',
    'Niue',
    'Noruega',
    'Nova Caledônia',
    'Nova Zelândia',
    'Omã',
    'Palau',
    'Panamá',
    'Papua Nova Guiné',
    'Paquistão',
    'Paraguai',
    'Peru',
    'Polinésia Francesa',
    'Polônia',
    'Porto Rico',
    'Portugal',
    'Quênia',
    'Quirguízia',
    'República Centro-Africana',
    'República Dominicana',
    'República Tcheca',
    'Romênia',
    'Ruanda',
    'Sahara Ocidental',
    'Samoa Americana',
    'Samoa Ocidental',
    'San Marino',
    'Santa Lúcia',
    'São Cristóvão e Névis',
    'São Pierre e Miquelon',
    'São Tomé e Príncipe',
    'São Vicente e Granadinas',
    'Seicheles',
    'Senegal',
    'Serra Leoa',
    'Sérvia',
    'Síria',
    'Somália',
    'Sri Lanka',
    'Suazilândia',
    'Sudão',
    'Suécia',
    'Suíça',
    'Suriname',
    'Tadjiquistão',
    'Tailândia',
    'Taiwan',
    'Tanzânia',
    'Territórios Franceses Meridionais',
    'Timor Leste',
    'Togo',
    'Tonga',
    'Trinidad e Tobago',
    'Tunísia',
    'Turcomênia',
    'Turquia',
    'Tuvalu',
    'Ucrânia',
    'Uganda',
    'Uruguai',
    'Uzbequistão',
    'Vanuatu',
    'Vaticano',
    'Venezuela',
    'Vietnã',
    'Zâmbia',
    'Zimbábue'];

export const cidadesEstado = ['',
    'Apucarana / PR',
    'Astorga / PR',
    'Conselheiro Mairinck / PR',
    'Contenda / PR',
    'Corbélia / PR',
    'Cornélio Procópio / PR',
    'Coronel Domingos Soares / PR',
    'Coronel Vivida / PR',
    'Corumbataí do Sul / PR',
    'Cruz Machado / PR',
    'Cruzeiro do Iguaçu / PR',
    'Cruzeiro do Oeste / PR',
    'Cruzeiro do Sul / PR',
    'Cruzmaltina / PR',
    'Cianorte / PR',
    'Curitiba / PR',
    'Curiúva / PR',
    'Diamante D´oeste / PR',
    'Diamante do Norte / PR',
    'Diamante do Sul / PR',
    'Dois Vizinhos / PR',
    'Douradina / PR',
    'Doutor Camargo / PR',
    'Doutor Ulysses / PR',
    'Enéas Marques / PR',
    'Engenheiro Beltrão / PR',
    'Entre Rios do Oeste / PR',
    'Esperança Nova / PR',
    'Espigão Alto do Iguaçu / PR',
    'Farol / PR',
    'Faxinal / PR',
    'Fazenda Rio Grande / PR',
    'Fênix / PR',
    'Fernandes Pinheiro / PR',
    'Figueira / PR',
    'Flor da Serra do Sul / PR',
    'Floraí / PR',
    'Floresta / PR',
    'Florestópolis / PR',
    'Flórida / PR',
    'Formosa do Oeste / PR',
    'Foz do Iguaçu / PR',
    'Foz do Jordão / PR',
    'Francisco Alves / PR',
    'Francisco Beltrão / PR',
    'General Carneiro / PR',
    'Godoy Moreira / PR',
    'Goioerê / PR',
    'Goioxim / PR',
    'Grandes Rios / PR',
    'Guaíra / PR',
    'Guairaçá / PR',
    'Guamiranga / PR',
    'Guapirama / PR',
    'Guaporema / PR',
    'Guaraci / PR',
    'Guaraniaçu / PR',
    'Guarapuava / PR',
    'Guaraqueçaba / PR',
    'Guaratuba / PR',
    'Honório Serpa / PR',
    'Ibaiti / PR',
    'Ibema / PR',
    'Ibiporã / PR',
    'Icaraíma / PR',
    'Iguaraçu / PR',
    'Iguatu / PR',
    'Imbaú / PR',
    'Imbituva / PR',
    'Inácio Martins / PR',
    'Inajá / PR',
    'Indianópolis / PR',
    'Ipiranga / PR',
    'Iporã / PR',
    'Iracema do Oeste / PR',
    'Irati / PR',
    'Iretama / PR',
    'Itaguajé / PR',
    'Itaipulândia / PR',
    'Itambaracá / PR',
    'Itambé / PR',
    'Itapejara D´oeste / PR',
    'Itaperuçu / PR',
    'Itaúna do Sul / PR',
    'Ivaí / PR',
    'Ivaiporã / PR',
    'Ivaté / PR',
    'Ivatuba / PR',
    'Jaboti / PR',
    'Jacarezinho / PR',
    'Jaguapitã / PR',
    'Jaguariaíva / PR',
    'Jandaia do Sul / PR',
    'Janiópolis / PR',
    'Japira / PR',
    'Japurá / PR',
    'Jardim Alegre / PR',
    'Jardim Olinda / PR',
    'Jataizinho / PR',
    'Jesuítas / PR',
    'Joaquim Távora / PR',
    'Jundiaí do Sul / PR',
    'Juranda / PR',
    'Jussara / PR',
    'Kaloré / PR',
    'Lapa / PR',
    'Laranjal / PR',
    'Laranjeiras do Sul / PR',
    'Leópolis / PR',
    'Lidianópolis / PR',
    'Lindoeste / PR',
    'Loanda / PR',
    'Lobato / PR',
    'Londrina / PR',
    'Luiziana / PR',
    'Lunardelli / PR',
    'Lupionópolis / PR',
    'Mallet / PR',
    'Mamborê / PR',
    'Mandaguaçu / PR',
    'Mandaguari / PR',
    'Mandirituba / PR',
    'Manfrinópolis / PR',
    'Mangueirinha / PR',
    'Manoel Ribas / PR',
    'Marechal Cândido Rondon / PR',
    'Maria Helena / PR',
    'Marialva / PR',
    'Marilândia do Sul / PR',
    'Marilena / PR',
    'Mariluz / PR',
    'Maringá / PR',
    'Mariópolis / PR',
    'Maripá / PR',
    'Marmeleiro / PR',
    'Marquinho / PR',
    'Marumbi / PR',
    'Matelândia / PR',
    'Matinhos / PR',
    'Mato Rico / PR',
    'Mauá da Serra / PR',
    'Medianeira / PR',
    'Mercedes / PR',
    'Mirador / PR',
    'Miraselva / PR',
    'Missal / PR',
    'Moreira Sales / PR',
    'Morretes / PR',
    'Munhoz de Melo / PR',
    'Nossa Senhora Das Graças / PR',
    'Nova Aliança do Ivaí / PR',
    'Nova América da Colina / PR',
    'Nova Aurora / PR',
    'Nova Cantu / PR',
    'Nova Esperança / PR',
    'Nova Esperança do Sudoeste / PR',
    'Nova Fátima / PR',
    'Nova Laranjeiras / PR',
    'Nova Londrina / PR',
    'Nova Olímpia / PR',
    'Nova Prata do Iguaçu / PR',
    'Nova Santa Bárbara / PR',
    'Nova Santa Rosa / PR',
    'Nova Tebas / PR',
    'Novo Itacolomi / PR',
    'Ortigueira / PR',
    'Ourizona / PR',
    'Ouro Verde do Oeste / PR',
    'Paiçandu / PR',
    'Palmas / PR',
    'Palmeira / PR',
    'Palmital / PR',
    'Palotina / PR',
    'Paraíso do Norte / PR',
    'Paranacity / PR',
    'Paranaguá / PR',
    'Paranapoema / PR',
    'Paranavaí / PR',
    'Pato Bragado / PR',
    'Pato Branco / PR',
    'Paula Freitas / PR',
    'Paulo Frontin / PR',
    'Peabiru / PR',
    'Perobal / PR',
    'Pérola / PR',
    'Pérola D´oeste / PR',
    'Piên / PR',
    'Pinhais / PR',
    'Pinhal de São Bento / PR',
    'Pinhalão / PR',
    'Pinhão / PR',
    'Piraí do Sul / PR',
    'Piraquara / PR',
    'Pitanga / PR',
    'Pitangueiras / PR',
    'Planaltina do Paraná / PR',
    'Planalto / PR',
    'Ponta Grossa / PR',
    'Pontal do Paraná / PR',
    'Porecatu / PR',
    'Porto Amazonas / PR',
    'Porto Barreiro / PR',
    'Porto Rico / PR',
    'Porto Vitória / PR',
    'Prado Ferreira / PR',
    'Pranchita / PR',
    'Presidente Castelo Branco / PR',
    'Primeiro de Maio / PR',
    'Prudentópolis / PR',
    'Quarto Centenário / PR',
    'Quatiguá / PR',
    'Quatro Barras / PR',
    'Quatro Pontes / PR',
    'Quedas do Iguaçu / PR',
    'Querência do Norte / PR',
    'Quinta do Sol / PR',
    'Quitandinha / PR',
    'Ramilândia / PR',
    'Rancho Alegre / PR',
    'Rancho Alegre D´oeste / PR',
    'Realeza / PR',
    'Rebouças / PR',
    'Renascença / PR',
    'Reserva / PR',
    'Reserva do Iguaçu / PR',
    'Ribeirão Claro / PR',
    'Ribeirão do Pinhal / PR',
    'Rio Azul / PR',
    'Rio Bom / PR',
    'Rio Bonito do Iguaçu / PR',
    'Rio Branco do Ivaí / PR',
    'Rio Branco do Sul / PR',
    'Rio Negro / PR',
    'Rolândia / PR',
    'Roncador / PR',
    'Rondon / PR',
    'Rosário do Ivaí / PR',
    'Sabáudia / PR',
    'Salgado Filho / PR',
    'Salto do Itararé / PR',
    'Salto do Lontra / PR',
    'Santa Amélia / PR',
    'Santa Cecília do Pavão / PR',
    'Santa Cruz de Monte Castelo / PR',
    'Santa fé / PR',
    'Santa Helena / PR',
    'Santa Inês / PR',
    'Santa Isabel do Ivaí / PR',
    'Santa Izabel do Oeste / PR',
    'Santa Lúcia / PR',
    'Santa Maria do Oeste / PR',
    'Santa Mariana / PR',
    'Santa Mônica / PR',
    'Santa Tereza do Oeste / PR',
    'Santa Terezinha de Itaipu / PR',
    'Santana do Itararé / PR',
    'Santo Antônio da Platina / PR',
    'Santo Antônio do Caiuá / PR',
    'Santo Antônio do Paraíso / PR',
    'Santo Antônio do Sudoeste / PR',
    'Santo Inácio / PR',
    'São Carlos do Ivaí / PR',
    'São Jerônimo da Serra / PR',
    'São João / PR',
    'São João do Caiuá / PR',
    'São João do Ivaí / PR',
    'São João do Triunfo / PR',
    'São Jorge D´oeste / PR',
    'São Jorge do Ivaí / PR',
    'São Jorge do Patrocínio / PR',
    'São José da Boa Vista / PR',
    'São José Das Palmeiras / PR',
    'São José Dos Pinhais / PR',
    'São Manoel do Paraná / PR',
    'São Mateus do Sul / PR',
    'São Miguel do Iguaçu / PR',
    'São Pedro do Iguaçu / PR',
    'São Pedro do Ivaí / PR',
    'São Pedro do Paraná / PR',
    'São Sebastião da Amoreira / PR',
    'São Tomé / PR',
    'Sapopema / PR',
    'Sarandi / PR',
    'Saudade do Iguaçu / PR',
    'Sengés / PR',
    'Serranópolis do Iguaçu / PR',
    'Sertaneja / PR',
    'Sertanópolis / PR',
    'Siqueira Campos / PR',
    'Sulina / PR',
    'Tamarana / PR',
    'Tamboara / PR',
    'Tapejara / PR',
    'Tapira / PR',
    'Teixeira Soares / PR',
    'Telêmaco Borba / PR',
    'Terra Boa / PR',
    'Terra Rica / PR',
    'Terra Roxa / PR',
    'Tibagi / PR',
    'Tijucas do Sul / PR',
    'Toledo / PR',
    'Tomazina / PR',
    'Três Barras do Paraná / PR',
    'Tunas do Paraná / PR',
    'Tuneiras do Oeste / PR',
    'Tupãssi / PR',
    'Turvo / PR',
    'Ubiratã / PR',
    'Umuarama / PR',
    'União da Vitória / PR',
    'Uniflor / PR',
    'Uraí / PR',
    'Ventania / PR',
    'Vera Cruz do Oeste / PR',
    'Verê / PR',
    'Vila Alta / PR',
    'Virmond / PR',
    'Vitorino / PR',
    'Wenceslau Braz / PR',
    'Xambrê / PR',
    'Angra Dos Reis / RJ',
    'Aperibé / RJ',
    'Araruama / RJ',
    'Areal / RJ',
    'Armação Dos Búzios / RJ',
    'Arraial do Cabo / RJ',
    'Barra do Piraí / RJ',
    'Barra Mansa / RJ',
    'Belford Roxo / RJ',
    'Bom Jardim / RJ',
    'Bom Jesus do Itabapoana / RJ',
    'Cabo Frio / RJ',
    'Cachoeiras de Macacu / RJ',
    'Cambuci / RJ',
    'Campos Dos Goytacazes / RJ',
    'Cantagalo / RJ',
    'Carapebus / RJ',
    'Cardoso Moreira / RJ',
    'Carmo / RJ',
    'Casimiro de Abreu / RJ',
    'Comendador Levy Gasparian / RJ',
    'Conceição de Macabu / RJ',
    'Cordeiro / RJ',
    'Duas Barras / RJ',
    'Duque de Caxias / RJ',
    'Engenheiro Paulo de Frontin / RJ',
    'Guapimirim / RJ',
    'Iguaba Grande / RJ',
    'Itaboraí / RJ',
    'Itaguaí / RJ',
    'Italva / RJ',
    'Itaocara / RJ',
    'Itaperuna / RJ',
    'Itatiaia / RJ',
    'Japeri / RJ',
    'Laje do Muriaé / RJ',
    'Macaé / RJ',
    'Macuco / RJ',
    'Magé / RJ',
    'Mangaratiba / RJ',
    'Maricá / RJ',
    'Mendes / RJ',
    'Mesquita / RJ',
    'Miguel Pereira / RJ',
    'Miracema / RJ',
    'Natividade / RJ',
    'Nilópolis / RJ',
    'Niterói / RJ',
    'Nova Friburgo / RJ',
    'Nova Iguaçu / RJ',
    'Paracambi / RJ',
    'Paraíba do Sul / RJ',
    'Parati / RJ',
    'Paty do Alferes / RJ',
    'Petrópolis / RJ',
    'Pinheiral / RJ',
    'Piraí / RJ',
    'Porciúncula / RJ',
    'Porto Real / RJ',
    'Quatis / RJ',
    'Queimados / RJ',
    'Quissamã / RJ',
    'Resende / RJ',
    'Rio Bonito / RJ',
    'Rio Claro / RJ',
    'Rio Das Flores / RJ',
    'Rio Das Ostras / RJ',
    'Rio de Janeiro / RJ',
    'Santa Maria Madalena / RJ',
    'Santo Antônio de Pádua / RJ',
    'São Fidélis / RJ',
    'São Francisco de Itabapoana / RJ',
    'São Gonçalo / RJ',
    'São João da Barra / RJ',
    'São João de Meriti / RJ',
    'São José de Ubá / RJ',
    'São José do Vale do Rio Preto / RJ',
    'São Pedro da Aldeia / RJ',
    'São Sebastião do Alto / RJ',
    'Sapucaia / RJ',
    'Saquarema / RJ',
    'Seropédica / RJ',
    'Silva Jardim / RJ',
    'Sumidouro / RJ',
    'Tanguá / RJ',
    'Teresópolis / RJ',
    'Trajano de Morais / RJ',
    'Três Rios / RJ',
    'Valença / RJ',
    'Varre-sai / RJ',
    'Vassouras / RJ',
    'Volta Redonda / RJ',
    'Acari / RN',
    'Açu / RN',
    'Afonso Bezerra / RN',
    'Água Nova / RN',
    'Alexandria / RN',
    'Almino Afonso / RN',
    'Alto do Rodrigues / RN',
    'Angicos / RN',
    'Antônio Martins / RN',
    'Apodi / RN',
    'Areia Branca / RN',
    'Arês / RN',
    'Augusto Severo / RN',
    'Baía Formosa / RN',
    'Baraúna / RN',
    'Barcelona / RN',
    'Bento Fernandes / RN',
    'Bodó / RN',
    'Bom Jesus / RN',
    'Brejinho / RN',
    'Caiçara do Norte / RN',
    'Caiçara do Rio do Vento / RN',
    'Caicó / RN',
    'Campo Redondo / RN',
    'Canguaretama / RN',
    'Caraúbas / RN',
    'Carnaúba Dos Dantas / RN',
    'Carnaubais / RN',
    'Ceará-mirim / RN',
    'Cerro Corá / RN',
    'Coronel Ezequiel / RN',
    'Coronel João Pessoa / RN',
    'Cruzeta / RN',
    'Currais Novos / RN',
    'Doutor Severiano / RN',
    'Encanto / RN',
    'Equador / RN',
    'Espírito Santo / RN',
    'Extremoz / RN',
    'Felipe Guerra / RN',
    'Fernando Pedroza / RN',
    'Florânia / RN',
    'Francisco Dantas / RN',
    'Frutuoso Gomes / RN',
    'Galinhos / RN',
    'Goianinha / RN',
    'Governador Dix-sept Rosado / RN',
    'Grossos / RN',
    'Guamaré / RN',
    'Ielmo Marinho / RN',
    'Ipanguaçu / RN',
    'Ipueira / RN',
    'Itajá / RN',
    'Itaú / RN',
    'Jaçanã / RN',
    'Jandaíra / RN',
    'Janduís / RN',
    'Januário Cicco / RN',
    'Japi / RN',
    'Jardim de Angicos / RN',
    'Jardim de Piranhas / RN',
    'Jardim do Seridó / RN',
    'João Câmara / RN',
    'João Dias / RN',
    'José da Penha / RN',
    'Jucurutu / RN',
    'Jundiá / RN',
    'Lagoa D´anta / RN',
    'Lagoa de Pedras / RN',
    'Lagoa de Velhos / RN',
    'Lagoa Nova / RN',
    'Lagoa Salgada / RN',
    'Lajes / RN',
    'Lajes Pintadas / RN',
    'Lucrécia / RN',
    'Luís Gomes / RN',
    'Macaíba / RN',
    'Macau / RN',
    'Major Sales / RN',
    'Marcelino Vieira / RN',
    'Martins / RN',
    'Maxaranguape / RN',
    'Messias Targino / RN',
    'Montanhas / RN',
    'Monte Alegre / RN',
    'Monte Das Gameleiras / RN',
    'Mossoró / RN',
    'Natal / RN',
    'Nísia Floresta / RN',
    'Nova Cruz / RN',
    'Olho-d´água do Borges / RN',
    'Ouro Branco / RN',
    'Paraná / RN',
    'Paraú / RN',
    'Parazinho / RN',
    'Parelhas / RN',
    'Parnamirim / RN',
    'Passa e Fica / RN',
    'Passagem / RN',
    'Patu / RN',
    'Pau Dos Ferros / RN',
    'Pedra Grande / RN',
    'Pedra Preta / RN',
    'Pedro Avelino / RN',
    'Pedro Velho / RN',
    'Pendências / RN',
    'Pilões / RN',
    'Poço Branco / RN',
    'Portalegre / RN',
    'Porto do Mangue / RN',
    'Presidente Juscelino / RN',
    'Pureza / RN',
    'Rafael Fernandes / RN',
    'Rafael Godeiro / RN',
    'Riacho da Cruz / RN',
    'Riacho de Santana / RN',
    'Riachuelo / RN',
    'Rio do Fogo / RN',
    'Rodolfo Fernandes / RN',
    'Ruy Barbosa / RN',
    'Santa Cruz / RN',
    'Santa Maria / RN',
    'Santana do Matos / RN',
    'Santana do Seridó / RN',
    'Santo Antônio / RN',
    'São Bento do Norte / RN',
    'São Bento do Trairí / RN',
    'São Fernando / RN',
    'São Francisco do Oeste / RN',
    'São Gonçalo do Amarante / RN',
    'São João do Sabugi / RN',
    'São José de Mipibu / RN',
    'São José do Campestre / RN',
    'São José do Seridó / RN',
    'São Miguel / RN',
    'São Miguel do Gostoso / RN',
    'São Paulo do Potengi / RN',
    'São Pedro / RN',
    'São Rafael / RN',
    'São Tomé / RN',
    'São Vicente / RN',
    'Senador Elói de Souza / RN',
    'Senador Georgino Avelino / RN',
    'Serra de São Bento / RN',
    'Serra do Mel / RN',
    'Serra Negra do Norte / RN',
    'Serrinha / RN',
    'Serrinha Dos Pintos / RN',
    'Severiano Melo / RN',
    'Sítio Novo / RN',
    'Taboleiro Grande / RN',
    'Taipu / RN',
    'Tangará / RN',
    'Tenente Ananias / RN',
    'Tenente Laurentino Cruz / RN',
    'Tibau / RN',
    'Tibau do Sul / RN',
    'Timbaúba Dos Batistas / RN',
    'Touros / RN',
    'Triunfo Potiguar / RN',
    'Umarizal / RN',
    'Upanema / RN',
    'Várzea / RN',
    'Venha-ver / RN',
    'Vera Cruz / RN',
    'Viçosa / RN',
    'Vila Flor / RN',
    'Alta Floresta D´oeste / RO',
    'Alto Alegre Dos Parecis / RO',
    'Alto Paraíso / RO',
    'Alvorada D´oeste / RO',
    'Ariquemes / RO',
    'Buritis / RO',
    'Cabixi / RO',
    'Cacaulândia / RO',
    'Cacoal / RO',
    'Campo Novo de Rondônia / RO',
    'Candeias do Jamari / RO',
    'Castanheiras / RO',
    'Cerejeiras / RO',
    'Chupinguaia / RO',
    'Colorado do Oeste / RO',
    'Corumbiara / RO',
    'Costa Marques / RO',
    'Cujubim / RO',
    'Espigão D´oeste / RO',
    'Governador Jorge Teixeira / RO',
    'Guajará-mirim / RO',
    'Itapuã do Oeste / RO',
    'Jaru / RO',
    'Ji-paraná / RO',
    'Machadinho D´oeste / RO',
    'Ministro Andreazza / RO',
    'Mirante da Serra / RO',
    'Monte Negro / RO',
    'Nova Brasilândia D´oeste / RO',
    'Nova Mamoré / RO',
    'Nova União / RO',
    'Novo Horizonte do Oeste / RO',
    'Ouro Preto do Oeste / RO',
    'Parecis / RO',
    'Pimenta Bueno / RO',
    'Pimenteiras do Oeste / RO',
    'Porto Velho / RO',
    'Presidente Médici / RO',
    'Primavera de Rondônia / RO',
    'Rio Crespo / RO',
    'Rolim de Moura / RO',
    'Santa Luzia D´oeste / RO',
    'São Felipe D´oeste / RO',
    'São Francisco do Guaporé / RO',
    'São Miguel do Guaporé / RO',
    'Seringueiras / RO',
    'Teixeirópolis / RO',
    'Theobroma / RO',
    'Urupá / RO',
    'Vale do Anari / RO',
    'Vale do Paraíso / RO',
    'Vilhena / RO',
    'Alto Alegre / RR',
    'Amajari / RR',
    'Boa Vista / RR',
    'Bonfim / RR',
    'Cantá / RR',
    'Caracaraí / RR',
    'Caroebe / RR',
    'Iracema / RR',
    'Mucajaí / RR',
    'Normandia / RR',
    'Pacaraima / RR',
    'Rorainópolis / RR',
    'São João da Baliza / RR',
    'São Luiz / RR',
    'Uiramutã / RR',
    'Aceguá / RS',
    'Água Santa / RS',
    'Agudo / RS',
    'Ajuricaba / RS',
    'Alecrim / RS',
    'Alegrete / RS',
    'Alegria / RS',
    'Almirante Tamandaré do Sul / RS',
    'Alpestre / RS',
    'Alto Alegre / RS',
    'Alto Feliz / RS',
    'Alvorada / RS',
    'Amaral Ferrador / RS',
    'Ametista do Sul / RS',
    'André da Rocha / RS',
    'Anta Gorda / RS',
    'Antônio Prado / RS',
    'Arambaré / RS',
    'Araricá / RS',
    'Aratiba / RS',
    'Arroio do Meio / RS',
    'Arroio do Padre / RS',
    'Arroio do Sal / RS',
    'Arroio do Tigre / RS',
    'Arroio Dos Ratos / RS',
    'Arroio Grande / RS',
    'Arvorezinha / RS',
    'Augusto Pestana / RS',
    'Áurea / RS',
    'Bagé / RS',
    'Balneário Pinhal / RS',
    'Barão / RS',
    'Barão de Cotegipe / RS',
    'Barão do Triunfo / RS',
    'Barra do Guarita / RS',
    'Barra do Quaraí / RS',
    'Barra do Ribeiro / RS',
    'Barra do Rio Azul / RS',
    'Barra Funda / RS',
    'Barracão / RS',
    'Barros Cassal / RS',
    'Benjamin Constant do Sul / RS',
    'Bento Gonçalves / RS',
    'Boa Vista Das Missões / RS',
    'Boa Vista do Buricá / RS',
    'Boa Vista do Cadeado / RS',
    'Boa Vista do Incra / RS',
    'Boa Vista do Sul / RS',
    'Bom Jesus / RS',
    'Bom Princípio / RS',
    'Bom Progresso / RS',
    'Bom Retiro do Sul / RS',
    'Boqueirão do Leão / RS',
    'Bossoroca / RS',
    'Bozano / RS',
    'Braga / RS',
    'Brochier / RS',
    'Butiá / RS',
    'Caçapava do Sul / RS',
    'Cacequi / RS',
    'Cachoeira do Sul / RS',
    'Cachoeirinha / RS',
    'Cacique Doble / RS',
    'Caibaté / RS',
    'Caiçara / RS',
    'Camaquã / RS',
    'Camargo / RS',
    'Cambará do Sul / RS',
    'Campestre da Serra / RS',
    'Campina Das Missões / RS',
    'Campinas do Sul / RS',
    'Campo Bom / RS',
    'Campo Novo / RS',
    'Campos Borges / RS',
    'Candelária / RS',
    'Cândido Godói / RS',
    'Candiota / RS',
    'Canela / RS',
    'Canguçu / RS',
    'Canoas / RS',
    'Canudos do Vale / RS',
    'Capão Bonito do Sul / RS',
    'Capão da Canoa / RS',
    'Capão do Cipó / RS',
    'Capão do Leão / RS',
    'Capela de Santana / RS',
    'Capitão / RS',
    'Capivari do Sul / RS',
    'Caraá / RS',
    'Carazinho / RS',
    'Carlos Barbosa / RS',
    'Carlos Gomes / RS',
    'Casca / RS',
    'Caseiros / RS',
    'Catuípe / RS',
    'Caxias do Sul / RS',
    'Centenário / RS',
    'Cerrito / RS',
    'Cerro Branco / RS',
    'Cerro Grande / RS',
    'Cerro Grande do Sul / RS',
    'Cerro Largo / RS',
    'Chapada / RS',
    'Charqueadas / RS',
    'Charrua / RS',
    'Chiapetta / RS',
    'Chuí / RS',
    'Chuvisca / RS',
    'Cidreira / RS',
    'Ciríaco / RS',
    'Colinas / RS',
    'Colorado / RS',
    'Condor / RS',
    'Constantina / RS',
    'Coqueiro Baixo / RS',
    'Coqueiros do Sul / RS',
    'Coronel Barros / RS',
    'Coronel Bicaco / RS',
    'Coronel Pilar / RS',
    'Cotiporã / RS',
    'Coxilha / RS',
    'Crissiumal / RS',
    'Cristal / RS',
    'Cristal do Sul / RS',
    'Cruz Alta / RS',
    'Cruzaltense / RS',
    'Cruzeiro do Sul / RS',
    'David Canabarro / RS',
    'Derrubadas / RS',
    'Dezesseis de Novembro / RS',
    'Dilermando de Aguiar / RS',
    'Dois Irmãos / RS',
    'Dois Irmãos Das Missões / RS',
    'Dois Lajeados / RS',
    'Dom Feliciano / RS',
    'Dom Pedrito / RS',
    'Dom Pedro de Alcântara / RS',
    'Dona Francisca / RS',
    'Doutor Maurício Cardoso / RS',
    'Doutor Ricardo / RS',
    'Eldorado do Sul / RS',
    'Encantado / RS',
    'Encruzilhada do Sul / RS',
    'Engenho Velho / RS',
    'Entre Rios do Sul / RS',
    'Entre-ijuís / RS',
    'Erebango / RS',
    'Erechim / RS',
    'Ernestina / RS',
    'Erval Grande / RS',
    'Erval Seco / RS',
    'Esmeralda / RS',
    'Esperança do Sul / RS',
    'Espumoso / RS',
    'Estação / RS',
    'Estância Velha / RS',
    'Esteio / RS',
    'Estrela / RS',
    'Estrela Velha / RS',
    'Eugênio de Castro / RS',
    'Fagundes Varela / RS',
    'Farroupilha / RS',
    'Faxinal do Soturno / RS',
    'Faxinalzinho / RS',
    'Fazenda Vilanova / RS',
    'Feliz / RS',
    'Flores da Cunha / RS',
    'Floriano Peixoto / RS',
    'Fontoura Xavier / RS',
    'Formigueiro / RS',
    'Forquetinha / RS',
    'Fortaleza Dos Valos / RS',
    'Frederico Westphalen / RS',
    'Garibaldi / RS',
    'Garruchos / RS',
    'Gaurama / RS',
    'General Câmara / RS',
    'Gentil / RS',
    'Getúlio Vargas / RS',
    'Giruá / RS',
    'Glorinha / RS',
    'Gramado / RS',
    'Gramado Dos Loureiros / RS',
    'Gramado Xavier / RS',
    'Gravataí / RS',
    'Guabiju / RS',
    'Guaíba / RS',
    'Guaporé / RS',
    'Guarani Das Missões / RS',
    'Harmonia / RS',
    'Herval / RS',
    'Herveiras / RS',
    'Horizontina / RS',
    'Hulha Negra / RS',
    'Humaitá / RS',
    'Ibarama / RS',
    'Ibiaçá / RS',
    'Ibiraiaras / RS',
    'Ibirapuitã / RS',
    'Ibirubá / RS',
    'Igrejinha / RS',
    'Ijuí / RS',
    'Ilópolis / RS',
    'Imbé / RS',
    'Imigrante / RS',
    'Independência / RS',
    'Inhacorá / RS',
    'Ipê / RS',
    'Ipiranga do Sul / RS',
    'Iraí / RS',
    'Itaara / RS',
    'Itacurubi / RS',
    'Itapuca / RS',
    'Itaqui / RS',
    'Itati / RS',
    'Itatiba do Sul / RS',
    'Ivorá / RS',
    'Ivoti / RS',
    'Jaboticaba / RS',
    'Jacuizinho / RS',
    'Jacutinga / RS',
    'Jaguarão / RS',
    'Jaguari / RS',
    'Jaquirana / RS',
    'Jari / RS',
    'Jóia / RS',
    'Júlio de Castilhos / RS',
    'Lagoa Bonita do Sul / RS',
    'Lagoa Dos Três Cantos / RS',
    'Lagoa Vermelha / RS',
    'Lagoão / RS',
    'Lajeado / RS',
    'Lajeado do Bugre / RS',
    'Lavras do Sul / RS',
    'Liberato Salzano / RS',
    'Lindolfo Collor / RS',
    'Linha Nova / RS',
    'Maçambara / RS',
    'Machadinho / RS',
    'Mampituba / RS',
    'Manoel Viana / RS',
    'Maquiné / RS',
    'Maratá / RS',
    'Marau / RS',
    'Marcelino Ramos / RS',
    'Mariana Pimentel / RS',
    'Mariano Moro / RS',
    'Marques de Souza / RS',
    'Mata / RS',
    'Mato Castelhano / RS',
    'Mato Leitão / RS',
    'Mato Queimado / RS',
    'Maximiliano de Almeida / RS',
    'Minas do Leão / RS',
    'Miraguaí / RS',
    'Montauri / RS',
    'Monte Alegre Dos Campos / RS',
    'Monte Belo do Sul / RS',
    'Montenegro / RS',
    'Mormaço / RS',
    'Morrinhos do Sul / RS',
    'Morro Redondo / RS',
    'Morro Reuter / RS',
    'Mostardas / RS',
    'Muçum / RS',
    'Muitos Capões / RS',
    'Muliterno / RS',
    'Não-me-toque / RS',
    'Nicolau Vergueiro / RS',
    'Nonoai / RS',
    'Nova Alvorada / RS',
    'Nova Araçá / RS',
    'Nova Bassano / RS',
    'Nova Boa Vista / RS',
    'Nova Bréscia / RS',
    'Nova Candelária / RS',
    'Nova Esperança do Sul / RS',
    'Nova Hartz / RS',
    'Nova Pádua / RS',
    'Nova Palma / RS',
    'Nova Petrópolis / RS',
    'Nova Prata / RS',
    'Nova Ramada / RS',
    'Nova Roma do Sul / RS',
    'Nova Santa Rita / RS',
    'Novo Barreiro / RS',
    'Novo Cabrais / RS',
    'Novo Hamburgo / RS',
    'Novo Machado / RS',
    'Novo Tiradentes / RS',
    'Novo Xingu / RS',
    'Osório / RS',
    'Paim Filho / RS',
    'Palmares do Sul / RS',
    'Palmeira Das Missões / RS',
    'Palmitinho / RS',
    'Panambi / RS',
    'Pantano Grande / RS',
    'Paraí / RS',
    'Paraíso do Sul / RS',
    'Pareci Novo / RS',
    'Parobé / RS',
    'Passa Sete / RS',
    'Passo do Sobrado / RS',
    'Passo Fundo / RS',
    'Paulo Bento / RS',
    'Paverama / RS',
    'Pedras Altas / RS',
    'Pedro Osório / RS',
    'Pejuçara / RS',
    'Pelotas / RS',
    'Picada Café / RS',
    'Pinhal / RS',
    'Pinhal da Serra / RS',
    'Pinhal Grande / RS',
    'Pinheirinho do Vale / RS',
    'Pinheiro Machado / RS',
    'Pinto Bandeira / RS',
    'Pirapó / RS',
    'Piratini / RS',
    'Planalto / RS',
    'Poço Das Antas / RS',
    'Pontão / RS',
    'Ponte Preta / RS',
    'Portão / RS',
    'Porto Alegre / RS',
    'Porto Lucena / RS',
    'Porto Mauá / RS',
    'Porto Vera Cruz / RS',
    'Porto Xavier / RS',
    'Pouso Novo / RS',
    'Presidente Lucena / RS',
    'Progresso / RS',
    'Protásio Alves / RS',
    'Putinga / RS',
    'Quaraí / RS',
    'Quatro Irmãos / RS',
    'Quevedos / RS',
    'Quinze de Novembro / RS',
    'Redentora / RS',
    'Relvado / RS',
    'Restinga Seca / RS',
    'Rio Dos Índios / RS',
    'Rio Grande / RS',
    'Rio Pardo / RS',
    'Riozinho / RS',
    'Roca Sales / RS',
    'Rodeio Bonito / RS',
    'Rolador / RS',
    'Rolante / RS',
    'Ronda Alta / RS',
    'Rondinha / RS',
    'Roque Gonzales / RS',
    'Rosário do Sul / RS',
    'Sagrada Família / RS',
    'Saldanha Marinho / RS',
    'Salto do Jacuí / RS',
    'Salvador Das Missões / RS',
    'Salvador do Sul / RS',
    'Sananduva / RS',
    'Santa Bárbara do Sul / RS',
    'Santa Cecília do Sul / RS',
    'Santa Clara do Sul / RS',
    'Santa Cruz do Sul / RS',
    'Santa Margarida do Sul / RS',
    'Santa Maria / RS',
    'Santa Maria do Herval / RS',
    'Santa Rosa / RS',
    'Santa Tereza / RS',
    'Santa Vitória do Palmar / RS',
    'Santana da Boa Vista / RS',
    'Santana do Livramento / RS',
    'Santiago / RS',
    'Santo Ângelo / RS',
    'Santo Antônio da Patrulha / RS',
    'Santo Antônio Das Missões / RS',
    'Santo Antônio do Palma / RS',
    'Santo Antônio do Planalto / RS',
    'Santo Augusto / RS',
    'Santo Cristo / RS',
    'Santo Expedito do Sul / RS',
    'São Borja / RS',
    'São Domingos do Sul / RS',
    'São Francisco de Assis / RS',
    'São Francisco de Paula / RS',
    'São Gabriel / RS',
    'São Jerônimo / RS',
    'São João da Urtiga / RS',
    'São João do Polêsine / RS',
    'São Jorge / RS',
    'São José Das Missões / RS',
    'São José do Herval / RS',
    'São José do Hortêncio / RS',
    'São José do Inhacorá / RS',
    'São José do Norte / RS',
    'São José do Ouro / RS',
    'São José do Sul / RS',
    'São José Dos Ausentes / RS',
    'São Leopoldo / RS',
    'São Lourenço do Sul / RS',
    'São Luiz Gonzaga / RS',
    'São Marcos / RS',
    'São Martinho / RS',
    'São Martinho da Serra / RS',
    'São Miguel Das Missões / RS',
    'São Nicolau / RS',
    'São Paulo Das Missões / RS',
    'São Pedro da Serra / RS',
    'São Pedro Das Missões / RS',
    'São Pedro do Butiá / RS',
    'São Pedro do Sul / RS',
    'São Sebastião do Caí / RS',
    'São Sepé / RS',
    'São Valentim / RS',
    'São Valentim do Sul / RS',
    'São Valério do Sul / RS',
    'São Vendelino / RS',
    'São Vicente do Sul / RS',
    'Sapiranga / RS',
    'Sapucaia do Sul / RS',
    'Sarandi / RS',
    'Seberi / RS',
    'Sede Nova / RS',
    'Segredo / RS',
    'Selbach / RS',
    'Senador Salgado Filho / RS',
    'Sentinela do Sul / RS',
    'Serafina Corrêa / RS',
    'Sério / RS',
    'Sertão / RS',
    'Sertão Santana / RS',
    'Sete de Setembro / RS',
    'Severiano de Almeida / RS',
    'Silveira Martins / RS',
    'Sinimbu / RS',
    'Sobradinho / RS',
    'Soledade / RS',
    'Tabaí / RS',
    'Tapejara / RS',
    'Tapera / RS',
    'Tapes / RS',
    'Taquara / RS',
    'Taquari / RS',
    'Taquaruçu do Sul / RS',
    'Tavares / RS',
    'Tenente Portela / RS',
    'Terra de Areia / RS',
    'Teutônia / RS',
    'Tio Hugo / RS',
    'Tiradentes do Sul / RS',
    'Toropi / RS',
    'Torres / RS',
    'Tramandaí / RS',
    'Travesseiro / RS',
    'Três Arroios / RS',
    'Três Cachoeiras / RS',
    'Três Coroas / RS',
    'Três de Maio / RS',
    'Três Forquilhas / RS',
    'Três Palmeiras / RS',
    'Três Passos / RS',
    'Trindade do Sul / RS',
    'Triunfo / RS',
    'Tucunduva / RS',
    'Tunas / RS',
    'Tupanci do Sul / RS',
    'Tupanciretã / RS',
    'Tupandi / RS',
    'Tuparendi / RS',
    'Turuçu / RS',
    'Ubiretama / RS',
    'União da Serra / RS',
    'Unistalda / RS',
    'Uruguaiana / RS',
    'Vacaria / RS',
    'Vale do Sol / RS',
    'Vale Real / RS',
    'Vale Verde / RS',
    'Vanini / RS',
    'Venâncio Aires / RS',
    'Vera Cruz / RS',
    'Veranópolis / RS',
    'Vespasiano Correa / RS',
    'Viadutos / RS',
    'Viamão / RS',
    'Vicente Dutra / RS',
    'Victor Graeff / RS',
    'Vila Flores / RS',
    'Vila Lângaro / RS',
    'Vila Maria / RS',
    'Vila Nova do Sul / RS',
    'Vista Alegre / RS',
    'Vista Alegre do Prata / RS',
    'Vista Gaúcha / RS',
    'Vitória Das Missões / RS',
    'Westfalia / RS',
    'Xangri-lá / RS',
    'Abdon Batista / SC',
    'Abelardo Luz / SC',
    'Agrolândia / SC',
    'Agronômica / SC',
    'Água Doce / SC',
    'Águas de Chapecó / SC',
    'Águas Frias / SC',
    'Águas Mornas / SC',
    'Alfredo Wagner / SC',
    'Alto Bela Vista / SC',
    'Anchieta / SC',
    'Angelina / SC',
    'Anita Garibaldi / SC',
    'Anitápolis / SC',
    'Antônio Carlos / SC',
    'Apiúna / SC',
    'Arabutã / SC',
    'Araquari / SC',
    'Araranguá / SC',
    'Armazém / SC',
    'Arroio Trinta / SC',
    'Arvoredo / SC',
    'Ascurra / SC',
    'Atalanta / SC',
    'Aurora / SC',
    'Balneário Arroio do Silva / SC',
    'Balneário Barra do Sul / SC',
    'Balneário Camboriú / SC',
    'Balneário Gaivota / SC',
    'Balneário Rincão / SC',
    'Bandeirante / SC',
    'Barra Bonita / SC',
    'Barra Velha / SC',
    'Bela Vista do Toldo / SC',
    'Belmonte / SC',
    'Benedito Novo / SC',
    'Biguaçu / SC',
    'Blumenau / SC',
    'Bocaina do Sul / SC',
    'Bom Jardim da Serra / SC',
    'Bom Jesus / SC',
    'Bom Jesus do Oeste / SC',
    'Bom Retiro / SC',
    'Bombinhas / SC',
    'Botuverá / SC',
    'Braço do Norte / SC',
    'Braço do Trombudo / SC',
    'Brunópolis / SC',
    'Brusque / SC',
    'Caçador / SC',
    'Caibi / SC',
    'Calmon / SC',
    'Camboriú / SC',
    'Campo Alegre / SC',
    'Campo Belo do Sul / SC',
    'Campo Erê / SC',
    'Campos Novos / SC',
    'Canelinha / SC',
    'Canoinhas / SC',
    'Capão Alto / SC',
    'Capinzal / SC',
    'Capivari de Baixo / SC',
    'Catanduvas / SC',
    'Caxambu do Sul / SC',
    'Celso Ramos / SC',
    'Cerro Negro / SC',
    'Chapadão do Lageado / SC',
    'Chapecó / SC',
    'Cocal do Sul / SC',
    'Concórdia / SC',
    'Cordilheira Alta / SC',
    'Coronel Freitas / SC',
    'Coronel Martins / SC',
    'Correia Pinto / SC',
    'Corupá / SC',
    'Criciúma / SC',
    'Cunha Porã / SC',
    'Cunhataí / SC',
    'Curitibanos / SC',
    'Descanso / SC',
    'Dionísio Cerqueira / SC',
    'Dona Emma / SC',
    'Doutor Pedrinho / SC',
    'Entre Rios / SC',
    'Ermo / SC',
    'Erval Velho / SC',
    'Faxinal Dos Guedes / SC',
    'Flor do Sertão / SC',
    'Florianópolis / SC',
    'Formosa do Sul / SC',
    'Forquilhinha / SC',
    'Fraiburgo / SC',
    'Frei Rogério / SC',
    'Galvão / SC',
    'Garopaba / SC',
    'Garuva / SC',
    'Gaspar / SC',
    'Governador Celso Ramos / SC',
    'Grão Pará / SC',
    'Gravatal / SC',
    'Guabiruba / SC',
    'Guaraciaba / SC',
    'Guaramirim / SC',
    'Guarujá do Sul / SC',
    'Guatambú / SC',
    'Herval D´oeste / SC',
    'Ibiam / SC',
    'Ibicaré / SC',
    'Ibirama / SC',
    'Içara / SC',
    'Ilhota / SC',
    'Imaruí / SC',
    'Imbituba / SC',
    'Imbuia / SC',
    'Indaial / SC',
    'Iomerê / SC',
    'Ipira / SC',
    'Iporã do Oeste / SC',
    'Ipuaçu / SC',
    'Ipumirim / SC',
    'Iraceminha / SC',
    'Irani / SC',
    'Irati / SC',
    'Irineópolis / SC',
    'Itá / SC',
    'Itaiópolis / SC',
    'Itajaí / SC',
    'Itapema / SC',
    'Itapiranga / SC',
    'Itapoá / SC',
    'Ituporanga / SC',
    'Jaborá / SC',
    'Jacinto Machado / SC',
    'Jaguaruna / SC',
    'Jaraguá do Sul / SC',
    'Jardinópolis / SC',
    'Joaçaba / SC',
    'Joinville / SC',
    'José Boiteux / SC',
    'Jupiá / SC',
    'Lacerdópolis / SC',
    'Lages / SC',
    'Laguna / SC',
    'Lajeado Grande / SC',
    'Laurentino / SC',
    'Lauro Muller / SC',
    'Lebon Régis / SC',
    'Leoberto Leal / SC',
    'Lindóia do Sul / SC',
    'Lontras / SC',
    'Luiz Alves / SC',
    'Luzerna / SC',
    'Macieira / SC',
    'Mafra / SC',
    'Major Gercino / SC',
    'Major Vieira / SC',
    'Maracajá / SC',
    'Maravilha / SC',
    'Marema / SC',
    'Massaranduba / SC',
    'Matos Costa / SC',
    'Meleiro / SC',
    'Mirim Doce / SC',
    'Modelo / SC',
    'Mondaí / SC',
    'Monte Carlo / SC',
    'Monte Castelo / SC',
    'Morro da Fumaça / SC',
    'Morro Grande / SC',
    'Navegantes / SC',
    'Nova Erechim / SC',
    'Nova Itaberaba / SC',
    'Nova Trento / SC',
    'Nova Veneza / SC',
    'Novo Horizonte / SC',
    'Orleans / SC',
    'Otacílio Costa / SC',
    'Ouro / SC',
    'Ouro Verde / SC',
    'Paial / SC',
    'Painel / SC',
    'Palhoça / SC',
    'Palma Sola / SC',
    'Palmeira / SC',
    'Palmitos / SC',
    'Papanduva / SC',
    'Paraíso / SC',
    'Passo de Torres / SC',
    'Passos Maia / SC',
    'Paulo Lopes / SC',
    'Pedras Grandes / SC',
    'Penha / SC',
    'Peritiba / SC',
    'Pescaria Brava / SC',
    'Petrolândia / SC',
    'Piçarras / SC',
    'Pinhalzinho / SC',
    'Pinheiro Preto / SC',
    'Piratuba / SC',
    'Planalto Alegre / SC',
    'Pomerode / SC',
    'Ponte Alta / SC',
    'Ponte Alta do Norte / SC',
    'Ponte Serrada / SC',
    'Porto Belo / SC',
    'Porto União / SC',
    'Pouso Redondo / SC',
    'Praia Grande / SC',
    'Presidente Castelo Branco / SC',
    'Presidente Getúlio / SC',
    'Presidente Nereu / SC',
    'Princesa / SC',
    'Quilombo / SC',
    'Rancho Queimado / SC',
    'Rio Das Antas / SC',
    'Rio do Campo / SC',
    'Rio do Oeste / SC',
    'Rio do Sul / SC',
    'Rio Dos Cedros / SC',
    'Rio Fortuna / SC',
    'Rio Negrinho / SC',
    'Rio Rufino / SC',
    'Riqueza / SC',
    'Rodeio / SC',
    'Romelândia / SC',
    'Salete / SC',
    'Saltinho / SC',
    'Salto Veloso / SC',
    'Sangão / SC',
    'Santa Cecília / SC',
    'Santa Helena / SC',
    'Santa Rosa de Lima / SC',
    'Santa Rosa do Sul / SC',
    'Santa Terezinha / SC',
    'Santa Terezinha do Progresso / SC',
    'Santiago do Sul / SC',
    'Santo Amaro da Imperatriz / SC',
    'São Bento do Sul / SC',
    'São Bernardino / SC',
    'São Bonifácio / SC',
    'São Carlos / SC',
    'São Cristovão do Sul / SC',
    'São Domingos / SC',
    'São Francisco do Sul / SC',
    'São João Batista / SC',
    'São João do Itaperiú / SC',
    'São João do Oeste / SC',
    'São João do Sul / SC',
    'São Joaquim / SC',
    'São José / SC',
    'São José do Cedro / SC',
    'São José do Cerrito / SC',
    'São Lourenço do Oeste / SC',
    'São Ludgero / SC',
    'São Martinho / SC',
    'São Miguel da Boa Vista / SC',
    'São Miguel do Oeste / SC',
    'São Pedro de Alcântara / SC',
    'Saudades / SC',
    'Schroeder / SC',
    'Seara / SC',
    'Serra Alta / SC',
    'Siderópolis / SC',
    'Sombrio / SC',
    'Sul Brasil / SC',
    'Taió / SC',
    'Tangará / SC',
    'Tigrinhos / SC',
    'Tijucas / SC',
    'Timbé do Sul / SC',
    'Timbó / SC',
    'Timbó Grande / SC',
    'Três Barras / SC',
    'Treviso / SC',
    'Treze de Maio / SC',
    'Treze Tílias / SC',
    'Trombudo Central / SC',
    'Tubarão / SC',
    'Tunápolis / SC',
    'Turvo / SC',
    'União do Oeste / SC',
    'Urubici / SC',
    'Urupema / SC',
    'Urussanga / SC',
    'Vargeão / SC',
    'Vargem / SC',
    'Vargem Bonita / SC',
    'Vidal Ramos / SC',
    'Videira / SC',
    'Vitor Meireles / SC',
    'Witmarsum / SC',
    'Xanxerê / SC',
    'Xavantina / SC',
    'Xaxim / SC',
    'Zortéa / SC',
    'Amparo de São Francisco / SE',
    'Aquidabã / SE',
    'Aracaju / SE',
    'Arauá / SE',
    'Areia Branca / SE',
    'Barra Dos Coqueiros / SE',
    'Boquim / SE',
    'Brejo Grande / SE',
    'Campo do Brito / SE',
    'Canhoba / SE',
    'Canindé de São Francisco / SE',
    'Capela / SE',
    'Carira / SE',
    'Carmópolis / SE',
    'Cedro de São João / SE',
    'Cristinápolis / SE',
    'Cumbe / SE',
    'Divina Pastora / SE',
    'Estância / SE',
    'Feira Nova / SE',
    'Frei Paulo / SE',
    'Gararu / SE',
    'General Maynard / SE',
    'Gracho Cardoso / SE',
    'Ilha Das Flores / SE',
    'Indiaroba / SE',
    'Itabaiana / SE',
    'Itabaianinha / SE',
    'Itabi / SE',
    'Itaporanga D´ajuda / SE',
    'Japaratuba / SE',
    'Japoatã / SE',
    'Lagarto / SE',
    'Laranjeiras / SE',
    'Macambira / SE',
    'Malhada Dos Bois / SE',
    'Malhador / SE',
    'Maruim / SE',
    'Moita Bonita / SE',
    'Monte Alegre de Sergipe / SE',
    'Muribeca / SE',
    'Neópolis / SE',
    'Nossa Senhora Aparecida / SE',
    'Nossa Senhora da Glória / SE',
    'Nossa Senhora Das Dores / SE',
    'Nossa Senhora de Lourdes / SE',
    'Nossa Senhora do Socorro / SE',
    'Pacatuba / SE',
    'Pedra Mole / SE',
    'Pedrinhas / SE',
    'Pinhão / SE',
    'Pirambu / SE',
    'Poço Redondo / SE',
    'Poço Verde / SE',
    'Porto da Folha / SE',
    'Propriá / SE',
    'Riachão do Dantas / SE',
    'Riachuelo / SE',
    'Ribeirópolis / SE',
    'Rosário do Catete / SE',
    'Salgado / SE',
    'Santa Luzia do Itanhy / SE',
    'Santa Rosa de Lima / SE',
    'Santana do São Francisco / SE',
    'Santo Amaro Das Brotas / SE',
    'São Cristóvão / SE',
    'São Domingos / SE',
    'São Francisco / SE',
    'São Miguel do Aleixo / SE',
    'Simão Dias / SE',
    'Siriri / SE',
    'Telha / SE',
    'Tobias Barreto / SE',
    'Tomar do Geru / SE',
    'Umbaúba / SE',
    'Adamantina / SP',
    'Adolfo / SP',
    'Aguaí / SP',
    'Águas da Prata / SP',
    'Águas de Lindóia / SP',
    'Águas de Santa Bárbara / SP',
    'Águas de São Pedro / SP',
    'Agudos / SP',
    'Alambari / SP',
    'Alfredo Marcondes / SP',
    'Altair / SP',
    'Altinópolis / SP',
    'Alto Alegre / SP',
    'Alumínio / SP',
    'Álvares Florence / SP',
    'Álvares Machado / SP',
    'Álvaro de Carvalho / SP',
    'Alvinlândia / SP',
    'Americana / SP',
    'Américo Brasiliense / SP',
    'Américo de Campos / SP',
    'Amparo / SP',
    'Analândia / SP',
    'Andradina / SP',
    'Angatuba / SP',
    'Anhembi / SP',
    'Anhumas / SP',
    'Aparecida / SP',
    'Aparecida D´oeste / SP',
    'Apiaí / SP',
    'Araçariguama / SP',
    'Araçatuba / SP',
    'Araçoiaba da Serra / SP',
    'Aramina / SP',
    'Arandu / SP',
    'Arapeí / SP',
    'Araraquara / SP',
    'Araras / SP',
    'Arco-íris / SP',
    'Arealva / SP',
    'Areias / SP',
    'Areiópolis / SP',
    'Ariranha / SP',
    'Artur Nogueira / SP',
    'Arujá / SP',
    'Aspásia / SP',
    'Assis / SP',
    'Atibaia / SP',
    'Auriflama / SP',
    'Avaí / SP',
    'Avanhandava / SP',
    'Avaré / SP',
    'Bady Bassitt / SP',
    'Balbinos / SP',
    'Bálsamo / SP',
    'Bananal / SP',
    'Barão de Antonina / SP',
    'Barbosa / SP',
    'Bariri / SP',
    'Barra Bonita / SP',
    'Barra do Chapéu / SP',
    'Barra do Turvo / SP',
    'Barretos / SP',
    'Barrinha / SP',
    'Barueri / SP',
    'Bastos / SP',
    'Batatais / SP',
    'Bauru / SP',
    'Bebedouro / SP',
    'Bento de Abreu / SP',
    'Bernardino de Campos / SP',
    'Bertioga / SP',
    'Bilac / SP',
    'Birigui / SP',
    'Biritiba-mirim / SP',
    'Boa Esperança do Sul / SP',
    'Bocaina / SP',
    'Bofete / SP',
    'Boituva / SP',
    'Bom Jesus Dos Perdões / SP',
    'Bom Sucesso de Itararé / SP',
    'Borá / SP',
    'Boracéia / SP',
    'Borborema / SP',
    'Borebi / SP',
    'Botucatu / SP',
    'Bragança Paulista / SP',
    'Braúna / SP',
    'Brejo Alegre / SP',
    'Brodowski / SP',
    'Brotas / SP',
    'Buri / SP',
    'Buritama / SP',
    'Buritizal / SP',
    'Cabrália Paulista / SP',
    'Cabreúva / SP',
    'Caçapava / SP',
    'Cachoeira Paulista / SP',
    'Caconde / SP',
    'Cafelândia / SP',
    'Caiabu / SP',
    'Caieiras / SP',
    'Caiuá / SP',
    'Cajamar / SP',
    'Cajati / SP',
    'Cajobi / SP',
    'Cajuru / SP',
    'Campina do Monte Alegre / SP',
    'Campinas / SP',
    'Campo Limpo Paulista / SP',
    'Campos do Jordão / SP',
    'Campos Novos Paulista / SP',
    'Cananéia / SP',
    'Canas / SP',
    'Cândido Mota / SP',
    'Cândido Rodrigues / SP',
    'Canitar / SP',
    'Capão Bonito / SP',
    'Capela do Alto / SP',
    'Capivari / SP',
    'Caraguatatuba / SP',
    'Carapicuíba / SP',
    'Cardoso / SP',
    'Casa Branca / SP',
    'Cássia Dos Coqueiros / SP',
    'Castilho / SP',
    'Catanduva / SP',
    'Catiguá / SP',
    'Cedral / SP',
    'Cerqueira César / SP',
    'Cerquilho / SP',
    'Cesário Lange / SP',
    'Charqueada / SP',
    'Chavantes / SP',
    'Clementina / SP',
    'Colina / SP',
    'Colômbia / SP',
    'Conchal / SP',
    'Conchas / SP',
    'Cordeirópolis / SP',
    'Coroados / SP',
    'Coronel Macedo / SP',
    'Corumbataí / SP',
    'Cosmópolis / SP',
    'Cosmorama / SP',
    'Cotia / SP',
    'Cravinhos / SP',
    'Cristais Paulista / SP',
    'Cruzália / SP',
    'Cruzeiro / SP',
    'Cubatão / SP',
    'Cunha / SP',
    'Descalvado / SP',
    'Diadema / SP',
    'Dirce Reis / SP',
    'Divinolândia / SP',
    'Dobrada / SP',
    'Dois Córregos / SP',
    'Dolcinópolis / SP',
    'Dourado / SP',
    'Dracena / SP',
    'Duartina / SP',
    'Dumont / SP',
    'Echaporã / SP',
    'Eldorado / SP',
    'Elias Fausto / SP',
    'Elisiário / SP',
    'Embaúba / SP',
    'Embu / SP',
    'Embu-guaçu / SP',
    'Emilianópolis / SP',
    'Engenheiro Coelho / SP',
    'Espírito Santo do Pinhal / SP',
    'Espírito Santo do Turvo / SP',
    'Estiva Gerbi / SP',
    'Estrela D´oeste / SP',
    'Estrela do Norte / SP',
    'Euclides da Cunha Paulista / SP',
    'Fartura / SP',
    'Fernando Prestes / SP',
    'Fernandópolis / SP',
    'Fernão / SP',
    'Ferraz de Vasconcelos / SP',
    'Flora Rica / SP',
    'Floreal / SP',
    'Flórida Paulista / SP',
    'Florínia / SP',
    'Franca / SP',
    'Francisco Morato / SP',
    'Franco da Rocha / SP',
    'Gabriel Monteiro / SP',
    'Gália / SP',
    'Garça / SP',
    'Gastão Vidigal / SP',
    'Gavião Peixoto / SP',
    'General Salgado / SP',
    'Getulina / SP',
    'Glicério / SP',
    'Guaiçara / SP',
    'Guaimbê / SP',
    'Guaíra / SP',
    'Guapiaçu / SP',
    'Guapiara / SP',
    'Guará / SP',
    'Guaraçaí / SP',
    'Guaraci / SP',
    'Guarani D´oeste / SP',
    'Guarantã / SP',
    'Guararapes / SP',
    'Guararema / SP',
    'Guaratinguetá / SP',
    'Guareí / SP',
    'Guariba / SP',
    'Guarujá / SP',
    'Guarulhos / SP',
    'Guatapará / SP',
    'Guzolândia / SP',
    'Herculândia / SP',
    'Holambra / SP',
    'Hortolândia / SP',
    'Iacanga / SP',
    'Iacri / SP',
    'Iaras / SP',
    'Ibaté / SP',
    'Ibirá / SP',
    'Ibirarema / SP',
    'Ibitinga / SP',
    'Ibiúna / SP',
    'Icém / SP',
    'Iepê / SP',
    'Igaraçu do Tietê / SP',
    'Igarapava / SP',
    'Igaratá / SP',
    'Iguape / SP',
    'Ilha Comprida / SP',
    'Ilha Solteira / SP',
    'Ilhabela / SP',
    'Indaiatuba / SP',
    'Indiana / SP',
    'Indiaporã / SP',
    'Inúbia Paulista / SP',
    'Ipaussu / SP',
    'Iperó / SP',
    'Ipeúna / SP',
    'Ipiguá / SP',
    'Iporanga / SP',
    'Ipuã / SP',
    'Iracemápolis / SP',
    'Irapuã / SP',
    'Irapuru / SP',
    'Itaberá / SP',
    'Itaí / SP',
    'Itajobi / SP',
    'Itaju / SP',
    'Itanhaém / SP',
    'Itaóca / SP',
    'Itapecerica da Serra / SP',
    'Itapetininga / SP',
    'Itapeva / SP',
    'Itapevi / SP',
    'Itapira / SP',
    'Itapirapuã Paulista / SP',
    'Itápolis / SP',
    'Itaporanga / SP',
    'Itapuí / SP',
    'Itapura / SP',
    'Itaquaquecetuba / SP',
    'Itararé / SP',
    'Itariri / SP',
    'Itatiba / SP',
    'Itatinga / SP',
    'Itirapina / SP',
    'Itirapuã / SP',
    'Itobi / SP',
    'Itu / SP',
    'Itupeva / SP',
    'Ituverava / SP',
    'Jaborandi / SP',
    'Jaboticabal / SP',
    'Jacareí / SP',
    'Jaci / SP',
    'Jacupiranga / SP',
    'Jaguariúna / SP',
    'Jales / SP',
    'Jambeiro / SP',
    'Jandira / SP',
    'Jardinópolis / SP',
    'Jarinu / SP',
    'Jaú / SP',
    'Jeriquara / SP',
    'Joanópolis / SP',
    'João Ramalho / SP',
    'José Bonifácio / SP',
    'Júlio Mesquita / SP',
    'Jumirim / SP',
    'Jundiaí / SP',
    'Junqueirópolis / SP',
    'Juquiá / SP',
    'Juquitiba / SP',
    'Lagoinha / SP',
    'Laranjal Paulista / SP',
    'Lavínia / SP',
    'Lavrinhas / SP',
    'Leme / SP',
    'Lençóis Paulista / SP',
    'Limeira / SP',
    'Lindóia / SP',
    'Lins / SP',
    'Lorena / SP',
    'Lourdes / SP',
    'Louveira / SP',
    'Lucélia / SP',
    'Lucianópolis / SP',
    'Luís Antônio / SP',
    'Luiziânia / SP',
    'Lupércio / SP',
    'Lutécia / SP',
    'Macatuba / SP',
    'Macaubal / SP',
    'Macedônia / SP',
    'Magda / SP',
    'Mairinque / SP',
    'Mairiporã / SP',
    'Manduri / SP',
    'Marabá Paulista / SP',
    'Maracaí / SP',
    'Marapoama / SP',
    'Mariápolis / SP',
    'Marília / SP',
    'Marinópolis / SP',
    'Martinópolis / SP',
    'Matão / SP',
    'Mauá / SP',
    'Mendonça / SP',
    'Meridiano / SP',
    'Mesópolis / SP',
    'Miguelópolis / SP',
    'Mineiros do Tietê / SP',
    'Mira Estrela / SP',
    'Miracatu / SP',
    'Mirandópolis / SP',
    'Mirante do Paranapanema / SP',
    'Mirassol / SP',
    'Mirassolândia / SP',
    'Mococa / SP',
    'Mogi Guaçu / SP',
    'Moji Das Cruzes / SP',
    'Moji-mirim / SP',
    'Mombuca / SP',
    'Monções / SP',
    'Mongaguá / SP',
    'Monte Alegre do Sul / SP',
    'Monte Alto / SP',
    'Monte Aprazível / SP',
    'Monte Azul Paulista / SP',
    'Monte Castelo / SP',
    'Monte Mor / SP',
    'Monteiro Lobato / SP',
    'Morro Agudo / SP',
    'Morungaba / SP',
    'Motuca / SP',
    'Murutinga do Sul / SP',
    'Nantes / SP',
    'Narandiba / SP',
    'Natividade da Serra / SP',
    'Nazaré Paulista / SP',
    'Neves Paulista / SP',
    'Nhandeara / SP',
    'Nipoã / SP',
    'Nova Aliança / SP',
    'Nova Campina / SP',
    'Nova Canaã Paulista / SP',
    'Nova Castilho / SP',
    'Nova Europa / SP',
    'Nova Granada / SP',
    'Nova Guataporanga / SP',
    'Nova Independência / SP',
    'Nova Luzitânia / SP',
    'Nova Odessa / SP',
    'Novais / SP',
    'Novo Horizonte / SP',
    'Nuporanga / SP',
    'Ocauçu / SP',
    'Óleo / SP',
    'Olímpia / SP',
    'Onda Verde / SP',
    'Oriente / SP',
    'Orindiúva / SP',
    'Orlândia / SP',
    'Osasco / SP',
    'Oscar Bressane / SP',
    'Osvaldo Cruz / SP',
    'Ourinhos / SP',
    'Ouro Verde / SP',
    'Ouroeste / SP',
    'Pacaembu / SP',
    'Palestina / SP',
    'Palmares Paulista / SP',
    'Palmeira D´oeste / SP',
    'Palmital / SP',
    'Panorama / SP',
    'Paraguaçu Paulista / SP',
    'Paraibuna / SP',
    'Paraíso / SP',
    'Paranapanema / SP',
    'Paranapuã / SP',
    'Parapuã / SP',
    'Pardinho / SP',
    'Pariquera-açu / SP',
    'Parisi / SP',
    'Patrocínio Paulista / SP',
    'Paulicéia / SP',
    'Paulínia / SP',
    'Paulistânia / SP',
    'Paulo de Faria / SP',
    'Pederneiras / SP',
    'Pedra Bela / SP',
    'Pedranópolis / SP',
    'Pedregulho / SP',
    'Pedreira / SP',
    'Pedrinhas Paulista / SP',
    'Pedro de Toledo / SP',
    'Penápolis / SP',
    'Pereira Barreto / SP',
    'Pereiras / SP',
    'Peruíbe / SP',
    'Piacatu / SP',
    'Piedade / SP',
    'Pilar do Sul / SP',
    'Pindamonhangaba / SP',
    'Pindorama / SP',
    'Pinhalzinho / SP',
    'Piquerobi / SP',
    'Piquete / SP',
    'Piracaia / SP',
    'Piracicaba / SP',
    'Piraju / SP',
    'Pirajuí / SP',
    'Pirangi / SP',
    'Pirapora do Bom Jesus / SP',
    'Pirapozinho / SP',
    'Pirassununga / SP',
    'Piratininga / SP',
    'Pitangueiras / SP',
    'Planalto / SP',
    'Platina / SP',
    'Poá / SP',
    'Poloni / SP',
    'Pompéia / SP',
    'Pongaí / SP',
    'Pontal / SP',
    'Pontalinda / SP',
    'Pontes Gestal / SP',
    'Populina / SP',
    'Porangaba / SP',
    'Porto Feliz / SP',
    'Porto Ferreira / SP',
    'Potim / SP',
    'Potirendaba / SP',
    'Pracinha / SP',
    'Pradópolis / SP',
    'Praia Grande / SP',
    'Pratânia / SP',
    'Presidente Alves / SP',
    'Presidente Bernardes / SP',
    'Presidente Epitácio / SP',
    'Presidente Prudente / SP',
    'Presidente Venceslau / SP',
    'Promissão / SP',
    'Quadra / SP',
    'Quatá / SP',
    'Queiroz / SP',
    'Queluz / SP',
    'Quintana / SP',
    'Rafard / SP',
    'Rancharia / SP',
    'Redenção da Serra / SP',
    'Regente Feijó / SP',
    'Reginópolis / SP',
    'Registro / SP',
    'Restinga / SP',
    'Ribeira / SP',
    'Ribeirão Bonito / SP',
    'Ribeirão Branco / SP',
    'Ribeirão Corrente / SP',
    'Ribeirão do Sul / SP',
    'Ribeirão Dos Índios / SP',
    'Ribeirão Grande / SP',
    'Ribeirão Pires / SP',
    'Ribeirão Preto / SP',
    'Rifaina / SP',
    'Rincão / SP',
    'Rinópolis / SP',
    'Rio Claro / SP',
    'Rio Das Pedras / SP',
    'Rio Grande da Serra / SP',
    'Riolândia / SP',
    'Riversul / SP',
    'Rosana / SP',
    'Roseira / SP',
    'Rubiácea / SP',
    'Rubinéia / SP',
    'Sabino / SP',
    'Sagres / SP',
    'Sales / SP',
    'Sales Oliveira / SP',
    'Salesópolis / SP',
    'Salmourão / SP',
    'Saltinho / SP',
    'Salto / SP',
    'Salto de Pirapora / SP',
    'Salto Grande / SP',
    'Sandovalina / SP',
    'Santa Adélia / SP',
    'Santa Albertina / SP',
    'Santa Bárbara D´oeste / SP',
    'Santa Branca / SP',
    'Santa Clara D´oeste / SP',
    'Santa Cruz da Conceição / SP',
    'Santa Cruz da Esperança / SP',
    'Santa Cruz Das Palmeiras / SP',
    'Santa Cruz do Rio Pardo / SP',
    'Santa Ernestina / SP',
    'Santa fé do Sul / SP',
    'Santa Gertrudes / SP',
    'Santa Isabel / SP',
    'Santa Lúcia / SP',
    'Santa Maria da Serra / SP',
    'Santa Mercedes / SP',
    'Santa Rita D´oeste / SP',
    'Santa Rita do Passa Quatro / SP',
    'Santa Rosa de Viterbo / SP',
    'Santa Salete / SP',
    'Santana da Ponte Pensa / SP',
    'Santana de Parnaíba / SP',
    'Santo Anastácio / SP',
    'Santo André / SP',
    'Santo Antônio da Alegria / SP',
    'Santo Antônio de Posse / SP',
    'Santo Antônio do Aracanguá / SP',
    'Santo Antônio do Jardim / SP',
    'Santo Antônio do Pinhal / SP',
    'Santo Expedito / SP',
    'Santópolis do Aguapeí / SP',
    'Santos / SP',
    'São Bento do Sapucaí / SP',
    'São Bernardo do Campo / SP',
    'São Caetano do Sul / SP',
    'São Carlos / SP',
    'São Francisco / SP',
    'São João da Boa Vista / SP',
    'São João Das Duas Pontes / SP',
    'São João de Iracema / SP',
    'São João do Pau D´alho / SP',
    'São Joaquim da Barra / SP',
    'São José da Bela Vista / SP',
    'São José do Barreiro / SP',
    'São José do Rio Pardo / SP',
    'São José do Rio Preto / SP',
    'São José Dos Campos / SP',
    'São Lourenço da Serra / SP',
    'São Luís do Paraitinga / SP',
    'São Manuel / SP',
    'São Miguel Arcanjo / SP',
    'São Paulo / SP',
    'São Pedro / SP',
    'São Pedro do Turvo / SP',
    'São Roque / SP',
    'São Sebastião / SP',
    'São Sebastião da Grama / SP',
    'São Simão / SP',
    'São Vicente / SP',
    'Sarapuí / SP',
    'Sarutaiá / SP',
    'Sebastianópolis do Sul / SP',
    'Serra Azul / SP',
    'Serra Negra / SP',
    'Serrana / SP',
    'Sertãozinho / SP',
    'Sete Barras / SP',
    'Severínia / SP',
    'Silveiras / SP',
    'Socorro / SP',
    'Sorocaba / SP',
    'Sud Mennucci / SP',
    'Sumaré / SP',
    'Suzanápolis / SP',
    'Suzano / SP',
    'Tabapuã / SP',
    'Tabatinga / SP',
    'Taboão da Serra / SP',
    'Taciba / SP',
    'Taguaí / SP',
    'Taiaçu / SP',
    'Taiúva / SP',
    'Tambaú / SP',
    'Tanabi / SP',
    'Tapiraí / SP',
    'Tapiratiba / SP',
    'Taquaral / SP',
    'Taquaritinga / SP',
    'Taquarituba / SP',
    'Taquarivaí / SP',
    'Tarabai / SP',
    'Tarumã / SP',
    'Tatuí / SP',
    'Taubaté / SP',
    'Tejupá / SP',
    'Teodoro Sampaio / SP',
    'Terra Roxa / SP',
    'Tietê / SP',
    'Timburi / SP',
    'Torre de Pedra / SP',
    'Torrinha / SP',
    'Trabiju / SP',
    'Tremembé / SP',
    'Três Fronteiras / SP',
    'Tuiuti / SP',
    'Tupã / SP',
    'Tupi Paulista / SP',
    'Turiúba / SP',
    'Turmalina / SP',
    'Ubarana / SP',
    'Ubatuba / SP',
    'Ubirajara / SP',
    'Uchoa / SP',
    'União Paulista / SP',
    'Urânia / SP',
    'Uru / SP',
    'Urupês / SP',
    'Valentim Gentil / SP',
    'Valinhos / SP',
    'Valparaíso / SP',
    'Vargem / SP',
    'Vargem Grande do Sul / SP',
    'Vargem Grande Paulista / SP',
    'Várzea Paulista / SP',
    'Vera Cruz / SP',
    'Vinhedo / SP',
    'Viradouro / SP',
    'Vista Alegre do Alto / SP',
    'Vitória Brasil / SP',
    'Votorantim / SP',
    'Votuporanga / SP',
    'Zacarias / SP',
    'Abreulândia / TO',
    'Aguiarnópolis / TO',
    'Aliança do Tocantins / TO',
    'Almas / TO',
    'Alvorada / TO',
    'Ananás / TO',
    'Angico / TO',
    'Aparecida do Rio Negro / TO',
    'Aragominas / TO',
    'Araguacema / TO',
    'Araguaçu / TO',
    'Araguaína / TO',
    'Araguanã / TO',
    'Araguatins / TO',
    'Arapoema / TO',
    'Arraias / TO',
    'Augustinópolis / TO',
    'Aurora do Tocantins / TO',
    'Axixá do Tocantins / TO',
    'Babaçulândia / TO',
    'Bandeirantes do Tocantins / TO',
    'Barra do Ouro / TO',
    'Barrolândia / TO',
    'Bernardo Sayão / TO',
    'Bom Jesus do Tocantins / TO',
    'Brasilândia do Tocantins / TO',
    'Brejinho de Nazaré / TO',
    'Buriti do Tocantins / TO',
    'Cachoeirinha / TO',
    'Campos Lindos / TO',
    'Cariri do Tocantins / TO',
    'Carmolândia / TO',
    'Carrasco Bonito / TO',
    'Caseara / TO',
    'Centenário / TO',
    'Chapada da Natividade / TO',
    'Chapada de Areia / TO',
    'Colinas do Tocantins / TO',
    'Colméia / TO',
    'Combinado / TO',
    'Conceição do Tocantins / TO',
    'Couto de Magalhães / TO',
    'Cristalândia / TO',
    'Crixás do Tocantins / TO',
    'Darcinópolis / TO',
    'Dianópolis / TO',
    'Divinópolis do Tocantins / TO',
    'Dois Irmãos do Tocantins / TO',
    'Dueré / TO',
    'Esperantina / TO',
    'Fátima / TO',
    'Figueirópolis / TO',
    'Filadélfia / TO',
    'Formoso do Araguaia / TO',
    'Fortaleza do Tabocão / TO',
    'Goianorte / TO',
    'Goiatins / TO',
    'Guaraí / TO',
    'Gurupi / TO',
    'Ipueiras / TO',
    'Itacajá / TO',
    'Itaguatins / TO',
    'Itapiratins / TO',
    'Itaporã do Tocantins / TO',
    'Jaú do Tocantins / TO',
    'Juarina / TO',
    'Lagoa da Confusão / TO',
    'Lagoa do Tocantins / TO',
    'Lajeado / TO',
    'Lavandeira / TO',
    'Lizarda / TO',
    'Luzinópolis / TO',
    'Marianópolis do Tocantins / TO',
    'Mateiros / TO',
    'Maurilândia do Tocantins / TO',
    'Miracema do Tocantins / TO',
    'Miranorte / TO',
    'Monte do Carmo / TO',
    'Monte Santo do Tocantins / TO',
    'Muricilândia / TO',
    'Natividade / TO',
    'Nazaré / TO',
    'Nova Olinda / TO',
    'Nova Rosalândia / TO',
    'Novo Acordo / TO',
    'Novo Alegre / TO',
    'Novo Jardim / TO',
    'Oliveira de Fátima / TO',
    'Palmas / TO',
    'Palmeirante / TO',
    'Palmeiras do Tocantins / TO',
    'Palmeirópolis / TO',
    'Paraíso do Tocantins / TO',
    'Paranã / TO',
    'Pau D´arco / TO',
    'Pedro Afonso / TO',
    'Peixe / TO',
    'Pequizeiro / TO',
    'Pindorama do Tocantins / TO',
    'Piraquê / TO',
    'Pium / TO',
    'Ponte Alta do Bom Jesus / TO',
    'Ponte Alta do Tocantins / TO',
    'Porto Alegre do Tocantins / TO',
    'Porto Nacional / TO',
    'Praia Norte / TO',
    'Presidente Kennedy / TO',
    'Pugmil / TO',
    'Recursolândia / TO',
    'Riachinho / TO',
    'Rio da Conceição / TO',
    'Rio Dos Bois / TO',
    'Rio Sono / TO',
    'Sampaio / TO',
    'Sandolândia / TO',
    'Santa fé do Araguaia / TO',
    'Santa Maria do Tocantins / TO',
    'Santa Rita do Tocantins / TO',
    'Santa Rosa do Tocantins / TO',
    'Santa Tereza do Tocantins / TO',
    'Santa Terezinha do Tocantins / TO',
    'São Bento do Tocantins / TO',
    'São Félix do Tocantins / TO',
    'São Miguel do Tocantins / TO',
    'São Salvador do Tocantins / TO',
    'São Sebastião do Tocantins / TO',
    'São Valério da Natividade / TO',
    'Silvanópolis / TO',
    'Sítio Novo do Tocantins / TO',
    'Sucupira / TO',
    'Taguatinga / TO',
    'Taipas do Tocantins / TO',
    'Talismã / TO',
    'Tocantínia / TO',
    'Tocantinópolis / TO',
    'Tupirama / TO',
    'Tupiratins / TO',
    'Wanderlândia / TO',
    'Xambioá / TO'];
