import { useEffect, useMemo, useState } from "react";
import { Api } from "_config/api/apiService";
import { mensagemErro } from "Components/Toastr/toastr";
import { Divider } from "helpers/divider/StylesDivider";
import { BoxList } from "helpers/box-list";
import { Container } from "helpers/Container";
import { BtnActive, BtnInative } from "helpers/ButtonCustom";
import PaginationColaborador from "Components/Pagination/paginationColaborador";
import { IColaboradorListDTO } from "_config/models/ordem-happy-api-model";
import { useNavigate } from "react-router-dom";
import RowColaborador from "Pages/Colaborador/RowColaborador";
import { Form } from "react-bootstrap";
import { useAuth } from "context/AuthProvider/useAuth";
import { Patente, PerfilFuncao } from "_config/constant";
import { Title } from "helpers/titles";
import { Header } from "helpers/header";
import { Col, Row } from "helpers/ColRow";
import { Search } from "react-bootstrap-icons";
import * as C from "./styles";
import "./styles.css";

function ListColaborador() {
    const [validation, setvalidation] = useState<boolean>(false);
    const usuario = useAuth();
    const navigate = useNavigate();
    const [ativo, setAtivo] = useState(true);
    const [pageNumber, setPageNumber] = useState(0);
    const [search, setSearch] = useState<string>('');
    const [page, setPage] = useState<IColaboradorListDTO>({
        content: [],
        last: true,
        totalPages: 0,
        totalElements: 0,
        size: 20,
        number: 0,
        first: true,
        numberOfElements: 0,
        empty: true
    });




    useEffect(() => {
        Api.get(`/pessoa?size=20&page=${pageNumber}&sort=nome&ativo=${ativo}`)
            .then(response => {
                const data = response.data as IColaboradorListDTO;
                setPage(data);
                navigate('/colaboradores')
            })
            .catch(error =>
                mensagemErro("Falha na conexão!"))
    }, [pageNumber, ativo, navigate])

    const handlePageChange = (newPageNumber: number) => {
        setPageNumber(newPageNumber);
    }

    const isAtivo = () => {
        setAtivo(true);
    }

    const isInativo = () => {
        setPageNumber(0);
        setAtivo(false);
    }

    const searchColaborador = useMemo(() => {
        const lowerSearch = search.toLowerCase();
        return page.content.filter(colaborador => colaborador?.nome?.toLowerCase().includes(lowerSearch))
    }, [page, search])




    function validacao() {
        if (!validation) {
            setvalidation(true);
        } else {
            setvalidation(false);
        }
    }

    return (
        <>
            <Container>
                <Header>
                    <Title>Colaboradores ({page.totalElements})</Title>
                </Header>

                <BoxList>
                    <Divider className="alin-aba-colaborador">
                        <Row>
                            <BtnActive onClick={isAtivo}>Ativo</BtnActive>
                            <BtnInative onClick={isInativo}>Inativo</BtnInative>
                        </Row>
                    </Divider>

                    <Row className="config-search-lista-colaborador-responsividade">
                        <Search id="search" className="search-lista-colaborador" onClick={() => validacao()} />
                        {validation ?
                            <Form.Control
                                className="config-campo-colaborador"
                                placeholder="Buscar"
                                onChange={(e) => setSearch(e.target.value)} />
                            : null}
                    </Row>

                    <C.Header>
                        <Divider><h2>nome</h2></Divider>
                        <Divider><h2>setor</h2></Divider>
                        <Divider><h2>aniversário</h2></Divider>

                        <Row className="config-search-lista-colaborador">
                            <Search id="search" className="search-lista-colaborador" onClick={() => validacao()} />
                            {validation ?
                                <Form.Control
                                    className="config-campo-colaborador"
                                    placeholder="Buscar"
                                    onChange={(e) => setSearch(e.target.value)} />
                                : null}
                        </Row>
                    </C.Header>

                    {ativo === true ?
                        searchColaborador.map(colaborador => (
                            <>
                                {(usuario.admin.codigo === PerfilFuncao.CODIGO_RECURSOS_HUMANOS && usuario.id !== colaborador.id) ||
                                    (usuario.admin.codigo === PerfilFuncao.CODIGO_TECNOLOGIA && usuario.id !== colaborador.id) ||
                                    (usuario.admin.codigo === PerfilFuncao.CODIGO_GESTOR && usuario.id !== colaborador.id && usuario.area === colaborador.area) ||
                                    (usuario.patente === Patente.GRAN_MESTRE && usuario.id !== colaborador.id) ?
                                    <RowColaborador key={colaborador.id} colaborador={colaborador} />
                                    : null}
                            </>
                        )) :

                        searchColaborador?.map(colaborador => (
                            <>
                                {(usuario.admin.codigo === PerfilFuncao.CODIGO_RECURSOS_HUMANOS && usuario.id !== colaborador.id) ||
                                    (usuario.admin.codigo === PerfilFuncao.CODIGO_TECNOLOGIA && usuario.id !== colaborador.id) ||
                                    (usuario.admin.codigo === PerfilFuncao.CODIGO_GESTOR && usuario.id !== colaborador.id && usuario.area === colaborador.area) ||
                                    (usuario.patente === Patente.GRAN_MESTRE && usuario.id !== colaborador.id) ?
                                    <RowColaborador key={colaborador.id} colaborador={colaborador} />
                                    : null}
                            </>
                        ))
                    }
                </BoxList>
                <PaginationColaborador page={page} onChange={handlePageChange} />
            </Container>
        </>
    );

}

export default ListColaborador;