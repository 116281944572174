import { PencilSquare } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { IColaboradorFullDTO } from '_config/models/ordem-happy-api-model';
import { Col, Row } from 'helpers/ColRow';
import { useAuth } from 'context/AuthProvider/useAuth';
import { Patente, PerfilFuncao } from '_config/constant';
import { MdOutlineFeedback } from 'react-icons/md';
import { FaRegListAlt } from 'react-icons/fa';
import ContatosColaborador from 'Components/ContatosColaborador';
import * as C from "./styles";
import { Divider } from 'helpers/divider/StylesDivider';

interface Props {
    colaborador: IColaboradorFullDTO;
}

function RowColaborador({ colaborador }: Props) {
    const usuario = useAuth();

    return (
        <C.Lista>
            <Divider>
                <p><strong>{colaborador.nome}</strong></p>
            </Divider>
            <Divider>
                <p>{colaborador.area}</p>
            </Divider>
            <Divider>
                <p>{moment(colaborador.dataNascimento).format("DD/MM/YYYY")}</p>
            </Divider>

            <Row>
                <ContatosColaborador colaborador={colaborador} />

                {usuario.admin.codigo === PerfilFuncao.CODIGO_GESTOR ||
                    usuario.admin.codigo === PerfilFuncao.CODIGO_TECNOLOGIA ||
                    usuario.admin.codigo === PerfilFuncao.CODIGO_RECURSOS_HUMANOS ?
                    <>
                        {colaborador.patente !== Patente.DIPLOMATA || usuario.patente === Patente.GRAN_MESTRE ?
                            <>

                                <Link to={`/feedback/${colaborador.id}`}>
                                    <MdOutlineFeedback id="md-outline-feedback" className="list-colaborador-icon" />
                                </Link>

                                <Link to={`/feedback-colaborador/${colaborador.id}`}>
                                    <FaRegListAlt id="fa-reg-list-alt" className="list-colaborador-icon" />
                                </Link>

                            </>
                            : null}
                    </>
                    : null}

                {usuario.admin.codigo === PerfilFuncao.CODIGO_TECNOLOGIA ||
                    usuario.admin.codigo === PerfilFuncao.CODIGO_RECURSOS_HUMANOS ?

                    <Link to={`/editar/${colaborador.id}`}>
                        <PencilSquare id="pencil-square" className="list-colaborador-icon" />
                    </Link>

                    : null}
            </Row>
        </C.Lista>
    );
}

export default RowColaborador;