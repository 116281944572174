import styled from "styled-components";

export const Container = styled.div`
    max-height: max-content;
    max-width: 1490px;
    padding: 10px;
    margin: auto;
`;

export const ContainerClean = styled.div`
    max-height: 100vh;
    height: 100%;
    width: 100%;


    @media (max-width: 400px) {
        position: absolute;
        height: max-content;
        width: 400px !important;
        margin: 0 auto;
        overflow-x: scroll;
    }
`;