import { PencilSquare } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { IQuestionarioFullDTO } from '_config/models/ordem-happy-api-model';
import { Divider } from 'helpers/divider/StylesDivider';
import * as C from "./styles";
import { Row } from 'helpers/ColRow';
import moment from 'moment';

interface Props {
    avaliacao: IQuestionarioFullDTO;
}

function RowAvaliacao({ avaliacao }: Props) {
    return (
        <>
            <C.Lista>
                <Divider>
                    <p><strong>{avaliacao.nomeQuestionario}</strong></p>
                </Divider>
                <Divider>
                    <p>{moment(avaliacao.dtInicio).format("DD/MM/YYYY")}</p>
                </Divider>
                <Divider>
                    <p>{moment(avaliacao.dtTermino).format("DD/MM/YYYY")}</p>
                </Divider>

                <Row>
                    <Link to={`/editar-avaliacao/${avaliacao.id}`}>
                        <PencilSquare id="pencil-square" />
                    </Link>
                </Row>
            </C.Lista>
        </>
    );
}

export default RowAvaliacao;