import { useEffect, useMemo, useState } from "react";
import { Api } from "_config/api/apiService";
import { mensagemErro } from "Components/Toastr/toastr";
import { Divider } from "helpers/divider/StylesDivider";
import { BoxList } from "helpers/box-list";
import { Container } from "helpers/Container";
import { Title } from "helpers/titles";
import { BtnActive, BtnInative } from "helpers/ButtonCustom";
import PaginationColaborador from "Components/Pagination/paginationColaborador";
import { IColaboradorListDTO } from "_config/models/ordem-happy-api-model";
import RowPerfil from "../RowPerfil";
import { Form } from "react-bootstrap";

import { Row } from "helpers/ColRow";
import { Search } from "react-bootstrap-icons";
import * as C from "./styles";
import "./styles.css";
import { Header } from "helpers/header";

function ListColaboradorPerfil() {
    const [validation, setvalidation] = useState<boolean>(false);
    const [ativo, setAtivo] = useState(true);
    const [pageNumber, setPageNumber] = useState(0);
    const [search, setSearch] = useState<string>('');
    const [page, setPage] = useState<IColaboradorListDTO>({
        content: [],
        last: true,
        totalPages: 0,
        totalElements: 0,
        size: 20,
        number: 0,
        first: true,
        numberOfElements: 0,
        empty: true
    });

    useEffect(() => {
        Api.get(`/pessoa?size=20&page=${pageNumber}&sort=nome&ativo=${ativo}`)
            .then(response => {
                const data = response.data as IColaboradorListDTO;
                setPage(data);
            })
            .catch(error =>
                mensagemErro("Falha na conexão!"))
    }, [pageNumber, ativo])

    const handlePageChange = (newPageNumber: number) => {
        setPageNumber(newPageNumber);
    }

    const isAtivo = () => {
        setAtivo(true);
    }

    const isInativo = () => {
        setPageNumber(0);
        setAtivo(false);
    }

    const searchColaborador = useMemo(() => {
        const lowerSearch = search.toLowerCase();
        return page.content.filter(colaborador => colaborador.nome.toLowerCase().includes(lowerSearch))
    }, [page, search])

    function validacao() {
        if (!validation) {
            setvalidation(true);
        } else {
            setvalidation(false);
        }
    }

    return (
        <>
            <Container>
                <Header>
                    <Title>Colaboradores ({page.totalElements})</Title>
                </Header>

                <BoxList>
                
                    <Divider className="alin-aba-perfil-funcao">
                        <Row>
                            <BtnActive onClick={isAtivo}>Ativo</BtnActive>
                            <BtnInative onClick={isInativo}>Inativo</BtnInative>
                        </Row>
                    </Divider>

                    <Row className="config-search-lista-perfil-funcao-responsividade">
                        <Search id="search" className="search-lista-perfil-funcao" onClick={() => validacao()} />
                        {validation ?
                            <Form.Control
                                className="config-campo-perfil-funcao"
                                placeholder="Buscar"
                                onChange={(e) => setSearch(e.target.value)} />
                            : null}
                    </Row>

                    <C.Header>
                        <Divider><h2>nome</h2></Divider>
                        <Divider><h2>setor</h2></Divider>
                        <Divider><h2>função</h2></Divider>

                        <Row className="config-search-lista-perfil-funcao">
                            <Search id="search" className="search-lista-perfil-funcao" onClick={() => validacao()} />
                            {validation ?
                                <Form.Control
                                    className="config-campo-perfil-funcao"
                                    placeholder="Buscar"
                                    onChange={(e) => setSearch(e.target.value)} />
                                : null}
                        </Row>
                    </C.Header>

                    {ativo ?
                        searchColaborador.map(colaborador => (
                            <RowPerfil key={colaborador.id} colaborador={colaborador} />
                        )) :
                        searchColaborador.map(colaborador => (
                            <RowPerfil key={colaborador.id} colaborador={colaborador} />
                        ))
                    }
                </BoxList>
                <PaginationColaborador page={page} onChange={handlePageChange} />
            </Container>
        </>
    );
}

export default ListColaboradorPerfil;