import styled from 'styled-components';

export const Btn = styled.button`
    width: 100px;
    height: 40px;
    color: #fff;
    border-radius: 5px;
    padding: 10px 25px;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
	box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
	7px 7px 20px 0px rgba(0,0,0,.1),
	4px 4px 5px 0px rgba(0,0,0,.1);
    outline: none;
`;

export const Button = styled(Btn)`
	background-color: var(--color-08);
	border: none;
	z-index: 1;
	&:hover {
		color: #fff;
	}
	&:active {
		top: 2px;
	}
`;

export const BtnInative = styled(Btn)`
	background-color: var(--color-08);
	background-image: var(--color-08);
	border: none;
	z-index: 1;
	&:after {
		position: absolute;
		content: "";
		width: 100%;
		height: 0;
		bottom: 0;
		left: 0;
		z-index: -1;
		border-radius: 5px;
		background-color: var(--color-04);
		background-image: var(--color-04);
		transition: all 0.3s ease;
	}
	&:hover {
		color: #fff;
		&:after {
			top: 0;
			height: 100%;
            box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
            rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
            rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
		}
	}
	&:active {
		top: 2px;
	}
`;

export const BtnActive = styled(Btn)`
	background-color: var(--color-08);
	background-image: var(--color-08);
	border: none;
	z-index: 1;
	&:after {
		position: absolute;
		content: "";
		width: 100%;
		height: 0;
		bottom: 0;
		left: 0;
		z-index: -1;
		border-radius: 5px;
		background-color: var(--color-07);
		background-image: var(--color-07);
		transition: all 0.3s ease;
	}
	&:hover {
		color: #fff;
		&:after {
			top: 0;
			height: 100%;
            box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
            rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
            rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
		}
	}
	&:active {
		top: 2px;
	}
`;
