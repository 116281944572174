import { IColaboradorFullDTO } from "_config/models/ordem-happy-api-model";
import { dataSaudacaoArray } from "../dataSaudacaoArray";

export function linkSendEmail(colaborador: IColaboradorFullDTO) {    
    const pl = "\n"; //Pular Linha

    const nome = colaborador.nome.split(' ').shift();
    const email = colaborador.emailCorporativo ? colaborador.emailCorporativo : colaborador.emailPessoal ;
    const emailCopia = colaborador.emailCorporativo ? colaborador.emailPessoal : "";
    const emailOculto = "";
    const assunto = `👨‍💻 Cliente - Portfólio ${nome}`;
    const saudacao = dataSaudacaoArray.object.map(saudacao => saudacao.descricao);
    const emoji = dataSaudacaoArray.object.map(saudacao => saudacao.emoji);
    const date = new Date();
    var body = "";

    body += `${nome}, ${saudacao} ${emoji}`;
    body += `${pl}${pl}`;
    body += `${pl}${pl}${pl}${pl}${pl}${pl}`;
    body += `Atenciosamente.`;
    body += `${pl}`;
    body += `${date}`;

    body = window.encodeURIComponent(body);

    return window.open(`mailto:${email}?cc=${emailCopia}&bcc=${emailOculto}&subject=${assunto}&body=${body}`, "_blank");
}