import { Divider } from 'antd';
import { BtnPadrao } from 'helpers/btn_especial/StylesButtom';
import { DivCenter } from 'helpers/divider/StylesDivider';
import { Col, Container, Row } from 'react-bootstrap'
import { ArrowRightCircleFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { ManualMundo } from '../component/_exportimg';
import './styles.css';

function Mundo() {
    return (
        <>
            <Container fluid className="alin-container">
                <Divider className="alin-text">
                    <h2>Objetivos</h2>
                    <p> O objetivo deste documento é orientar os Squads do Templo Happy, quanto as<br />
                        regras que determinam o comportamento da Ordem, bem como a classificação e<br />
                        nomenclatura de cargos, jornada de crescimento, salários e benefícios.<br />
                    </p>
                    <h2>Âmbito de aplicação</h2>
                    <p>Este documento é aplicável aos Squads do Templo Happy.</p>
                    <h2>O Mundo Happy</h2>
                    <DivCenter>
                        <ManualMundo />
                    </DivCenter>
                    <p> Em uma galáxia a mais de 700 anos luz do Planeta Terra, existe um mundo<br />
                        que abriga os Guardiões da Educação. Seres responsáveis por garantir a constante<br />
                        evolução do conhecimento em todo Universo.<br />
                        Na década de 1980, o planeta Terra passava por uma grande crise educacional<br />
                        e por consequência um gigante empobrecimento intelectual e cultural da população,<br />
                        gerando cada vez mais corrupção e destruição neste planeta.<br />
                        Um dos Guardiões mais respeitados de sua galáxia, reúne sua família em uma<br />
                        missão desacreditada por todo Universo – Salvar o Planeta Terra da destruição, por<br />
                        meio da Educação e da Cultura e, assim em 1990 iniciam um ousado projeto<br />
                        Educacional nessa nação.<br />
                        Ao longo de 30 anos, conseguem desenvolver a maior referência de ensino de<br />
                        todo Universo, na qual um dos filhos do Guardião assume o projeto de expansão<br />
                        educacional para toda a nação, de forma acessível e sem deixar a qualidade diminuir.<br />
                        Conhecido como Guardião William Matos, filho de Wilson Matos, dissemina a<br />
                        educação de forma WILLIMITADA, através de plataformas virtuais que conseguem<br />
                        garantir toda a qualidade para o desenvolvimento da sociedade.<br />
                        Em um de seus retiros para potencializar seu lado espiritual, caminhando no<br />
                        deserto da velha educação, o Guardião William cai em uma ruína de cristais<br />
                        esverdeados. Os cristais estavam fracos e quase sem energia, perdendo por completo<br />
                        sua força. Os cristais esverdeados representam a Luz da Tecnologia, a qual estava<br />
                        abandonada no Planeta Terra.<br />
                        O Guardião se propôs a potencializar novamente o cristal verde, dando vida e<br />
                        vitalidade para a educação tecnológica de todo universo, acrescentando dinâmica e<br />
                        felicidade na transmissão do conhecimento<br />
                        Investindo muita luz nessa missão, o Guardião convoca o Gran Mestre Otoniel,<br />
                        vindo de uma galáxia de operações, responsáveis por garantir a união de pessoas<br />
                        competentes para a boa condução de projetos estratégicos.<br />
                        O Gran Mestre Otoniel se alia a outro Gran Mestre e aos Mestres na construção<br />
                        de uma nova Ordem. junto com os Cônsules e Cavaleiros do Cristal, trouxeram a<br />
                        metodologia LET, levantando então o Templo Happy, que é a base da força e onde a<br />
                        Ordem se organiza e faz sua luz chegar ao espaço!<br />
                        Aos poucos a Happy ganha novos seguidores. Diplomatas, de galáxias<br />
                        distantes sentem a força e o chamado dos cristais H, chegando ao templo para trazer<br />
                        suas experiências, aumentando ainda mais o alcance dos cristais! Vieram Cavaleiros,<br />
                        Sentinelas, Padawans e Youngs de todos os cantos do Universo.<br />
                        Assim, semearam uma Floresta que capta energia do espaço e transforma em<br />
                        conhecimento que são disseminados pelos 3 principais cristais da Educação<br />
                        <li>Cristal da Tecnologia, chamado de Code, voltando ao verde com brilho<br />
                            e intensidade perfeita para o desenvolvimento de habilidades para o desenvolvimento<br />
                            do futuro;<br />
                        </li>
                        <li>Cristal da Educação Financeira, nomeado de Money, com seu amarelo<br />
                            vivo que remete ao ouro e dá mais condições de dignidade para as sociedades;<br />
                        </li>
                        <li>O Cristal da Oratória, Speech de cor vermelha vibrante que garante a<br />
                            paz mundial por disseminar as boas relações e comunicações entre as nações.<br />
                        </li>
                        E assim, cada vez mais e mais o Mundo Happy foi crescendo e se organizando.<br />
                        Os Hubs e as Escolas se multiplicando, a Floresta do conhecimento criou raízes e<br />
                        semeou grandes frutos, foi dado início a Fábrica de Talentos, os Satélites e as outras<br />
                        Terras foram aparecendo e aumentando nosso poder por todo Universo!<br />
                    </p>
                </Divider>
            </Container>

            <DivCenter>
                <Row>
                    <Col>
                        <Link to="/manual">
                            <BtnPadrao id="voltar">Voltar</BtnPadrao>
                        </Link>
                    </Col>
                    <Col>
                        <Link to="/templo">
                            <ArrowRightCircleFill id="arrow-right-circle-fill" className="btn-icon-arrow" />
                        </Link>
                    </Col>
                </Row>
            </DivCenter>
        </>
    )
}

export default Mundo;