import { DivCenter } from 'helpers/divider/StylesDivider';
import { Container, Image } from 'react-bootstrap';
import organograma from '../../_assets/img/img-png/organograma/ORGANOGRAMA ORDEM HAPPY.png';
import './styles.css';

function Organograma() {
  return (
    <Container fluid className="p-0">
      <DivCenter>
        <Image className="organograma" src={organograma} alt="organograma" />
      </DivCenter>
    </Container>
  );
}

export default Organograma;