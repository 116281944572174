import { useContext } from "react"
import { AuthContext } from "."

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('UseAuth must be used within an AuthProviden')
  }

  const { login, area, admin, nome, id, patente, superiorImediato, authenticate, logout } = context;

  return { login, area, admin, nome, id, patente, superiorImediato, authenticate, logout };
}
