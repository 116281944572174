import { BtnPadrao } from 'helpers/btn_especial/StylesButtom';
import { DivCenter, Divider } from 'helpers/divider/StylesDivider';
import { Col, Container, Row } from 'react-bootstrap';
import { ArrowLeftCircleFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import './styles.css';

function Glossario() {
  return (
    <>
      <Container fluid className="alin-container">
        <Divider className="alin-text">
          <h2>Glossário Ordem Happy</h2>
          <p>
            <strong>Ordem:</strong> Diretrizes estabelecidas entre a pluralidade de elementos que envolvem a<br />
            Happy;<br />
            <strong>Templo Happy (Sede):</strong> Onde as diretrizes do pensamento ilimitado fazem a transição<br />
            do idealizado ao realizado;<br />
            <strong>Satélite:</strong> Analisa e entende as tendências, criando oportunidades para disseminar o<br />
            futuro da geração de Protagonistas Happy;<br />
            <strong>Hub Educacional (Franquias):</strong> Meio no qual é formada a geração de protagonistas,<br />
            independente da sua localização no mundo Happy;<br />
            <strong>Comandante Happy (Gestor Hub):</strong> Mantém a paz, o equilíbrio e a organização dos<br />
            Hubs Educacionais, não se deixando atrair pelo lado negro da força;<br />
            <strong>Mentor Happy (Professor):</strong> Desenvolve e aprimora as habilidades dos protagonistas<br />
            no Hub Educacional;<br />
            <strong>Protagonista Happy (Aluno):</strong> Aprendizes que frequentam o Hub Educacional para<br />
            desenvolver suas habilidades técnicas e socioemocionais;<br />
            <strong>Visionário Happy (Pais/Responsáveis):</strong> São os pais ou responsáveis dos<br />
            Protagonistas Happy, que estão à frente do seu tempo;<br />
            <strong>Guerreiro Happy (Comercial):</strong> Aqueles que estão na linha de frente do Hub<br />
            Educacional, responsáveis por captar Protagonistas e Visionários;<br />
            <strong>Guia Happy (Recepcionista):</strong> Conduz visitantes, Protagonistas e Visionários,<br />
            mostrando-lhes o caminho e as coisas importantes;<br />
            <strong>Helper:</strong> Zela e cuida do Hub Educacional e de todos os envolvidos, principalmente os<br />
            Protagonistas;<br />
            <strong>Terras:</strong> São outras empresas ou organizações;<br />
            <strong>Recompensa:</strong> São os créditos que um colaborador recebe no início de cada mês,<br />
            como reconhecimento pelo seu trabalho;<br />
            <strong>Happy Gold:</strong> É a recompensa extra que o colaborador pode receber, também<br />
            conhecido como gratificação;<br />
            <strong>Squads:</strong> São integrantes das equipes do Templo Happy, que se empenham<br />
            intensamente para alcançar o ilimitado.<br />
          </p>
          <h2>Anexos</h2>
          <li>
            <Link to="//happycode2.sharepoint.com/sites/intranet/Documentos%20Compartilhados/Forms/AllItems.aspx?FolderCTID=0x01200005CDADBD7A3BA945AFBF92270B54B4AE&View=%7BB83F65BD%2D37EA%2D4E92%2DBEE5%2D5F681693DAF2%7D&id=%2Fsites%2Fintranet%2FDocumentos%20Compartilhados%2FFranqueadora%2FPROCESSOS%2F2%20%2D%20PROCESSOS%20PUBLICADOS%2FREGULAMENTOS%2FRGH%2D0079%20%2D%20Pol%C3%ADtica%20de%20Benef%C3%ADcios%2Epdf&parent=%2Fsites%2Fintranet%2FDocumentos%20Compartilhados%2FFranqueadora%2FPROCESSOS%2F2%20%2D%20PROCESSOS%20PUBLICADOS%2FREGULAMENTOS">Política de Benefícios</Link>
          </li>
        </Divider>
      </Container>

      <DivCenter>
        <Row>
          <Col>
            <Link to="/hino">
              <ArrowLeftCircleFill id="arrow-left-circle-fill" className="btn-icon-arrow" />
            </Link>
          </Col>
          <Col>
            <Link to="/manual">
              <BtnPadrao id="voltar">Voltar</BtnPadrao>
            </Link>
          </Col>
        </Row>
      </DivCenter>
    </>
  )
}

export default Glossario;