import { Image } from "react-bootstrap";
import { DivCenter } from "helpers/divider/StylesDivider";
import consul_carousel from "../../../_assets/img/img-png/personagem-patente/slice4.png";
import consulInsigniaCarousel from "../../../_assets/img/img-png/insignias-patente/consul.png";
import "./consul.styles.css";
import "../styles.css";

export const ConsulCarousel = () => (
    <>
        <DivCenter>
            <Image src={consul_carousel} alt="img-consul-carousel" id="manual-consul-carousel" className="manual-patente-carousel" />

            <DivCenter className="block-patente-consul-title">
                <h2 className="alin-title-patente-carrossel">consul</h2>
            </DivCenter>

            <DivCenter className="block-carrossel-full block-config-consul-carrossel">
                <Image src={consulInsigniaCarousel} alt="img-consul-insignia-carousel" id="manual-consul-insignia-carousel" className="manual-patente-insignia-consul-carousel" />
            </DivCenter>

        </DivCenter>
    </>
);