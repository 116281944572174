import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Col, Container, Form, Row } from "react-bootstrap";
import { BtnPadrao } from "helpers/btn_especial/StylesButtom";
import { Divider } from "helpers/divider/StylesDivider";
import { H5 } from "helpers/titles";
import { useColaboradorService } from "_config/service/colaborador.service";
import { IColaboradorFullDTO, IQuestionarioPessoaFullDTO } from "_config/models/ordem-happy-api-model";
import { useQuestionarioPessoaService } from "_config/service/questionarioPessoa.service";
import './styles.css';
import { Button } from "helpers/ButtonCustom";

function RespostaAvaliacao() {
    const { colaboradorId }: any = useParams();
    const [respostas, setRespostas] = useState<IQuestionarioPessoaFullDTO[]>([]);
    const [colaborador, setColaborador] = useState<IColaboradorFullDTO>();
    const serviceColaboradores = useColaboradorService();
    const serviceQuestionarioPessoa = useQuestionarioPessoaService();

    const colaboradores = () => {
        serviceColaboradores.colaboradores(colaboradorId)
            .then(response => {
                setColaborador(response.data)
            });
    }

    const questionarioPessoa = () => {
        serviceQuestionarioPessoa.questionarioPessoa(colaboradorId)
            .then(response => {
                setRespostas(response.data)
            });
    }

    useEffect(() => {
        colaboradores();
        questionarioPessoa();
    }, [])

    let contador: number = 0;

    /*const media = respostas.map((x) => (
        (x.respostaUm + x.respostaDois + x.respostaTres + x.respostaQuatro + x.respostaCinco)/ 5
    ))*/

    return (
        <>
            <Divider className="alin-title-colaborador">
                <h2>{colaborador?.genero === "Masculino" ? "Avaliações do colaborador :" : colaborador?.genero === "Feminino" ? "Avaliação da colaboradora :" : null} {colaborador?.nome}</h2>
            </Divider>
            {respostas.map((x) => (
                <Container className="alin-relatorio">
                    <Row>
                        <h2 className="alin-title-resultado">Essa é a avaliação: {contador = contador + 1}.</h2>
                        <H5 className="config-h5-avaliação">{x.pessoa.nome}</H5>
                    </Row>
                    <Row>
                        <Col className="borda-tabela">
                            <Form.Text className="config-text config-numquest">Questão 1:</Form.Text>
                            <Form.Text className="config-text config-quest">{x.questionario.primeiraPergunta}</Form.Text>
                            <Form.Text className="config-text config-nota">Nota: {x.respostaUm}</Form.Text>
                        </Col>
                    </Row>

                    <Row>
                        <Col className="borda-tabela">
                            <Form.Text className="config-text config-numquest">Questão 2:</Form.Text>
                            <Form.Text className="config-text config-quest">{x.questionario.segundaPergunta}</Form.Text>
                            <Form.Text className="config-text config-nota">Nota: {x.respostaDois}</Form.Text>
                        </Col>
                    </Row>

                    <Row>
                        <Col className="borda-tabela">
                            <Form.Text className="config-text config-numquest">Questão 3:</Form.Text>
                            <Form.Text className="config-text config-quest">{x.questionario.terceiraPergunta}</Form.Text>
                            <Form.Text className="config-text config-nota">Nota: {x.respostaTres}</Form.Text>
                        </Col>
                    </Row>

                    <Row>
                        <Col className="borda-tabela">
                            <Form.Text className="config-text config-numquest">Questão 4:</Form.Text>
                            <Form.Text className="config-text config-quest">{x.questionario.quartaPergunta}</Form.Text>
                            <Form.Text className="config-text config-nota">Nota: {x.respostaQuatro}</Form.Text>
                        </Col>
                    </Row>

                    <Row>
                        <Col className="borda-tabela">
                            <Form.Text className="config-text config-numquest">Questão 5:</Form.Text>
                            <Form.Text className="config-text config-quest">{x.questionario.quintaPergunta}</Form.Text>
                            <Form.Text className="config-text config-nota">Nota: {x.respostaCinco}</Form.Text>
                        </Col>
                    </Row>

                    <Row>
                        <Col className="borda-tabela">
                            <Form.Text className="config-text config-numquest">Questão 6:</Form.Text>
                            <Form.Text className="config-text config-quest">{x.questionario.sextaPergunta}</Form.Text>
                            <Form.Text className="config-text config-nota">Nota: {x.respostaSeis}</Form.Text>
                        </Col>
                    </Row>

                    <Row>
                        <Col className="borda-tabela">
                            <Form.Text className="config-text config-numquest">Questão 7:</Form.Text>
                            <Form.Text className="config-text config-quest">{x.questionario.setimaPergunta}</Form.Text>
                            <Form.Text className="config-text config-nota">Nota: {x.respostaSete}</Form.Text>
                        </Col>
                    </Row>

                    {x.respostaOito !== null ?
                        <>
                            <Row>
                                <Col className="borda-tabela">
                                    <Form.Text className="config-text config-numquest">Questão 8:</Form.Text>
                                    <Form.Text className="config-text config-quest">{x.questionario.oitavaPergunta}</Form.Text>
                                    <Form.Text className="config-text config-nota">Nota: {x.respostaOito}</Form.Text>
                                </Col>
                            </Row>

                            <Row>
                                <Col className="borda-tabela">
                                    <Form.Text className="config-text config-numquest">Questão 9:</Form.Text>
                                    <Form.Text className="config-text config-quest">{x.questionario.nonaPergunta}</Form.Text>
                                    <Form.Text className="config-text config-nota">Nota: {x.respostaNove}</Form.Text>
                                </Col>
                            </Row>
                        </>
                        : null}
                    <Row>
                        <Col className="borda-media">
                            Media: {
                                ((x.respostaUm + x.respostaDois
                                    + x.respostaTres + x.respostaQuatro
                                    + x.respostaCinco + x.respostaSeis
                                    + x.respostaSete + x.respostaOito + x.respostaNove) / (x.respostaOito !== null ? 9 : 7)).toPrecision(3)
                            }
                        </Col>
                    </Row>
                </Container>
            ))}
            <Divider className="alin-btn-card-avaliacao">
                <Link to="/colaboradores">
                    <Button id="voltar">Voltar</Button>
                </Link>
            </Divider>
        </>
    )
}

export default RespostaAvaliacao;