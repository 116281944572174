import { Image } from "react-bootstrap";
import { DivCenter, Divider } from "helpers/divider/StylesDivider";
import padawan_carousel from "../../../_assets/img/img-png/personagem-patente/slice11.png";
import padawanInsigniaCarousel from "../../../_assets/img/img-png/insignias-patente/padawan 3.png";
import "./padawan.styles.css";
import "../styles.css";

export const PadawanCarousel = () => (
    <>
        <Image src={padawan_carousel} alt="img-padawan-carousel" id="manual-padawan-carousel" className="manual-patente-carousel" />
        <Divider className="alin-tabela-salario-padawan-carrossel">
            <DivCenter>
                <h2 className="titles-insignia-cargo-carrosel">padawan</h2>
                <Image src={padawanInsigniaCarousel} alt="img-padawan-insignia-carousel" id="manual-padawan-insignia-padawan-carousel" className="manual-patente-insignia-padawan-carousel" />
            </DivCenter>
            <Divider className="block-carrossel block-config1-padawan-carrossel"><p className="alin-text-patente-carrossel">?</p></Divider>
            <Divider className="block-carrossel block-config2-padawan-carrossel"><p className="alin-text-patente-carrossel">?</p></Divider>
            <Divider className="block-carrossel block-config3-padawan-carrossel"><p className="alin-text-patente-carrossel">R$ 3.800,00</p></Divider>
            <Divider className="block-carrossel block-config4-padawan-carrossel"><p className="alin-text-patente-carrossel">R$ 3.200,00</p></Divider>
            <Divider className="block-carrossel block-config5-padawan-carrossel"><p className="alin-text-patente-carrossel">R$ 2.800,00</p></Divider>
        </Divider>
    </>
);
