import { Image } from "react-bootstrap";
import { DivCenter, Divider } from "helpers/divider/StylesDivider";
import young_carousel from "../../../_assets/img/img-png/personagem-patente/slice12.png";
import youngInsigniaCarousel from "../../../_assets/img/img-png/insignias-patente/young 3.png";
import "./young.styles.css";
import "../styles.css";

export const YoungCarousel = () => (
  <>
    <Image src={young_carousel} alt="img-young-carousel" id="manual-young-carousel" className="manual-patente-carousel" />

    <Divider className="alin-tabela-salario-young-carrossel">
      <DivCenter>
        <h2 className="titles-insignia-cargo-carrosel">young</h2>
        <Image src={youngInsigniaCarousel} alt="img-young-insignia-carousel" id="manual-young-insignia-young-carousel" className="manual-patente-insignia-young-carousel" />
      </DivCenter>
      <Divider className="block-carrossel block-config1-young-carrossel"><p className="alin-text-patente-carrossel"></p></Divider>
      <Divider className="block-carrossel block-config2-young-carrossel"><p className="alin-text-patente-carrossel"></p></Divider>
      <Divider className="block-carrossel block-config3-young-carrossel"><p className="alin-text-patente-carrossel"></p></Divider>
      <Divider className="block-carrossel block-config4-young-carrossel"><p className="alin-text-patente-carrossel">R$ 1.800,00</p></Divider>
      <Divider className="block-carrossel block-config5-young-carrossel"><p className="alin-text-patente-carrossel">R$ 1.000,00</p></Divider>
    </Divider>
  </>
);
