import { useEffect, useState } from 'react'
import { useColaboradorService } from '_config/service/colaborador.service';
import { IColaboradorFullDTO } from '_config/models/ordem-happy-api-model';
import { DivCenterPR, Divider } from 'helpers/divider/StylesDivider';
import { Container } from 'helpers/Container';
import "./styles.css";

function CardInsignia() {
    const user = JSON.parse(localStorage.getItem('u') || '{}');
    const [colaborador, setColaborador] = useState<IColaboradorFullDTO>();
    const serviceColaboradores = useColaboradorService();

    const colaboradores = () => {
        serviceColaboradores.colaboradores(user?.id)
            .then(response => {
                setColaborador(response.data)
            });
    }

    useEffect(() => {
        colaboradores();
    }, [])

    function CardInsigniaStyle(): string {
        let cardInsigniaStyle = "";

        if (colaborador?.patente === 'Young I') {
            cardInsigniaStyle = 'insignia-young1';
        }
        if (colaborador?.patente === 'Young II') {
            cardInsigniaStyle = 'insignia-young2';
        }
        if (colaborador?.patente === 'Padawan I') {
            cardInsigniaStyle = 'insignia-padawan1';
        }
        if (colaborador?.patente === 'Padawan II') {
            cardInsigniaStyle = 'insignia-padawan2';
        }
        if (colaborador?.patente === 'Padawan III') {
            cardInsigniaStyle = 'insignia-padawan3';
        }
        if (colaborador?.patente === 'Sentinela I') {
            cardInsigniaStyle = 'insignia-sentinela1';
        }
        if (colaborador?.patente === 'Sentinela II') {
            cardInsigniaStyle = 'insignia-sentinela2';
        }
        if (colaborador?.patente === 'Sentinela III') {
            cardInsigniaStyle = 'insignia-sentinela3';
        }
        if (colaborador?.patente === 'Cavaleiro I') {
            cardInsigniaStyle = 'insignia-cavaleiro1';
        }
        if (colaborador?.patente === 'Cavaleiro II') {
            cardInsigniaStyle = 'insignia-cavaleiro2';
        }
        if (colaborador?.patente === 'Cavaleiro III') {
            cardInsigniaStyle = 'insignia-cavaleiro3';
        }
        if (colaborador?.patente === 'Diplomata') {
            cardInsigniaStyle = 'insignia-diplomata';
        }
        if (colaborador?.patente === 'Mestre') {
            cardInsigniaStyle = 'insignia-mestre';
        }
        if (colaborador?.patente === 'Consul') {
            cardInsigniaStyle = 'insignia-consul';
        }
        if (colaborador?.patente === 'Gran Mestre') {
            cardInsigniaStyle = 'insignia-granMestre';
        }
        if (colaborador?.patente === 'Guardião') {
            cardInsigniaStyle = 'insignia-guardiao';
        }
        return cardInsigniaStyle;
    }

    function ColorTitle(): string {
        let colorTitle = "";

        if (colaborador?.patente === 'Guardião') {
            colorTitle = 'title-guardiao-jornada';
        }
        return colorTitle;
    }

    return (
        <Container>
            <DivCenterPR>
                <Divider className={`${CardInsigniaStyle()}`} />
                <Divider className="alin-title-patente-jornada">
                    <h2 className={`${ColorTitle()}`} >{colaborador?.patente}</h2>
                </Divider>
            </DivCenterPR>
        </Container>
    );
}

export default CardInsignia