import { Image } from "react-bootstrap";
import { DivCenter } from "helpers/divider/StylesDivider";
import diplomataM_carousel from "../../../_assets/img/img-png/personagem-patente/slice7.png";
import diplomataF_carousel from "../../../_assets/img/img-png/personagem-patente/slice8.png";
import diplomataInsigniaCarousel from "../../../_assets/img/img-png/insignias-patente/diplomata.png";
import "./diplomata.styles.css";
import "../styles.css";

export const DiplomataCarousel = () => (
    <>
        <DivCenter>
            <Image src={diplomataM_carousel} alt="img-diplomatam-carousel" id="manual-diplomatam-carousel" className="manual-patente-carousel" />

            <DivCenter className="block-patente-diplomata-title">
                <h2 className="alin-title-patente-carrossel">diplomata</h2>
            </DivCenter>

            <DivCenter className="block-carrossel-full block-config-diplomata-carrossel">
                <Image src={diplomataInsigniaCarousel} alt="img-diplomata-insignia-carousel" id="manual-diplomata-insignia-carousel" className="manual-patente-insignia-diplomata-carousel" />
            </DivCenter>

            <Image src={diplomataF_carousel} alt="img-diplomataf-carousel" id="manual-diplomataf-carousel" className="manual-patente-carousel" />
        </DivCenter>
    </>
);