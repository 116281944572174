import { useState } from 'react';
import { Col, Row } from "helpers/ColRow";
import { Container } from "helpers/Container";
import { H5, Title } from "helpers/titles";

import { useQuestionarioService } from '_config/service/questionario.service';
import { BtnPadrao } from 'helpers/btn_especial/StylesButtom';
import { Link, useNavigate } from "react-router-dom";
import { IQuestionarioFullDTO } from '_config/models/ordem-happy-api-model';
import { Form } from 'react-bootstrap';
import { mensagemSucesso } from 'Components/Toastr/toastr';
import { Header } from 'helpers/header';


function NovaAvaliacao() {
    const [questionarios, setQuestionarios] = useState<IQuestionarioFullDTO | any>();
    const serviceQuestionario = useQuestionarioService();
    const navigate = useNavigate();

    const updateQuestionario = () => {
        serviceQuestionario.novoQuestionario(questionarios)
        navigate('/painel-avaliacao')
        mensagemSucesso("Sucesso!")
    }

    return (
        <Container>

            <Header>
                <Title>Editar de Avaliação</Title>
            </Header>
            
            <Form>
                <Col>

                    <Row className="margin">
                        <Col>
                            <H5>Data início</H5>
                            <Form.Control
                                type="date"
                                value={questionarios?.dtInicio}
                                onChange={(e) => setQuestionarios({ ...questionarios, dtInicio: e.target.value })} />
                        </Col>
                        <Col>
                            <H5>Data término</H5>
                            <Form.Control
                                type="date"
                                value={questionarios?.dtTermino}
                                onChange={(e) => setQuestionarios({ ...questionarios, dtTermino: e.target.value })} />
                        </Col>
                    </Row>
                    <Row className="margin">
                        <H5>nome do questionário</H5>
                        <Form.Control
                            placeholder="Nome do questionário"
                            onChange={(e) => setQuestionarios({ ...questionarios, nomeQuestionario: e.target.value })} />
                    </Row>
                    <Row className="margin">
                        <H5>Editar de questão</H5>
                        <Form.Control
                            placeholder="Questão 1"
                            onChange={(e) => setQuestionarios({ ...questionarios, primeiraPergunta: e.target.value })} />
                    </Row>
                    <Row className="margin">
                        <H5>Editar de questão</H5>
                        <Form.Control
                            placeholder="Questão 2"

                            onChange={(e) => setQuestionarios({ ...questionarios, segundaPergunta: e.target.value })} />
                    </Row>
                    <Row className="margin">
                        <H5>Editar de questão</H5>
                        <Form.Control
                            placeholder="Questão 3"
                            onChange={(e) => setQuestionarios({ ...questionarios, terceiraPergunta: e.target.value })} />
                    </Row>
                    <Row className="margin">
                        <H5>Editar de questão</H5>
                        <Form.Control
                            placeholder="Questão 4"
                            onChange={(e) => setQuestionarios({ ...questionarios, quartaPergunta: e.target.value })} />
                    </Row>
                    <Row className="margin">
                        <H5>Editar de questão</H5>
                        <Form.Control
                            placeholder="Questão 5"
                            onChange={(e) => setQuestionarios({ ...questionarios, quintaPergunta: e.target.value })} />
                    </Row>
                    <Row className="margin">
                        <H5>Editar de questão</H5>
                        <Form.Control
                            placeholder="Questão 6"
                            onChange={(e) => setQuestionarios({ ...questionarios, sextaPergunta: e.target.value })} />
                    </Row>
                    <Row className="margin">
                        <H5>Editar de questão</H5>
                        <Form.Control
                            placeholder="Questão 7"
                            onChange={(e) => setQuestionarios({ ...questionarios, setimaPergunta: e.target.value })} />
                    </Row>
                    <Row className="margin">
                        <H5>Editar de questão</H5>
                        <Form.Control
                            placeholder="Questão 8"
                            onChange={(e) => setQuestionarios({ ...questionarios, oitavaPergunta: e.target.value })} />
                    </Row>
                    <Row className="margin">
                        <H5>Editar de questão</H5>
                        <Form.Control
                            placeholder="Questão 9"
                            onChange={(e) => setQuestionarios({ ...questionarios, nonaPergunta: e.target.value })} />
                    </Row>
                </Col>
                <Link to="/lista-avaliacao">
                    <BtnPadrao id="voltar">Voltar</BtnPadrao>
                </Link>

                <BtnPadrao onClick={updateQuestionario} id="salvar" type="submit">Salvar</BtnPadrao>
            </Form>

        </Container >
    );
}

export default NovaAvaliacao;