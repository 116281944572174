import { Image } from "react-bootstrap";
import { DivCenter, Divider } from "helpers/divider/StylesDivider";
import cavaleiro_carousel from "../../../_assets/img/img-png/personagem-patente/slice9.png";
import cavaleiroInsigniaCarousel from "../../../_assets/img/img-png/insignias-patente/cavaleiro 3.png";
import "./cavaleiro.stayles.css";
import "../styles.css";

export const CavaleiroCarousel = () => (
    <>
        <Image src={cavaleiro_carousel} alt="img-cavaleiro-carousel" id="manual-cavaleiro-carousel" className="manual-patente-carousel" />
        <Divider className="alin-tabela-salario-cavaleiro-carrossel">
            <DivCenter>
                <h2 className="titles-insignia-cargo-carrosel">cavaleiro</h2>
                <Image src={cavaleiroInsigniaCarousel} alt="img-cavaleiro-insignia-carousel" id="manual-cavaleiro-insignia-carousel" className="manual-patente-insignia-cavaleiro-carousel" />
            </DivCenter>
            <Divider className="block-carrossel block-config1-cavaleiro-carrossel"><p className="alin-text-patente-carrossel">?</p></Divider>
            <Divider className="block-carrossel block-config2-cavaleiro-carrossel"><p className="alin-text-patente-carrossel">?</p></Divider>
            <Divider className="block-carrossel block-config3-cavaleiro-carrossel"><p className="alin-text-patente-carrossel">R$ 5.500,00</p></Divider>
            <Divider className="block-carrossel block-config4-cavaleiro-carrossel"><p className="alin-text-patente-carrossel">R$ 5.000,00</p></Divider>
            <Divider className="block-carrossel block-config5-cavaleiro-carrossel"><p className="alin-text-patente-carrossel">R$ 4.500,00</p></Divider>
        </Divider>
    </>
);