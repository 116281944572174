import React from 'react';
import PrivateRotas from './PrivateRotas';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { Login } from '../../Pages/Login';
import Home from '../../Pages/Home/index';
import Cadastro from '../../Pages/Cadastro';
import FormAvaliacao from 'Pages/Avaliacao/FormAvaliacao';
import Organograma from 'Pages/Organograma';
import Ascensao from 'Pages/Ascensao';
import Jornada from 'Pages/Jornada';
import Manual from 'Pages/Manual';
import Mundo from 'Pages/Manual/mundo';
import Templo from 'Pages/Manual/templo';
import Cargo from 'Pages/Manual/cargo';
import Hino from 'Pages/Manual/hino';
import Glossario from 'Pages/Manual/glossario';
import JornadaManual from 'Pages/Manual/jornada';
import Intranet from 'Pages/Intranet';
import PainelAdmin from 'Pages/PainelAdmin';
import ListAvaliacao from 'Pages/PainelAdmin/Avaliacao/ListAvaliacao';
import PainelAvaliacao from 'Pages/PainelAdmin/Avaliacao';
import NovaAvaliacao from 'Pages/PainelAdmin/Avaliacao/NovaAvaliacao';
import PerfilFuncao from 'Pages/PainelAdmin/Perfil/PerfilFuncao/EditarPerfilFuncao';
import JornalHappy from 'Pages/PainelAdmin/JornalHappy';
import EditarPerfilFuncao from 'Pages/PainelAdmin/Perfil/PerfilFuncao/EditarPerfilFuncao';
import ListColaboradoresAvaliados from 'Pages/PainelAdmin/Avaliacao/ListColaboradoresAvaliados';
import RespostaAvaliacao from 'Pages/Avaliacao/RespostaAvaliacao';
import EditarColaborador from 'Pages/Colaborador/EditarColaborador';
import EditarAvaliacao from 'Pages/PainelAdmin/Avaliacao/EditarAvaliacao';
import ListColaboradorPerfil from 'Pages/PainelAdmin/Perfil/PerfilFuncao/ListColaboradorPerfil';
import ListColaborador from 'Pages/Colaborador/ListColaborador';
import Feedback from 'Components/Feedback';
import ExibirHolerite from 'Pages/PainelAdmin/Holerite/ExibirHolerite';
import UploadHolerite from 'Pages/PainelAdmin/Holerite/UploadHolerite';
import FeedbackColaborador from 'Components/FeedbackColaborador';
import HappyNews from 'Pages/HappyNews';

const Rotas: React.FunctionComponent = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path='/home' element={<PrivateRotas><Home /></PrivateRotas>} />
                <Route path='/cadastro' element={<PrivateRotas><Cadastro /></PrivateRotas>} />
                <Route path='/colaboradores' element={<PrivateRotas><ListColaborador /></PrivateRotas>} />
                <Route path='/ascensao' element={<PrivateRotas><Ascensao /></PrivateRotas>} />
                <Route path='/manual' element={<PrivateRotas><Manual /></PrivateRotas>} />
                <Route path='/organograma' element={<PrivateRotas><Organograma /></PrivateRotas>} />
                <Route path='/jornada' element={<PrivateRotas><Jornada /></PrivateRotas>} />
                <Route path='/mundo' element={<PrivateRotas><Mundo /></PrivateRotas>} />
                <Route path='/templo' element={<PrivateRotas><Templo /></PrivateRotas>} />
                <Route path='/cargo' element={<PrivateRotas><Cargo /></PrivateRotas>} />
                <Route path='/jornada-manual' element={<PrivateRotas><JornadaManual /></PrivateRotas>} />
                <Route path='/hino' element={<PrivateRotas><Hino /></PrivateRotas>} />
                <Route path='/glossario' element={<PrivateRotas><Glossario /></PrivateRotas>} />
                <Route path='/intranet' element={<PrivateRotas><Intranet /></PrivateRotas>} />
                <Route path='/jornal-happy' element={<PrivateRotas><JornalHappy /></PrivateRotas>} />
                <Route path='/admin-painel' element={<PrivateRotas><PainelAdmin /></PrivateRotas>} />
                <Route path='/nova-avaliacao' element={<PrivateRotas><NovaAvaliacao /></PrivateRotas>} />
                <Route path='/painel-avaliacao' element={<PrivateRotas><PainelAvaliacao /></PrivateRotas>} />
                <Route path='/perfil-funcao' element={<PrivateRotas><PerfilFuncao /></PrivateRotas>} />
                <Route path='/lista-avaliacao' element={<PrivateRotas><ListAvaliacao /></PrivateRotas>} />
                <Route path='/lista-colaborador-perfil' element={<PrivateRotas><ListColaboradorPerfil /></PrivateRotas>} />
                <Route path='/lista-colaboradores-avaliados' element={<PrivateRotas><ListColaboradoresAvaliados /></PrivateRotas>} />
                <Route path='/upload-holerite' element={<PrivateRotas><UploadHolerite /></PrivateRotas>} />
                <Route path='/happy-news' element={<PrivateRotas><HappyNews /></PrivateRotas>} />

                <Route
                    path='/avaliacao/:avaliacaoId'
                    element={<PrivateRotas><FormAvaliacao /></PrivateRotas>} />

                <Route
                    path='/editar-avaliacao/:avaliacaoId'
                    element={<PrivateRotas><EditarAvaliacao /></PrivateRotas>} />
                <Route
                    path='/editar-perfil-funcao/:colaboradorId'
                    element={<PrivateRotas><EditarPerfilFuncao /></PrivateRotas>} />
                <Route
                    path='/editar/:colaboradorId'
                    element={<PrivateRotas><EditarColaborador /></PrivateRotas>}
                />
                <Route
                    path='/resultado-avaliacao/:colaboradorId'
                    element={<PrivateRotas><RespostaAvaliacao /></PrivateRotas>}
                />
                <Route
                    path='/feedback/:colaboradorId'
                    element={<PrivateRotas><Feedback /></PrivateRotas>} />
                <Route
                    path='/feedback-colaborador/:pessoaAvaliadaId'
                    element={<PrivateRotas><FeedbackColaborador /></PrivateRotas>} />
                <Route
                    path='/exibir-holerite/'
                    element={<PrivateRotas><ExibirHolerite /></PrivateRotas>} />
            </Routes>
        </BrowserRouter>
    );
}

export default Rotas;