import './styles.css';

function FundoEstrelado() {

    return (
        <>
            <section className="config-section-fundo-estrelado">
                <div className="fundo-estrelado">
                    <div className="star star1"></div>
                    <div className="star star2"></div>
                    <div className="star star3"></div>
                    <div className="star star4"></div>
                    <div className="star star5"></div>
                    <div className="star star6"></div>
                    <div className="star star7"></div>
                    <div className="star star8"></div>
                </div>
            </section>
        </>
    );
}

export default FundoEstrelado;