import Lottie from "lottie-react";
import mundo_happy from "./lf30_editor_kazavtzj.json";
import crescimento_happy from "./lf20_wosmqor2.json";
import cargos_happy from "./lf20_rt6n0a7l.json";
import templo_happy from "./lf20_wbxnqgni.json";
import hino_happy from "./lf20_solknjq9.json";
import glossario_happy from "./lf20_lfekbobv.json";
import novo from "../../../_assets/lottie/nova-funcionalidade/new.json";
import "./styles.css";


export const MundoHappy = () => {
    return <Lottie className="lottie-manual" loop animationData={mundo_happy} />
};

export const CrescimentoHappy = () => {
    return <Lottie className="lottie-manual" loop animationData={crescimento_happy} />
};

export const CargosHappy = () => {
    return <Lottie className="lottie-manual" loop animationData={cargos_happy} />
};

export const TemploHappy = () => {
    return <Lottie className="lottie-manual" loop animationData={templo_happy} />
};

export const HinoHappy = () => {
    return <Lottie className="lottie-manual" loop animationData={hino_happy} />
};

export const GlossarioHappy = () => {
    return <Lottie className="lottie-manual" loop animationData={glossario_happy} />
};

export const New = () => {
    return <Lottie id="new" loop animationData={novo} />
};